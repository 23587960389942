import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH } from "config";

const ExperienceColumn = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "shift",
})(({ shift }) => ({
  alignItems: "center",

  "& .experience": {
    marginBottom: "-15%",

    "&:nth-of-type(1)": {
      alignSelf: "flex-end",
    },

    "&:nth-of-type(3)": {
      alignSelf: "flex-start",
    },
  },

  ...(shift && {
    transform: "translateX(-25%)",
    marginBottom: "-10%",
  }),

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 40,

    "& .experience": {
      marginBottom: 0,

      "&:nth-of-type(1), &:nth-of-type(3)": {
        alignSelf: "center",
      },
    },

    ...(shift && {
      transform: "none",
      marginBottom: 0,
    }),
  },
}));

export default ExperienceColumn;
