import React from "react";
import Jumbotron from "./Jumbotron";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const Home = () => {
  return (
    <>
      <Jumbotron />
      <Section1 />
      <Section2 />
      <Section3 />
    </>
  );
};

export default Home;
