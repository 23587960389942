import React from "react";
import { Stack } from "@mui/material";
import Header from "components/atoms/Typography/Header";
import Text from "components/atoms/Typography/Text";
import StackedButtons from "../StackedButtons";
import styled from "@emotion/styled";
import { CONTAINER_WIDTH } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["style", "textAlign"].includes(prop),
})(({ theme, style, textAlign }) => ({
  width: "100%",
  alignItems: textAlign === "center" ? "center" : "flex-start",
  ...style,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    alignItems: "center",
    maxWidth: 500,
    margin: "0 auto",

    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
}));

const TitledSection = ({
  title,
  description,
  titleFontSizes = { desktop: 40, mobile: 32 },
  buttons,
  style = {},
  titleColor = "text.lighter",
  textColor = "text.lighter",
  textAlign = "left",
  buttonSpacing = "36px",
  children,
  className = "titledSection",
}) => {
  const { mobile } = useBreakpoints();
  const titleFontSize = mobile ? titleFontSizes.mobile : titleFontSizes.desktop;
  const titleLineHeight = {
    20: 28 / 20,
    28: 36 / 28,
    32: 40 / 32,
    40: 48 / 40,
    48: 56 / 48,
  };

  return (
    <Wrapper style={style} textAlign={textAlign} className={className}>
      <Stack gap={2}>
        {title != null && (
          <Header
            fontSize={titleFontSize}
            color={titleColor}
            textAlign={textAlign}
            lineHeight={titleLineHeight[titleFontSize]}
            className="header"
          >
            {title}
          </Header>
        )}
        {description != null && (
          <Text
            color={textColor}
            textAlign={textAlign}
            fontSize={mobile ? 14 : 16}
            lineHeight={mobile ? "20px" : "24px"}
          >
            {description}
          </Text>
        )}
      </Stack>
      {buttons != null && (
        <StackedButtons style={{ marginTop: buttonSpacing }}>
          {buttons}
        </StackedButtons>
      )}
      {children}
    </Wrapper>
  );
};

export default TitledSection;
