import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH_MOBILE } from "config";

const Centered = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  maxWidth: 1575,
  position: "relative",
  margin: "0 auto",
}));

const Background = styled(Stack)(({ theme }) => ({
  position: "relative",
  paddingBottom: `19.4%`,
  width: "111%",
  backgroundImage: `url('/images/templates/home/accents/cards.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top center",

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    width: "260%",
    paddingBottom: `42%`,
  },
}));

const Cards = () => {
  return (
    <Centered>
      <Background />
    </Centered>
  );
};

export default Cards;
