import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { CONTAINER_WIDTH } from 'config';

export const Container = styled(Stack)(() => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
}));

export const Content = styled(Stack)(() => ({
  background: 'rgba(20, 20, 20, 0.80)',
  backdropFilter: 'blur(6px)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '16px 80px',
  borderTop: '1px solid #4E4F51',

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    padding: '16px 40px',
  },
}));

export const Left = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 60,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 40,
  },
}));

export const Right = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 100,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 50,
  },
}));

export const Pagination = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 12,
}));

export const PageNumber = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  minWidth: 40,
}));

export const ChevronDownWrapper = styled(Stack)(({ open }) => ({
  transform: open ? 'rotateZ(180deg)' : 'unset',
}));

export const Button = styled(Stack)(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
}));

export const Dropdown = styled(Stack)(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 4,
  borderRadius: 32,
  border: '1px solid #4E4F51',
  padding: '8px 12px',
}));

export const ZoomWrapper = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
}));

export const Divider = styled(Stack)(() => ({
  width: 1,
  height: 48,
  backgroundColor: '#4E4F51',
}));
