import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { ReactComponent as DiscordSvg } from "icons/discord.svg";
import fonts from "theme/fonts";
import Text from "components/atoms/Typography/Text";
import CustomLink from "components/atoms/CustomLink";
import { AXIE_DISCORD } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.colors.socials.discord,
  color: theme.palette.colors.white,
  borderRadius: 8,
  padding: "8px 16px",
  position: "fixed",
  bottom: 16,
  right: 16,
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
  transition: "0.2s all ease",
  zIndex: 99,

  "&:hover": {
    transform: "scale(1.08)",
  },

  [theme.breakpoints.down("sm")]: {
    right: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "6px 12px",

    "& .content": {
      display: "none",
    },
  },
}));

const CTA = styled(Stack)(({ theme }) => ({
  fontFamily: fonts.header,
  fontSize: 17,
  textTransform: "uppercase",
  lineHeight: 1.2,
}));

const MeetTheCommunity = () => {
  return (
    <CustomLink href={AXIE_DISCORD}>
      <Wrapper>
        <DiscordSvg />
        <Stack className="content">
          <Text>Confused?</Text>
          <CTA>
            Meet the
            <br />
            community
          </CTA>
        </Stack>
      </Wrapper>
    </CustomLink>
  );
};

export default MeetTheCommunity;
