import React from "react";
import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import Experience from "./Experience";
import data from "data/data";
import Container from "components/atoms/Container";
import { CONTAINER_WIDTH, CONTAINER_WIDTH_MOBILE } from "config";
import ExperienceColumn from "./ExperienceColumn";
import { useParallax } from "react-scroll-parallax";
import { useBreakpoints } from "hooks/useBreakpoints";

const Columns = styled(Stack)(({ theme }) => ({
  marginBottom: "15%",

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 40,
  },

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    marginBottom: 80,
  },
}));

const Experiences = ({ target }) => {
  const { desktopDown } = useBreakpoints();
  const experiences = useParallax({
    speed: desktopDown ? 0 : 17,
    targetElement: target.current,
  });

  return (
    <Box ref={experiences.ref}>
      <Container>
        <Columns>
          <ExperienceColumn shift={true}>
            <Experience experience={data.experiences.origins} />
            <Experience experience={data.experiences.breeding} />
          </ExperienceColumn>

          <ExperienceColumn>
            <Experience experience={data.experiences.land} />
            <Experience experience={data.experiences.creator} />
            <Experience experience={data.experiences.builder} />
          </ExperienceColumn>
        </Columns>
      </Container>
    </Box>
  );
};

export default Experiences;
