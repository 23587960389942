import * as types from "./axieActionTypes";

/* Price Floors */
export const fetchPriceFloors = () => ({
  type: types.AXIE_FETCH_PRICE_FLOORS,
});

export const fetchPriceFloorsStarted = () => ({
  type: types.AXIE_FETCH_PRICE_FLOORS_STARTED,
});

export const fetchPriceFloorsFailed = () => ({
  type: types.AXIE_FETCH_PRICE_FLOORS_FAILED,
});

export const fetchPriceFloorsSucceeded = (data) => ({
  type: types.AXIE_FETCH_PRICE_FLOORS_SUCCEEDED,
  data,
});

/* Marketplace Stats */

export const fetchMarketplaceStats = () => ({
  type: types.AXIE_FETCH_MARKETPLACE_STATS,
});

export const fetchMarketplaceStatsStarted = () => ({
  type: types.AXIE_FETCH_MARKETPLACE_STATS_STARTED,
});

export const fetchMarketplaceStatsFailed = () => ({
  type: types.AXIE_FETCH_MARKETPLACE_STATS_FAILED,
});

export const fetchMarketplaceStatsSucceeded = (data) => ({
  type: types.AXIE_FETCH_MARKETPLACE_STATS_SUCCEEDED,
  data,
});