import React from "react";
import { Box, Dialog as MuiDialog } from "@mui/material";
import ReactPlayer from "react-player";
import { isNullOrEmptyString } from "helpers/utils";
import styled from "@emotion/styled";
import CloseButton from "./CloseButton";
import { useBreakpoints } from "hooks/useBreakpoints";

const Dialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  "& .MuiPaper-root": {
    width,
    maxWidth: width,
    overflow: "visible",
    position: "relative",
    background: "transparent",
    boxShadow: "none",
    justifyContent: "center",
    padding: 0,
  },
  "& .closeButton": {
    position: "fixed",
    top: 24,
    right: 24,
    zIndex: 99,
  },
}));

const Video = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "92vw",
  height: `${92 / (16 / 9)}vw`,
  margin: "0 auto",
}));

const FullScreenVideo = ({ open, setOpen, url, width = "100%" }) => {
  const { desktopDown } = useBreakpoints();
  if (isNullOrEmptyString(url)) return null;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={width}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
    >
      <CloseButton onClick={handleClose} width={desktopDown ? 60 : 80} />

      <Video>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: desktopDown ? 20 : 40,
            overflow: "hidden",
          }}
        />
      </Video>
    </Dialog>
  );
};

export default FullScreenVideo;
