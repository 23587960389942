import React from "react";
import styled from "@emotion/styled";
import { Button as MuiButton, useTheme } from "@mui/material";
import fonts from "theme/fonts";
import fontSizes from "theme/fontSizes";
import CustomLink from "../CustomLink";
import makeStyles from "./styles";

const Wrapper = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ style }) => ({
  ...(style.backgroundImage && {
    backgroundImage: `url('/images/buttons/${style.backgroundImage}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  }),
  ...(style.background && {
    background: style.background,
  }),
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  width: style.width,
  height: style.height,
  fontFamily: fonts.header,
  fontSize: fontSizes[style.fontSize],
  textTransform: style.textTransform,
  color: style.color,
  borderRadius: style.borderRadius,
  transition: "0.2s transform ease",

  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",

    ...(style.hover && {
      ...style.hover,
    }),
  },
}));

const Button = ({
  variant = "light",
  className = "button",
  size = "small",
  children,
  href,
  to,
  onClick,
}) => {
  const theme = useTheme();

  const styles = makeStyles(variant, size, theme);

  return (
    <CustomLink href={href} to={to} onClick={onClick}>
      <Wrapper style={styles || {}} className={className}>
        {children}
      </Wrapper>
    </CustomLink>
  );
};

export default Button;
