import React from "react";
import styled from "@emotion/styled";
import { Drawer as MuiDrawer } from "@mui/material";
import Links from "../molecules/Links";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import Button from "components/atoms/Button";
import { PLAY_NOW_URL } from "config";
import colors from "theme/colors";

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  position: "relative",

  "& .MuiPaper-root": {
    backgroundImage: "url('/images/nav/nav-drawer-paper.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    gap: theme.spacing(4),
    alignItems: "flex-start",
    padding: "72px 32px",
    width: "100%",
  },

  "& .close": {
    position: "absolute",
    top: 20,
    right: 20,
    transition: "0.2s transform ease",

    "&:hover": {
      transform: "scale(1.2)",
      cursor: "pointer",
    },
  },
}));

const MobileDrawer = ({ open, handleDrawerClose }) => {
  return (
    <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
      <CloseIcon
        color={colors.mocha[1]}
        className="close"
        onClick={handleDrawerClose}
      />
      <Links type="mobile" handleDrawerClose={handleDrawerClose} />
      <Button variant="plain" size="large" href={PLAY_NOW_URL}>
        Play for Free
      </Button>
    </Drawer>
  );
};

export default MobileDrawer;
