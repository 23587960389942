import { AXIE_TWITTER_HANDLE, AXIE_WEBSITE } from "config";

export const defaultSeo = {
  title: "Axie Infinity",
  description:
    "Discover the groundbreaking world of blockchain gaming with Axie Infinity.",
  url: AXIE_WEBSITE,
  twitter: AXIE_TWITTER_HANDLE,
  keywords: "axie infinity",
  twitterImage: "https://axieinfinity.com/images/social/axie-infinity.jpg",
  ogImage: "https://axieinfinity.com/images/social/axie-infinity.jpg",
};

export const pageSeo = {
  home: {
    title:
      "Axie Infinity - Battle, Collect, and Trade Collectible NFT Creatures",
    description: defaultSeo.description,
  },
  termsOfUse: {
    title: "Terms of Use",
    description:
      "Learn about the official terms and conditions for using Axie Infinity's website and services. Review our terms of use before playing.",
  },
  privacyPolicy: {
    title: "Privacy Policy",
    description:
      "We take your privacy seriously. Review our privacy policy to learn how we protect your data and personal information while you play on Axie Infinity.",
  },
  codeOfConduct: {
    title: "Code of Conduct",
    description:
      "Axie Infinity is committed to fostering a positive and inclusive community. Review our code of conduct to learn about our guidelines for ethical and respectful play.",
  },
  buildersTerms: {
    title: "Builders Program - Terms and Conditions",
    description:
      "Learn about the terms and conditions of the Axie Infinity Builders Program. Join our community of builders and creators and help shape the future of Axie Infinity.",
  },
  axs: {
    title: "Axie Infinity Shards (AXS)",
    description:
      "Learn about the AXS token, the cryptocurrency of the Axie universe. Use AXS to buy, breed, and battle with unique creatures, and more in Axie Infinity.",
  },
  page404: {
    title: "Page Not Found",
    description: defaultSeo.description,
  },
  lore: {
    title: "Lore",
    description: "",
  },
};
