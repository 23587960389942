import React from "react";
import Image from "components/atoms/Image";
import CustomLink from "components/atoms/CustomLink";

const Logo = ({ width = 110 }) => {
  return (
    <CustomLink to="/">
      <Image
        width={width}
        src="/images/branding/axie-infinity-logo.png"
        alt="Axie Infinity Logo"
        className="logo"
      />
    </CustomLink>
  );
};

export default Logo;
