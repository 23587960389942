import axios from "axios";
import { AXIE_INFINITY_GRAPHQL } from "config";
import {
  axieFloorPrices,
  ethExchangeRate,
  getAxieBriefList,
  getAxieDetail,
  getAxieTotalCounter,
  marketplaceStats,
} from "./endpoints";

const postHeaders = {
  "Content-Type": "application/json",
};

export const requestGetAxieDetail = async (axieId) => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: getAxieDetail.query,
      variables: getAxieDetail.variables(axieId),
    },
    { postHeaders }
  );
  return response.data.data.axie;
};

export const requestSearchAxies = async (variables) => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: getAxieBriefList.query,
      variables: getAxieBriefList.variables(variables),
    },
    { postHeaders }
  );
  return response.data.data;
};

export const requestTotalAxies = async () => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: getAxieTotalCounter.query,
    },
    { postHeaders }
  );
  return response.data.data;
};

export const requestPriceFloors = async () => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: axieFloorPrices.query,
    },
    { postHeaders }
  )
  return response.data.data;
};

export const requestMarketplaceStats = async () => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: marketplaceStats.query,
      variables: marketplaceStats.variables(),
    },
    { postHeaders }
  );
  return response.data.data;
};

export const requestEthExchangeRate = async () => {
  const response = await axios.post(
    AXIE_INFINITY_GRAPHQL,
    {
      query: ethExchangeRate.query,
      variables: ethExchangeRate.variables(),
    },
    { postHeaders }
  );
  return response.data.data;
};
