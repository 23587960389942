import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Stat from "./Stat";
import { BREAKPOINT } from ".";
import { fetchMarketplaceStats } from "store/axies/axieActions";
import { connect } from "react-redux";
import {
  getMarketplaceStats,
  getMarketplaceStatsStatus,
} from "store/axies/selectors";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  gap: 20,

  [`@media (max-width: ${BREAKPOINT}px)`]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 28,
  },
}));

const Stats = ({ stats, fetchMarketplaceStats, status }) => {
  useEffect(() => {
    fetchMarketplaceStats();
  }, [fetchMarketplaceStats]);

  if (status.loading || status.failed) return null;

  return (
    <Wrapper>
      {stats.map((stat) => (
        <Stat key={stat.title} stat={stat} />
      ))}
    </Wrapper>
  );
};

const mapDispatchToProps = { fetchMarketplaceStats };

const mapStateToProps = (state) => ({
  stats: getMarketplaceStats(state),
  status: getMarketplaceStatsStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
