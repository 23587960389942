import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled(Stack)(() => ({
  background: 'rgba(20, 20, 20, 0.80)',
  backdropFilter: 'blur(6px)',
  position: 'fixed',
  bottom: 0,
  width: '100%',
}));

export const Content = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 8,
  borderTop: '1px solid #4E4F51',
  zIndex: 1,
}));

export const Left = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
