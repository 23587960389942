import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled(Stack)(({ open }) => ({
  background: 'rgba(20, 20, 20, 0.80)',
  backdropFilter: 'blur(6px)',
  padding: '16px 0',
  transform: open ? 'translateY(0%)' : 'translateY(150%)',
  transition: 'transform 0.3s ease-out',
}));

export const ArrowRightButton = styled(Stack)(() => ({
  position: 'absolute',
  right: 16,
  zIndex: 1,
  cursor: 'pointer',
}));

export const ArrowLeftButton = styled(Stack)(() => ({
  position: 'absolute',
  left: 16,
  zIndex: 1,
  cursor: 'pointer',
}));

export const LeftOverlay = styled(Stack)(() => ({
  background: 'linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.00) 100%)',
  width: 80,
  height: '100%',
  position: 'absolute',
  left: 0,
}));

export const RightOverlay = styled(Stack)(() => ({
  background:
    'linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0.00) 100%)',
  width: 80,
  height: '100%',
  position: 'absolute',
  right: 0,
}));
