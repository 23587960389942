import * as types from "../axieActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import { requestPriceFloors } from "services/api";
import * as actions from "../axieActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchPriceFloorRequest() {
  try {
    yield put(actions.fetchPriceFloorsStarted());
    const data = yield retry(RETRY_TIMES, RETRY_DELAY, requestPriceFloors);
    yield put(actions.fetchPriceFloorsSucceeded(data));
  } catch (e) {
    yield put(actions.fetchPriceFloorsFailed());
  }
}

export default function* priceFloorsSaga() {
  yield takeEvery(types.AXIE_FETCH_PRICE_FLOORS, makeFetchPriceFloorRequest);
}
