export const formatUsd = (amount) => {
  if (amount > 999999) {
    return abbreviateUsd(amount);
  }
  return new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "usd",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);
};

export const abbreviateUsd = (amount) => {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    style: "currency",
    currency: "usd",
  }).format(amount);
};

export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
