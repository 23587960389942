import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled(Stack)(() => ({
  height: '100%',
  cursor: 'pointer',
  border: '1px solid transparent',
  borderRadius: 8,
  overflow: 'hidden',

  '&:hover': {
    border: '1px solid #F0DCC0',
  },
}));

export const Content = styled(Stack)(() => ({
  position: 'relative',
}));

export const ImageWrapper = styled(Stack)(() => ({
  height: 'auto',
  width: '300px',

  [`@media (max-width: 450px)`]: {
    width: '100%',
  },
}));

export const Overlay = styled(Stack)(() => ({
  background:
    'linear-gradient(0deg, rgba(12, 10, 9, 0.80) 0%, rgba(12, 10, 9, 0.00) 100%)',
  width: '100%',
  height: '100%',
  position: 'absolute',
}));
