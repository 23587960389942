import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Marketplace from "./Marketplace";
import PlayCollectOwn from "./PlayCollectOwn";
import WoodDivider from "components/atoms/WoodDivider";
import { CONTAINER_WIDTH } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundImage: `url('/images/templates/home/accents/wood-background-dark.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  gap: 80,
  paddingTop: theme.spacing(10),
  paddingBottom: 80,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const Section2 = () => {
  return (
    <>
      <Wrapper>
        <Marketplace />
        <PlayCollectOwn />
      </Wrapper>
      <WoodDivider />
    </>
  );
};

export default Section2;
