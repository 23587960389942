import { useNavigate, useParams } from 'react-router-dom';

import Text from 'components/atoms/Typography/Text';
import { links } from 'data/data';
import { getPageId, onScrollToElement } from 'pages/Lore/utils';
import fontSizes from 'theme/fontSizes';

import { Image, ImageOverlay, ImageWrapper, PageWrapper } from './styles';

const PageItem = (props) => {
  const navigation = useNavigate();
  const { chapterId, pageId } = useParams();

  const { currentPage, image } = props;
  const isSelectedPage = currentPage === Number(pageId);

  const onSelectPage = (pageNumber) => {
    onScrollToElement(`#${getPageId(chapterId, pageNumber)}`);
    navigation(`${links.lore.to}/${chapterId}/${pageNumber}`, {
      replace: true,
    });
  };

  return (
    <PageWrapper>
      <ImageWrapper
        key={`page-${currentPage}`}
        onClick={() => onSelectPage(currentPage)}
      >
        <Image
          alt={`page-${currentPage}`}
          src={image}
          selected={isSelectedPage}
        />
        <ImageOverlay selected={isSelectedPage} />
      </ImageWrapper>
      <Text fontSize={fontSizes[14]} color="text.white">
        {currentPage}
      </Text>
    </PageWrapper>
  );
};

export default PageItem;
