import { typography } from "./typography";
import { baseline } from "./baseline";
import { buttons } from "./buttons";
import { links } from "./links";
import { appBar } from "./appBar";
import { dialog } from "./dialog";
import { tabs } from "./tabs";

const components = {
  ...baseline,
  ...typography,
  ...buttons,
  ...links,
  ...appBar,
  ...dialog,
  ...tabs,
};

export default components;
