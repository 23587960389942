import React from "react";
import { Stack } from "@mui/material";
import Header from "./Header";
import Content from "./Content";

const SubPage = ({
  title,
  description,
  headerLinks,
  children,
  wrapperClass,
  fullWidth,
}) => {
  return (
    <Stack>
      <Header
        title={title}
        description={description}
        headerLinks={headerLinks}
      />
      <Content wrapperClass={wrapperClass} fullWidth={fullWidth}>
        {children}
      </Content>
    </Stack>
  );
};

export default SubPage;
