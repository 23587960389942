import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import CustomLink from "components/atoms/CustomLink";
import fontSizes from "theme/fontSizes";

export const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["type", "status"].includes(prop),
})(({ theme, type, status }) => ({
  padding: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(0.75),
  userSelect: "none",
  flexDirection: "row",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  fontSize: fontSizes[16],
  fontWeight: 800,

  "&.navLink": {
    color: theme.palette.colors.mocha[1],

    "& svg path": {
      fill: theme.palette.colors.mocha[1],
    },

    "&:hover": {
      color: theme.palette.colors.white,

      "& svg path": {
        fill: theme.palette.colors.white,
      },
    },
  },

  ...(status === "active" && {
    "&.navLink": {
      color: theme.palette.colors.white,
    },
  }),

  ...(type === "mobile" && {
    "&.navLink": {
      color: theme.palette.colors.mocha[4],
      padding: 0,

      "& svg path": {
        fill: theme.palette.colors.mocha[4],
      },
    },
  }),
}));

const LinkWrapper = ({ children, type, to, href, status, onClick }) => {
  return (
    <CustomLink to={to} href={href} onClick={onClick}>
      <Wrapper type={type} className="navLink" status={status}>
        {children}
      </Wrapper>
    </CustomLink>
  );
};

export default LinkWrapper;
