import React, { useEffect, useRef, useState } from "react";
import { PlaygroundGame } from "./Playground/PlaygroundGame";
import * as PIXI from "pixi.js";
import "pixi-spine";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PuffLoader from "./Playground/PuffLoader";
import Directions from "./Playground/Directions";
import useWindowWidth from "hooks/useWindowWidth";
import { getAxieGenes } from "./Playground/getAxieGenes";
import { useBreakpoints } from "hooks/useBreakpoints";

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;

export const AXIE_SCALE = 0.29;

export const AXIE_DIRECTION = {
  Left: 1,
  Right: -1,
};

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "containerStyle",
})(({ containerStyle }) => ({
  position: "relative",

  ".directions": {
    position: "absolute",
    left: "50%",
    bottom: "10%",
    transform: "translateX(-50%)",
    opacity: 0.5,
  },

  ".puffLoader": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 100,
  },

  ...containerStyle,
}));

const Container = styled(Stack)(({ width, height }) => ({
  position: "relative",
  width,
  height,
}));

const AnimatedAxie = ({ axieId, showDirections = false, containerStyle }) => {
  const container = useRef(null);
  const gameRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const width = useWindowWidth();
  const [genes, setGenes] = useState(null);
  const { customBreakpoint } = useBreakpoints(695);

  useEffect(() => {
    if (!container) return;
    if (!container.current) return;
    if (!genes) return;

    const canvasContainer = container.current;

    if (canvasContainer.childElementCount > 0) {
      canvasContainer.lastChild?.remove();
    }
    setLoading(true);

    const { offsetWidth, offsetHeight } = canvasContainer;

    const game = new PlaygroundGame({
      resolution: window.devicePixelRatio,
      autoStart: true,
      width: offsetWidth,
      height: offsetHeight,
      transparent: true,
      genes,
      axieScale: customBreakpoint ? 0.2 : 0.29,
      setLoading,
    });

    gameRef.current = game;
    gameRef.current.startGame();
    canvasContainer.appendChild(game.view);

    return () => {
      if (game) {
        game.destroy();
      }
    };
  }, [genes, width, customBreakpoint]);

  useEffect(() => {
    const getGenes = async (axieId) => {
      const axieGenes = await getAxieGenes(axieId);
      setGenes(axieGenes);
    };

    getGenes(axieId);
  }, [axieId]);

  return (
    <Wrapper sx={containerStyle}>
      {loading && <PuffLoader size={customBreakpoint ? 100 : 120} />}
      {showDirections && <Directions />}
      <Container ref={container} width="100%" height="100%"></Container>
    </Wrapper>
  );
};

export default AnimatedAxie;
