import React from "react";
import Button from "components/atoms/Button";
import CustomLink from "components/atoms/CustomLink";
import { AXIE_DISCORD } from "config";
import Text from "components/atoms/Typography/Text";
import Header from "components/atoms/Typography/Header";
import Container from "components/atoms/Container";
import {
  Background,
  Board,
  ContentWrapper,
  Content,
  TextContent,
} from "./styles";

const Page404 = () => {
  return (
    <Background>
      <Container>
        <Board>
          <ContentWrapper>
            <Header
              color="text.light"
              fontSize={100}
              variant="h1"
              lineHeight="124px"
            >
              404
            </Header>
            <Content>
              <TextContent>
                <Text
                  color="text.light"
                  textAlign="center"
                  fontSize={32}
                  lineHeight="24px"
                >
                  Page not found
                </Text>
                <Text color="text.light" textAlign="center">
                  You can go back to{" "}
                  <CustomLink to="/" className="textLink">
                    homepage
                  </CustomLink>{" "}
                  or you become part of our growing community.
                </Text>
              </TextContent>
              <Button variant="dark" size={224} href={AXIE_DISCORD}>
                Join our Discord
              </Button>
            </Content>
          </ContentWrapper>
        </Board>
      </Container>
    </Background>
  );
};

export default Page404;
