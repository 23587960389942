export const AXIE_INFINITY_GRAPHQL =
  "https://graphql-gateway.axieinfinity.com/graphql";

export const CONTAINER_WIDTH = 1280;
export const CONTAINER_WIDTH_MOBILE = 360;

export const APP_DOT_AXIE_URL = "https://app.axieinfinity.com";
export const MAVIS_HUB_URL = "https://hub.skymavis.com";
export const MARKETPLACE_URL = `${APP_DOT_AXIE_URL}/marketplace`;
export const MARKETPLACE_URL_AXIES = `${MARKETPLACE_URL}/axies`;
export const GAMES_URL = `${MAVIS_HUB_URL}/games?partnershipType=sky-mavis`;
export const GAMES_URL_ORIGINS = `${GAMES_URL}/origins`;
export const GAMES_URL_LAND = `${GAMES_URL}/homeland-alpha`;

export const PLAY_NOW_URL = "https://welcome.skymavis.com/download";
export const WHITEPAPER_URL = "https://whitepaper.axieinfinity.com";
export const AXIE_CDN = "https://axiecdn.axieinfinity.com";
export const BLOG_URL = "https://axie.substack.com";
export const AXS_AUDIT_REPORT =
  "https://cdn.axieinfinity.com/landing-page/AXS_Audit_Report.pdf";

export const BUILDERS_URL = "https://axie.tech/games";
export const CREATORS_URL = "https://axie.substack.com/p/axiecreatorprogram";
export const BREEDING_URL = "https://welcome.axie.tech/guides/breeding";

export const GAMEPLAY_TRAILER_URL =
  "https://www.youtube.com/watch?v=X2z_YIeettE";
export const AXIECON_ESPORTS_REPLAY_URL = "https://youtu.be/kT4EiNyDEm0?t=2373";

export const AXIE_DISCORD = "https://welcome.skymavis.com/regional-servers";
export const AXIE_TWITTER = "https://twitter.com/AxieInfinity";
export const AXIE_TWITTER_HANDLE = "@AxieInfinity";
export const AXIE_WEBSITE = "https://axieinfinity.com";

export const MAX_AXIE_ID_FALLBACK = 1_000_000;
