const palette = {
  white: "#ffffff",
  black: "#000000",
  primary: "#ffffff",
  secondary: "#000000",
  mocha: {
    1: "#F0DCC0",
    2: "#DFA363",
    4: "#703519",
    5: "#3A1E10",
  },
  sky: {
    1: "#78B9FB",
    2: "#1D9BF0",
  },
  blue: {
    600: "#115CB9",
  },
  brown: {
    light: "#B18459",
    medium: "#6C3B28",
  },
  darkGrey: "#141414",
};

const text = {
  regular: palette.mocha[4],
  dark: palette.mocha[5],
  light: palette.mocha[2],
  lighter: palette.mocha[1],
  blue: palette.sky[1],
  white: palette.white,
};

const socials = {
  discord: "#5765F2",
  twitter: palette.sky[2],
};

const colors = {
  ...palette,
  text,
  socials,
};

export default colors;
