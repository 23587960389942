import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Header from "components/atoms/Typography/Header";
import Image from "components/atoms/Image";
import data from "data/data";
import { ParallaxBanner } from "react-scroll-parallax";
import { useBreakpoints } from "hooks/useBreakpoints";

const Centered = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  position: "relative",
  margin: "0 auto",
  zIndex: 3,
}));

const Background = styled(Stack)(({ theme }) => ({
  backgroundImage: `url('/images/templates/home/esports/background-0.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  width: "100%",
  minWidth: 912,
  minHeight: 513,
  position: "relative",
}));

const Axies = styled(Stack)(({ theme }) => ({
  position: "absolute",
  backgroundImage: `url('/images/templates/home/esports/background-1.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  maxWidth: 1400,
  aspectRatio: "1282 / 705",
  width: "100%",
  top: "50%",
  left: "50%",
  transform: "translateY(-50%) translateX(-50%)",
}));

const WoodenSign = styled(Stack)(({ theme }) => ({
  position: "absolute",
  width: 406,
  top: -10,
  left: "50%",
  transform: "translateX(-50%)",

  ".title": {
    position: "absolute",
    bottom: "23%",
    left: "50%",
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",
  },

  [`@media (max-width: 470px)`]: {
    width: 290,

    ".title": {
      bottom: "22%",
    },
  },
}));

const Jumbotron = () => {
  const aspectRatio = "1440 / 866";
  const { customBreakpoint } = useBreakpoints(470);

  return (
    <Centered>
      <Background>
        <Axies />
        <ParallaxBanner
          layers={[
            {
              image: "/images/templates/home/esports/background-2.png",
              speed: customBreakpoint ? 0 : 6,
            },
          ]}
          style={{
            aspectRatio,
          }}
        >
          <WoodenSign>
            <Header
              textAlign="center"
              color="text.lighter"
              fontSize={customBreakpoint ? 24 : 32}
              className="title"
              style={{ lineHeight: customBreakpoint ? 32 / 28 : 40 / 32 }}
            >
              {data.esports.sectionTitle}
            </Header>
            <Image
              src="/images/templates/home/esports/wooden-sign.png"
              alt="Wooden Sign"
            />
          </WoodenSign>
        </ParallaxBanner>
      </Background>
    </Centered>
  );
};

export default Jumbotron;
