import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import Container from 'components/atoms/Container';

export const Background = styled(Stack)(() => ({
  backgroundImage: `url('/images/templates/lore/background.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
}));

export const Content = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 80,
  paddingBottom: 80,
  marginTop: 250,

  [`@media (max-width: 400px)`]: {
    marginTop: 50,
  },
}));

export const ContainerWrapper = styled(Container)(() => ({
  height: '100%',

  [`@media (max-width: 400px)`]: {
    maxWidth: 310,
    padding: '100px 0px 0px',
  },
}));

export const TitleWrapper = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const Chapters = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
}));
