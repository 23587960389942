import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Wrapper from "./styles";
import { CONTAINER_WIDTH } from "config";

const PaperBorder = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url('/images/templates/sub-page/paper-border.jpg')`,
  backgroundRepeat: "repeat-x",
  backgroundPosition: "top center",
  height: 21,
  width: "100%",
}));

const Paper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  position: "relative",
  backgroundImage: `url('/images/templates/sub-page/paper.jpg')`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center center",
  backgroundSize: "contain",
  minHeight: 400,
  paddingTop: 80,
  paddingBottom: 80,
  width: "100%",
}));

const Decoration = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url('/images/templates/sub-page/axie-illustration.png')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  height: 192,
  width: 1400,
  position: "absolute",
  top: 26,
  left: "50%",
  transform: "translateX(-50%)",
  mixBlendMode: "luminosity",
  zIndex: 1,
  opacity: 0.7,
}));

const Inner = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})(({ fullWidth }) => ({
  position: "relative",
  zIndex: 3,
  gap: 12,
  width: "100%",
  maxWidth: fullWidth ? CONTAINER_WIDTH : 1084,
  margin: "0 auto",
  padding: "0 20px",
}));

const Content = ({ children, wrapperClass, fullWidth }) => {
  return (
    <Wrapper className={wrapperClass}>
      <PaperBorder />
      <Paper>
        <Decoration />
        <Inner fullWidth={fullWidth}>{children}</Inner>
      </Paper>
      <PaperBorder />
    </Wrapper>
  );
};

export default Content;
