import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Text from 'components/atoms/Typography/Text';
import { MOBILE_BREAKPOINT } from 'components/organisms/Nav';
import { links } from 'data/data';

import fonts from 'theme/fonts';
import fontSizes from 'theme/fontSizes';

import ChapterCover from './ChapterCover';
import { chapters } from './data';
import {
  Background,
  Chapters,
  ContainerWrapper,
  Content,
  TitleWrapper,
} from './styles';

const Lore = () => {
  const navigation = useNavigate();
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_BREAKPOINT}px)`);

  const onSelectChapter = (chapterId) => {
    navigation(`${links.lore.to}/${chapterId}/1`);
  };

  return (
    <Background>
      <ContainerWrapper>
        <Content>
          <TitleWrapper>
            <Text
              color="text.lighter"
              fontSize={isMobileView ? fontSizes[36] : fontSizes[40]}
              lineHeight={1.2}
              fontFamily={fonts.header}
            >
              Tales of Lunacia
            </Text>
            <Text
              color="text.lighter"
              fontSize={fontSizes[16]}
              lineHeight={1.5}
              style={{ maxWidth: 628 }}
            >
              These stories reveal secrets buried deep within Lunacia's DNA.
            </Text>
          </TitleWrapper>
          <Chapters>
            {Object.entries(chapters).map(([key, chapterData]) => (
              <ChapterCover
                key={key}
                coverImage={chapterData.coverImage}
                title={`${chapterData.name}: ${chapterData.title}`}
                onClick={() => onSelectChapter(key)}
              />
            ))}
          </Chapters>
        </Content>
      </ContainerWrapper>
    </Background>
  );
};

export default Lore;
