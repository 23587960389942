import data from "data/data";
import { createSelector } from "reselect";
import { abbreviateUsd } from "helpers/display";

export const getFloorPrices = (state) => state.axies.floorPrices.data || {};
export const getFloorPricesStatus = (state) =>
  state.axies.floorPrices.status || {};

const marketplaceStatsData = (state) => state.axies.marketplaceStats.data || {};
export const getMarketplaceStatsStatus = (state) =>
  state.axies.marketplaceStats.status || {};

export const getMarketplaceStats = createSelector(
  [marketplaceStatsData],
  (statsData) => {
    const augmentedStats = data.marketplace.stats.map((stat) => {
      const rawAmount = statsData[stat.data];
      let formattedAmount = "??";

      if (rawAmount != null) {
        if (stat.type === "number") {
          formattedAmount = parseInt(rawAmount).toLocaleString();
        } else if (stat.type === "currency") {
          formattedAmount = abbreviateUsd(rawAmount);
        } else {
          formattedAmount = rawAmount;
        }
      }

      return {
        title: stat.title,
        icon: stat.icon,
        amount: formattedAmount,
      };
    });

    return augmentedStats;
  }
);
