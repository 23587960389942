import * as PIXI from "pixi.js";
import { Spine, TextureAtlas } from "pixi-spine";
import { AtlasAttachmentLoader, SkeletonJson } from "@pixi-spine/runtime-3.8";
import {
  AxieMixer,
  getAxieColorPartShift,
  getVariantAttachmentPath,
} from "@axieinfinity/mixer";

export class Figure extends Spine {
  static resourcePath = "https://axiecdn.axieinfinity.com/mixer-stuffs/v2/";

  constructor(mixer, axieScale) {
    const resources = Figure.getResources(mixer);
    const allTextures = {};

    for (const resource of resources) {
      const texture = PIXI.Texture.from(resource.imagePath);
      allTextures[resource.key] = texture;
    }

    const spineAtlas = new TextureAtlas();
    spineAtlas.addTextureHash(allTextures, false);

    const spineAtlasLoader = new AtlasAttachmentLoader(spineAtlas);

    const spineJsonParser = new SkeletonJson(spineAtlasLoader);

    const spineData = spineJsonParser.readSkeletonData(mixer.spine);
    super(spineData);

    this.mixer = mixer;
    this.scale.set(axieScale);
  }

  static async loadAndSpawn(loader, mixer, axieScale) {
    await this.loadResources(loader, mixer);
    return new Figure(mixer, axieScale);
  }

  static async fromAxieId(loader, genes, axieScale) {
    try {
      const mixer = new AxieMixer(genes).getAssets();

      if (!mixer) throw new Error("invalid mixer");

      const newFigure = await this.loadAndSpawn(loader, mixer, axieScale);

      newFigure.stateData.setMix("draft/run-origin", "action/idle/normal", 0.1);
      newFigure.stateData.setMix("action/idle/normal", "draft/run-origin", 0.2);

      return newFigure;
    } catch (e) {}
  }

  static async loadResources(loader, mixer) {
    loader.reset();
    const resources = this.getResources(mixer);
    resources.forEach((item) => {
      if (loader.resources[item.key] === undefined) {
        loader.add(item.key, item.imagePath);
      }
    });

    await new Promise((resolve) => loader.load(resolve));
  }

  static getResources(mixer) {
    const skinAttachments = mixer.spine.skins[0].attachments;
    const imagesToLoad = [];

    const partColorShift = getAxieColorPartShift(mixer.variant);
    for (const slotName in skinAttachments) {
      const skinSlotAttachments = skinAttachments[slotName];
      for (const attachmentName in skinSlotAttachments) {
        const path = skinSlotAttachments[attachmentName].path;

        let imagePath =
          this.resourcePath +
          getVariantAttachmentPath(
            slotName,
            path,
            mixer.variant,
            partColorShift
          );
        imagesToLoad.push({ key: path, imagePath });
      }
    }
    return imagesToLoad;
  }
}
