import Page from 'components/templates/Page';
import Pages from 'pages';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { chapters } from '../data';
import { getPageId, onScrollToElement } from '../utils';
import Footer from './Footer';
import { Container, ImagesWrapper } from './styles';

const Chapter = () => {
  const [isClient, setIsClient] = useState(false);
  const [isZoomIn, setIsZoomIn] = useState(false);

  const { chapterId, pageId = 1 } = useParams();

  const pages = chapters[chapterId]?.pages || [];
  const isEmptyChapter = pages.length === 0;

  const currentPageId = getPageId(chapterId, pageId);

  const onZoom = () => {
    setIsZoomIn(!isZoomIn);
    setTimeout(() => {
      onScrollToElement(`#${currentPageId}`);
    }, 250);
  };

  useEffect(() => {
    onScrollToElement(`#${currentPageId}`);
    setIsClient(true);
  }, []);

  if (isEmptyChapter) {
    return <Page page={<Pages.Page404 />} />;
  }

  return (
    <Container>
      <ImagesWrapper zoom={isZoomIn.toString()}>
        {pages.map((page, index) => {
          const chapterPageId = getPageId(chapterId, index + 1);
          return (
            <img
              className="chapter-page-image"
              id={chapterPageId}
              key={chapterPageId}
              src={page}
              alt=""
            />
          );
        })}
      </ImagesWrapper>
      {isClient && <Footer onZoom={onZoom} isZoomIn={isZoomIn} />}
    </Container>
  );
};

export default Chapter;
