import React from "react";
import { PropTypes } from "prop-types";
import { defaultSeo, pageSeo } from "./config";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description,
  keywords,
  url,
  twitter,
  twitterImage,
  ogImage,
  seoProp,
}) => {
  if (seoProp && seoProp in pageSeo) {
    title = pageSeo[seoProp].title;
    description = pageSeo[seoProp].description;
  }
  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:site_name" content={title} />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:creator" content={twitter} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  twitter: PropTypes.string,
  keywords: PropTypes.string,
  twitterImage: PropTypes.string,
  ogImage: PropTypes.string,
};

SEO.defaultProps = {
  title: defaultSeo.title,
  description: defaultSeo.description,
  url: defaultSeo.url,
  twitter: defaultSeo.twitter,
  keywords: defaultSeo.keywords,
  twitterImage: defaultSeo.twitterImage,
  ogImage: defaultSeo.ogImage,
};

export default SEO;
