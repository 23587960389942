import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import fontSizes from 'theme/fontSizes';
import colors from 'theme/colors';
import Text from 'components/atoms/Typography/Text';

import { ReactComponent as Book } from 'icons/book.svg';
import { ReactComponent as CaretLeft } from 'icons/caret-left.svg';

import { links } from 'data/data';
import { chapters } from 'pages/Lore/data';

import PageSelection from './PageSelection';
import { Container, Content, Left } from './styles';

const MobileFooter = () => {
  const { chapterId } = useParams();
  const navigation = useNavigate();

  const [isShowPageSelection, setIsShowPageSelection] = useState(false);

  const chapterData = chapters[chapterId] || {};
  const { name, title } = chapterData;

  const onChangeShowPageSelection = () => {
    setIsShowPageSelection(!isShowPageSelection);
  };

  const onGoBack = () => {
    navigation(links.lore.to);
  };

  return (
    <>
      <PageSelection
        open={isShowPageSelection}
        onClose={onChangeShowPageSelection}
      />
      <Container>
        <Content>
          <Left>
            <IconButton onClick={onGoBack}>
              <CaretLeft height={32} color={colors.mocha[1]} />
            </IconButton>
            <Text
              fontSize={fontSizes[14]}
              color="text.lighter"
              style={{ textTransform: 'uppercase' }}
            >
              {`${name} - ${title}`}
            </Text>
          </Left>
          <IconButton onClick={onChangeShowPageSelection}>
            <Book height={24} color={colors.mocha[1]} />
          </IconButton>
        </Content>
      </Container>
    </>
  );
};

export default MobileFooter;
