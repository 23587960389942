import React from "react";
import { Stack } from "@mui/material";
import Text from "components/atoms/Typography/Text";
import { useBreakpoints } from "hooks/useBreakpoints";

const PostText = ({ post }) => {
  const { mobile } = useBreakpoints();

  return (
    <Stack gap="4px">
      <Text
        textAlign="center"
        fontSize={mobile ? 20 : 24}
        fontWeight={800}
        color="text.regular"
        style={{ lineHeight: mobile ? 32 / 24 : 28 / 20 }}
      >
        {post.title}
      </Text>
      <Text
        textAlign="center"
        color="text.regular"
        style={{ lineHeight: 24 / 16 }}
      >
        {post.date}
      </Text>
    </Stack>
  );
};

export default PostText;
