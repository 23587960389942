import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as ChevronDown } from "icons/chevron-down.svg";

const Wrapper = styled(Stack, {
  shouldForwardProp: (props) => props !== "isOpen",
})(({ isOpen }) => ({
  transition: "0.15s transform cubic-bezier(0.4, 0, 0, 1.02)",
  svg: {
    width: 16,
  },
  ...(isOpen && {
    transform: "rotate(180deg)",
  }),
}));

const DropdownArrow = ({ isOpen }) => {
  return (
    <Wrapper isOpen={isOpen}>
      <ChevronDown />
    </Wrapper>
  );
};

export default DropdownArrow;
