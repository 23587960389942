import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Nav from "components/organisms/Nav";
import Footer from "components/organisms/Footer";
import ScrollToTop from "components/atoms/ScrollToTop";
import SEO from "routes/SEO";
import MeetTheCommunity from "components/atoms/Button/MeetTheCommunity";

const Page = ({ page, seo }) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <SEO seoProp={seo} />
      <ScrollToTop />
      <Nav />
      {page}
      <Footer />
      <MeetTheCommunity />
    </Box>
  );
};

Page.propTypes = {
  page: PropTypes.element,
  seo: PropTypes.string,
};

Page.defaultProps = {};

export default Page;
