import Home from "./Home";
//import BuildersTerms from "./BuildersTerms";
import CodeOfConduct from "./CodeOfConduct";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import Page404 from "./Page404";
import AXS from "./AXS";
import Lore from "./Lore";
import Chapter from "./Lore/Chapter";

const Pages = {
  Home,
  CodeOfConduct,
  PrivacyPolicy,
  TermsOfUse,
  Page404,
  AXS,
  Lore,
  Chapter
};

export default Pages;
