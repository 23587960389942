import { useMediaQuery } from '@mui/material';
import { SMALL_BREAKPOINT } from 'components/organisms/Nav';
import { links } from 'data/data';
import { parsePageId } from 'pages/Lore/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

const Footer = (props) => {
  const { isZoomIn, onZoom } = props;

  const [pageId, setPageId] = useState();

  const { chapterId } = useParams();
  const navigation = useNavigate();
  const isMobileView = useMediaQuery(`(max-width:${SMALL_BREAKPOINT}px)`);

  const isInViewport = (element) => {
    const { top } = element.getBoundingClientRect();
    return top >= 0 && top <= window.innerHeight;
  };

  useEffect(() => {
    const elements = document?.querySelectorAll('.chapter-page-image');
    window.addEventListener('scroll', function () {
      for (const element of elements) {
        if (isInViewport(element)) {
          const elementId = element.id;
          const newPageId = parsePageId(elementId);
          setPageId(Number(newPageId || 1));
          break;
        }
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [chapterId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (pageId) {
        navigation(`${links.lore.to}/${chapterId}/${pageId}`, {
          replace: true,
        });
      }
    }, 700);

    return () => clearTimeout(handler);
  }, [chapterId, navigation, pageId]);

  if (isMobileView) {
    return <MobileFooter />;
  }

  return <DesktopFooter onZoom={onZoom} isZoomIn={isZoomIn} pageId={pageId} />;
};

export default Footer;
