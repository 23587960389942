import React from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material";
import Logo from "../atoms/Logo";
import MobileDrawer from "./MobileDrawer";
import Hamburger from "../atoms/Hamburger";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 320,
  height: 64,
  backgroundImage: "url('/images/nav/nav-mobile.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center center",
  position: "fixed",
  top: "2vh",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 99,
  padding: "30px 30px 38px 10px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const MobileNav = ({ handleDrawerOpen, handleDrawerClose, drawerOpen }) => {
  return (
    <Wrapper>
      <MobileDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} />

      <Logo width={84} />

      <Hamburger handleDrawerOpen={handleDrawerOpen} />
    </Wrapper>
  );
};

export default MobileNav;
