import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Stats from "./Stats";
import InfoTabs from "./InfoTabs";
import Image from "components/atoms/Image";
import { CONTAINER_WIDTH_MOBILE } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";

export const BREAKPOINT = 1000;

const Flag = styled(Stack)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: 1200,
  margin: "0 auto",
}));

const Content = styled(Stack)(({ theme }) => ({
  background: "linear-gradient(180deg, #671313 0%, #801F1A 100%)",
  width: `${(1120 / 1200) * 100}%`,
  margin: "0 auto",
  borderRadius: "0 0 60px 60px",
  paddingTop: 22,
  paddingLeft: `${(48 / 1200) * 100}%`,
  paddingRight: `${(48 / 1200) * 100}%`,
  paddingBottom: 48,

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    width: `${(305 / 340) * 100}%`,
    borderRadius: "0 0 40px 40px",
    paddingTop: 15,
  },
}));

const PlayCollectOwn = () => {
  const { mobile } = useBreakpoints();
  const flagImage = mobile
    ? `/images/templates/home/play-collect-own/flag-header-mobile.png`
    : `/images/templates/home/play-collect-own/flag-header.png`;
  return (
    <Flag>
      <Image src={flagImage} alt="Flag" width="100%" />
      <Content>
        <InfoTabs />
        <Stats />
      </Content>
    </Flag>
  );
};

export default PlayCollectOwn;
