import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeTheme } from "./theme/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Routes from "./routes";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  const theme = createTheme(makeTheme());

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ParallaxProvider>
          <Routes />
        </ParallaxProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
