import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH_MOBILE, CONTAINER_WIDTH } from "config";
import Experiences from "./Experiences";
import Constellation from "./Constellation";
import Container from "components/atoms/Container";
import { useBreakpoints } from "hooks/useBreakpoints";
import Globe from "../Globe";
import Title from "../Title";
import data from "data/data";

const GradientBackground = styled(Stack)(({ theme }) => ({
  background:
    "linear-gradient(180deg, #382532 0%, #2C2238 11.88%, #211F3D 22.15%, #07194B 100%)",

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 80,
  },
}));

const Stars = styled(Stack)(({ theme }) => ({
  backgroundImage: "url('/images/templates/home/infinite-experiences/stars.png')",
  backgroundSize: "cover",
  backgroundPosition: "center top",
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  paddingTop: 200,
  paddingBottom: 50,
  position: "relative",
  zIndex: 3,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    marginBottom: -80,
    gap: 60,
  },

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    paddingTop: 130,
    paddingBottom: 0,
  },
}));

const InfiniteExperiences = () => {
  const target = useRef(null);
  const { desktopDown } = useBreakpoints();

  return (
    <GradientBackground>
      <Stars>
        <Container style={{ position: "relative" }} innerRef={target}>
          {!desktopDown && <Constellation target={target} />}

          <Wrapper>
            <Title>{data.experiences.title}</Title>
            <Experiences target={target} />
          </Wrapper>
        </Container>
      </Stars>
      <Globe />
    </GradientBackground>
  );
};

export default InfiniteExperiences;
