import { useMediaQuery } from "@mui/material";
import { CONTAINER_WIDTH, CONTAINER_WIDTH_MOBILE } from "config";

export const useBreakpoints = (breakpoint) => {
  const desktop = useMediaQuery(`(min-width:${CONTAINER_WIDTH}px)`);
  const desktopDown = useMediaQuery(`(max-width:${CONTAINER_WIDTH}px)`);
  const mobile = useMediaQuery(`(max-width:${CONTAINER_WIDTH_MOBILE}px)`);
  const customBreakpoint = useMediaQuery(`(max-width:${breakpoint}px)`);

  return { desktop, desktopDown, mobile, customBreakpoint };
};
