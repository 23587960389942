import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PlayForFree from "./PlayForFree";
import LatestNews from "./LatestNews";
import WhatIsAxieInfinity from "./WhatIsAxieInfinity";
import WoodDivider from "components/atoms/WoodDivider";
import { CONTAINER_WIDTH } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: 80,
  backgroundImage: `url('/images/templates/home/accents/wood-background-light.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  paddingBottom: 80,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    gap: 70,
  },
}));

const Section1 = () => {
  return (
    <>
      <WoodDivider />
      <Wrapper>
        <PlayForFree />
        <LatestNews />
        <WhatIsAxieInfinity />
      </Wrapper>
      <WoodDivider variant="dark" />
    </>
  );
};

export default Section1;
