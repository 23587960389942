export const dialog = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 16,
        padding: 24,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {},
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {},
    },
  },
};
