import React from "react";
import { Stack } from "@mui/material";
import AxieShowcase from "./AxieShowcase";
import Header from "./Header";

const Marketplace = () => {
  return (
    <Stack gap={3}>
      <Header />
      <AxieShowcase />
    </Stack>
  );
};

export default Marketplace;
