import React, { useState } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Container from "components/atoms/Container";
import Image from "components/atoms/Image";
import { CONTAINER_WIDTH } from "config";
import Video from "./Video";
import Text from "./Text";

const RelativeWrapper = styled(Stack)(({ theme }) => ({
  position: "relative",

  ".note1": {
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translateY(-20%)",
    zIndex: 8,
    maxWidth: 146,

    [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
      width: "20%",
      left: 8,
    },
  },

  ".note2": {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translateY(-20%)",
    zIndex: 8,
    maxWidth: 110,

    [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
      width: "15%",
      right: 27,
    },
  },
}));

const GridWrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "840fr 440fr",
  gap: 16,
  paddingTop: theme.spacing(9),

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    flexDirection: "column-reverse",
    display: "flex",
    gap: 80,
  },
}));

const PlayForFree = () => {
  const [videoOpen, setVideoOpen] = useState(false);

  return (
    <Container>
      <RelativeWrapper>
        <Image
          src="/images/templates/home/accents/note-1.png"
          alt="Decorative note"
          className="note1"
        />
        <Image
          src="/images/templates/home/accents/note-2.png"
          alt="Decorative note"
          className="note2"
        />

        <GridWrapper>
          <Video videoOpen={videoOpen} setVideoOpen={setVideoOpen} />
          <Text setVideoOpen={setVideoOpen} />
        </GridWrapper>
      </RelativeWrapper>
    </Container>
  );
};

export default PlayForFree;
