import * as types from "../axieActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import { requestEthExchangeRate, requestMarketplaceStats } from "services/api";
import * as actions from "../axieActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchMarketplaceStatsRequest() {
  try {
    yield put(actions.fetchMarketplaceStatsStarted());
    const stats = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      requestMarketplaceStats
    );
    const ethExchangeRate = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      requestEthExchangeRate
    );
    const data = {
      totalAxies: parseInt(stats?.tokensStats?.axie?.totalSupply ?? "0"),
      totalOwners: parseInt(stats?.tokensStats?.axie?.holders ?? "0"),
      totalSales:
        parseFloat(stats?.overallMarketStats?.mkpVolume?.allTime ?? "0") *
        ethExchangeRate?.exchangeRate?.eth?.usd,
    };
    yield put(actions.fetchMarketplaceStatsSucceeded(data));
  } catch (e) {
    yield put(actions.fetchMarketplaceStatsFailed());
  }
}

export default function* marketplaceStatsSaga() {
  yield takeEvery(
    types.AXIE_FETCH_MARKETPLACE_STATS,
    makeFetchMarketplaceStatsRequest
  );
}
