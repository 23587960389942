import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "../Image";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  width: (width / 114) * 114,
  height: (width / 114) * 120,
  backgroundImage: 'url("/images/buttons/square-wooden-button.png")',
  backgroundSize: "cover",
  position: "relative",
  transition: "0.2s transform ease",

  "&:hover": {
    transform: "scale(1.1)",
    cursor: "pointer",
  },

  "& .icon": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: (width / 114) * 78,
    height: (width / 114) * 78,
  },
}));

const CloseButton = ({ onClick, width = 114 }) => {
  return (
    <Wrapper onClick={onClick} width={width} className="closeButton">
      <Image
        alt="Close icon"
        src="/images/icons/close-icon.png"
        className="icon"
      />
    </Wrapper>
  );
};

export default CloseButton;
