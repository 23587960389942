import { Stack } from "@mui/material";
import React from "react";

const TabPanel = (props) => {
  const { children, value, index, id, ...other } = props;
  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-${index}`}
      aria-labelledby={`${id}-${index}`}
      sx={{ width: "100%" }}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Stack>
  );
};

export default TabPanel;
