import React, { useState } from "react";
import Container from "components/atoms/Container";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import TitledSection from "components/molecules/TitledSection";
import data from "data/data";
import Button from "components/atoms/Button";
import Video from "./Video";
import { CONTAINER_WIDTH } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";

const Wrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  bottom: 0,
  transform: "translateX(-50%) translateY(30%)",
  zIndex: 4,
  width: "100%",

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    position: "static",
    transform: "none",
    background: "linear-gradient(to bottom, #382632, #372532)",
  },
}));

const Layout = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "660fr 600fr",
  gap: theme.spacing(4),
  width: "100%",
  position: "relative",
  zIndex: 3,

  "> .titledSection": {
    paddingTop: "29%",
  },

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    marginTop: -100,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    gap: 0,

    "> .titledSection": {
      paddingTop: 28,
    },
  },
}));

const Content = () => {
  const [videoOpen, setVideoOpen] = useState(false);
  const { mobile } = useBreakpoints();

  return (
    <Wrapper>
      <Container>
        <Layout>
          <Video videoOpen={videoOpen} setVideoOpen={setVideoOpen} />

          <TitledSection
            title={data.esports.title}
            description={data.esports.description}
            style={{
              paddingTop: mobile ? 0 : "29%",
              "& .header": { textShadow: "0 0 10px #533439b5" },
            }}
            titleFontSizes={{ desktop: 32, mobile: 20 }}
            buttons={
              <Button
                variant="dark"
                size={mobile ? 148 : 178}
                onClick={() => setVideoOpen(true)}
              >
                Watch finals
              </Button>
            }
          />
        </Layout>
      </Container>
    </Wrapper>
  );
};

export default Content;
