import React from "react";
import { Box } from "@mui/material";
import Image from "components/atoms/Image";
import Button from "components/atoms/Button";
import { useParallax } from "react-scroll-parallax";
import { useBreakpoints } from "hooks/useBreakpoints";
import { BREAKPOINT_FIXED_LAYOUT } from "..";
import CustomLink from "components/atoms/CustomLink";
import { PLAY_NOW_URL } from "config";

const Axies = ({ target }) => {
  const { customBreakpoint: breakFixed } = useBreakpoints(
    BREAKPOINT_FIXED_LAYOUT
  );

  const axies = useParallax({
    speed: breakFixed ? 0 : -20,
    targetElement: target.current,
  });

  return (
    <Box
      ref={axies.ref}
      sx={{
        width: "100%",
        position: "absolute",
        left: 0,
        bottom: 0,
        paddingTop: "20%",

        "& .logo": {
          position: "absolute",
          left: "49%",
          transform: "translateX(-50%)",
          pointerEvents: "auto",
          width: 160,
          bottom: "58.1%",

          [`@media(min-width:${BREAKPOINT_FIXED_LAYOUT}px)`]: {
            width: 240,
          },

          "@media(min-width:1920px)": {
            width: 280,
          },
        },

        ".button": {
          position: "absolute",
          bottom: "40.5%",
          left: "48.3%",
          transform: "translateX(-50%)",
          pointerEvents: "auto",

          "&:hover": {
            transform: "translateX(-50%) scale(1.05)",
          },

          [`@media(min-width:${BREAKPOINT_FIXED_LAYOUT}px)`]: {
            bottom: "41%",
          },

          "@media(min-width:1920px)": {
            bottom: "43%",
          },
        },
      }}
    >
      <Image src="/images/templates/home/jumbotron/background-2.png" width="100%" />

      <CustomLink to="/">
        <Image
          src="/images/branding/axie-infinity-logo.png"
          alt="Axie Infinity Logo"
          className="logo"
        />
      </CustomLink>
      <Button
        href={PLAY_NOW_URL}
        variant="play-now"
        size={breakFixed ? "small" : "large"}
      >
        Play Now
      </Button>
    </Box>
  );
};

export default Axies;
