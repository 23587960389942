import React from "react";
import { styled, useMediaQuery } from "@mui/material";
import Links from "../molecules/Links";
import { Stack } from "@mui/material";
import Button from "components/atoms/Button";
import { PLAY_NOW_URL } from "config";
import { MEDIUM_BREAKPOINT, SMALL_BREAKPOINT } from "..";
import Hamburger from "../atoms/Hamburger";
import Logo from "../atoms/Logo";

const Wrapper = styled(Stack)(({ theme }) => ({
  transition: "0.3s all ease",
  backgroundImage: "url('/images/nav/nav.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "fixed",
  top: "2vh",
  left: "50%",
  transform: "translateX(-50%)",
  width: 1280,
  height: 80,
  zIndex: 99,
  padding: "30px 30px 38px 30px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "100%",
}));

const LogoAndLinks = styled(Stack)(({ theme, type }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(3),
}));

const DesktopNav = ({ handleDrawerOpen }) => {
  const mdDown = useMediaQuery(`(max-width:${MEDIUM_BREAKPOINT}px)`);
  const smDown = useMediaQuery(`(max-width:${SMALL_BREAKPOINT}px)`);

  return (
    <Wrapper>
      {smDown ? (
        <>
          <Logo to="/" />
          <Hamburger handleDrawerOpen={handleDrawerOpen} />
        </>
      ) : (
        <>
          <LogoAndLinks>
            <Logo to="/" />
            <Links />
          </LogoAndLinks>
          <Button
            variant="plain"
            size={mdDown ? "small" : "large"}
            href={PLAY_NOW_URL}
          >
            Play for Free
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default DesktopNav;
