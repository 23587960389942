import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import H2 from "components/atoms/Typography/Header";
import Container from "components/atoms/Container";
import data from "data/data";
import Post from "./Post";
import { CONTAINER_WIDTH, CONTAINER_WIDTH_MOBILE } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";

const Content = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    "& .title": {
      textAlign: "center",
    },
  },
}));

const NewsItems = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(2),
  flexWrap: "wrap",

  ".post": {
    position: "relative",
    maxWidth: 412,
    width: "calc((100% - 16px) / 3)",
    aspectRatio: `412 / 440`,
    minWidth: 320,
  },

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    padding: "0 20px",

    ".post": {
      width: "calc((100% - 16px) / 2)",
    },
  },

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    ".post": {
      width: "100%",
    },
  },
}));

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const LatestNews = () => {
  const [news,setNews] = useState([]);
  const { mobile } = useBreakpoints();

  useEffect(() => {
    fetch("https://ronin.axiedao.org/api/getLatestNews/").then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result?.success && result?.data?.length){
            result?.data?.map((obj) => {
              const date = new Date(obj.date);
              obj.date=`${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
            })
            setNews(result?.data);
          }
        })
      }
    })
  },[])

  return (
    <Container>
      <Content>
        <H2
          className="title"
          color="text.lighter"
          fontSize={mobile ? 32 : 40}
          lineHeight={mobile ? 40 / 32 : 48 / 40}
        >
          Latest News
        </H2>
        <NewsItems>
          {news.map((post, i) => (
            <Post key={i} index={i} post={post} />
          ))}
        </NewsItems>
      </Content>
    </Container>
  );
};

export default LatestNews;
