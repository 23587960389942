import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH_MOBILE } from "config";

export const Wrapper = styled(Stack)(() => ({
  position: "relative",
  width: 385,
  aspectRatio: `385 / 432`,
  marginBottom: 100,
  maxWidth: "100%",

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    marginBottom: 120,
  },
}));

export const Note = styled(Stack)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url('/images/templates/home/jumbotron/paper.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  paddingBottom: `${(432 / 385) * 100}%`,
  zIndex: 3,
  alignItems: "center",

  "& .axieImage": {
    position: "absolute",
    bottom: "-23%",
    zIndex: 4,
    transform: "translateY(-50%)",
    textAlign: "center",
    width: `${(400 / 385) * 100}%`,
    aspectRatio: "400 / 300",
  },

  [`@media (max-width: 425px)`]: {
    "& .axieImage": {
      width: "95%",
    },
  },
}));

export const Content = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: theme.spacing(2.5),
  padding: "11% 8%",

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    padding: "11% 4%",
  },
}));

export const TitleWrapper = styled(Stack)(({ theme }) => ({
  minHeight: 80,
  justifyContent: "center",

  [`@media (max-width: 425px)`]: {
    minHeight: "auto",
  },
}));
