import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH_MOBILE } from "config";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ theme, style }) => ({
  flexDirection: "row",
  gap: "20px",
  ...style,

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    gap: "12px",
  },

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE - 1}px)`]: {
    flexDirection: "column",
  },
}));

const StackedButtons = ({ children, style }) => {
  return <Wrapper style={style}>{children}</Wrapper>;
};

export default StackedButtons;
