import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "components/atoms/Image";
import data from "data/data";
import FullScreenVideo from "components/atoms/FullScreenVideo";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: 660,
  position: "relative",
  left: "4%",

  "&:hover": {
    cursor: "pointer",

    ".playButton": {
      transform: "translateX(-50%) translateY(-50%) scale(1.1)",
    },
  },

  ".frame": {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 2,
  },

  ".thumbnail": {
    position: "absolute",
    top: "7%",
    left: "4%",
    width: "84%",
    zIndex: 1,
  },

  ".playButton": {
    transition: "0.2s transform ease",
    position: "absolute",
    top: "50%",
    left: "46%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 3,
    width: "15.3%",
    filter: "drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.5))",
  },
}));

const Video = ({ videoOpen, setVideoOpen }) => {
  return (
    <>
      <Wrapper onClick={() => setVideoOpen(true)}>
        <Image
          src="/images/templates/home/esports/thumbnail.jpg"
          alt="Esports final at AxieCon"
          className="thumbnail"
        />
        <Image
          src="/images/templates/home/esports/frame.png"
          alt="Television"
          className="frame"
        />
        <Image
          src="/images/templates/home/accents/play.png"
          alt="Play button"
          className="playButton"
        />
      </Wrapper>

      <FullScreenVideo
        open={videoOpen}
        setOpen={setVideoOpen}
        url={data.esports.videoUrl}
      />
    </>
  );
};

export default Video;
