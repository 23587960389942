import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "components/atoms/Image";
import FullScreenVideo from "components/atoms/FullScreenVideo";
import data from "data/data";
import { CONTAINER_WIDTH } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  maxWidth: 846,
  width: "100%",
  margin: "0 auto",
}));

const TV = styled(Stack)(({ theme }) => ({
  position: "relative",
  width: "100%",

  ".tv": {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
  },

  "& .note4": {
    position: "absolute",
    bottom: 0,
    transform: "translateY(32%) translateX(86%)",
    right: 0,
    zIndex: 8,
    maxWidth: 192,

    [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
      width: "26%",
      right: "initial",
      left: "0",
      transform: "translateY(44%) translateX(16%)",
    },
  },
}));

const Thumbnail = styled(Stack)(({ theme }) => ({
  position: "absolute",
  top: "5.5%",
  left: "8.5%",
  width: "83%",

  "&:hover": {
    cursor: "pointer",

    ".playButton": {
      transform: "translateX(-50%) translateY(-50%) scale(1.1)",
    },
  },

  ".playButton": {
    transition: "0.2s transform ease",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 3,
    width: "14.2%",
    filter: "drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.5))",
  },

  ".thumbnail": {
    zIndex: 2,
    borderRadius: "40px",
    overflow: "hidden",
  },
}));

const Video = ({ videoOpen, setVideoOpen }) => {
  return (
    <>
      <Wrapper>
        <TV onClick={() => setVideoOpen(true)}>
          <Image
            src="/images/templates/home/accents/note-4.png"
            alt="Puffynote"
            className="note4"
          />
          <Image
            src="/images/templates/home/play-for-free/television.png"
            alt="Television"
            className="tv"
          />
          <Thumbnail>
            <Image
              src="/images/templates/home/play-for-free/thumbnail.jpg"
              alt="An axie battle"
              className="thumbnail"
            />
            <Image
              src="/images/templates/home/accents/play.png"
              alt="Play button"
              className="playButton"
            />
          </Thumbnail>
        </TV>
      </Wrapper>
      <FullScreenVideo
        open={videoOpen}
        setOpen={setVideoOpen}
        url={data.playForFree.videoUrl}
      />
    </>
  );
};

export default Video;
