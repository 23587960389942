export const keyboard = (keyCode) => {
  const key = {};
  key.code = keyCode;
  key.isDown = false;
  key.isUp = true;
  key.press = undefined;
  key.release = undefined;

  key.downHandler = (event) => {
    if (event.key === key.code) {
      if (key.isUp && key.press) {
        key.press();
      }
      key.isDown = true;
      key.isUp = false;
    }
  };

  key.upHandler = (event) => {
    if (event.key === key.code) {
      if (key.isDown && key.release) {
        key.release();
      }
      key.isDown = false;
      key.isUp = true;
    }
  };

  return key;
};

export const contain = (sprite, container) => {
  let collision = undefined;
  let halfWidth = sprite.width / 2;
  //Left
  if (sprite.x - halfWidth / 2 < container.x) {
    sprite.x = container.x + halfWidth;
    collision = "left";
  }

  //Right
  if (sprite.position.x + halfWidth * -1 > container.width) {
    sprite.x = container.width - halfWidth * -1;
    collision = "right";
  }

  return collision;
};
