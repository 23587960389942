import React, { useState } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "components/atoms/Image";
import { makeMarketplaceURLFromID } from "helpers/axie";
import CustomLink from "components/atoms/CustomLink";
import PuffLoader from "components/organisms/AnimatedAxie/Playground/PuffLoader";
import ConditionalWrapper from "components/atoms/ConditionalWrapper";
import Axie from "./Axie";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 255,
  minHeight: 233,
  position: "relative",
  justifyContent: "flex-end",
  gridArea: "axie",
  margin: "10px 20px",

  "&:hover": {
    "& .axieImage": {
      transform: "translateX(-48%) translateY(-16%) scale(1.3)",
    },
  },
}));

const AxieStand = ({ data = {} }) => {
  const [hovering, setHovering] = useState(false);

  const axie = (data.results || [])[0];
  const id = axie?.id;
  const price = axie?.order?.currentPriceUsd;

  return (
    <ConditionalWrapper
      condition={id != null}
      wrapper={(children) => (
        <CustomLink href={makeMarketplaceURLFromID(id)} className="axieStand">
          {children}
        </CustomLink>
      )}
    >
      <Wrapper
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className="axieStandWrapper"
      >
        {id == null || price == null ? (
          <PuffLoader
            style={{
              position: "relative",
              left: "50%",
              transform: "translateX(-50%) translateY(-20%)",
            }}
          />
        ) : (
          <Axie id={id} price={price} hovering={hovering} />
        )}

        <Image src="/images/templates/home/marketplace/stand.png" alt="Wooden stand" />
      </Wrapper>
    </ConditionalWrapper>
  );
};

export default AxieStand;
