import React from "react";
import { Typography } from "@mui/material";

const Text = ({
  children,
  textAlign = "left",
  variant = "body2",
  fontSize,
  fontFamily,
  color,
  fontWeight = "400",
  lineHeight,
  className,
  style,
}) => {
  return (
    <Typography
      variant={variant}
      textAlign={textAlign}
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      className={className}
      sx={{ lineHeight, ...style }}
    >
      {children}
    </Typography>
  );
};

export default Text;
