import fonts from "theme/fonts";

export const buttons = {
  MuiButton: {
    variants: [],
    styleOverrides: {
      root: {
        whiteSpace: "nowrap",
        fontFamily: fonts.header,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
};
