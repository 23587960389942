import React from "react";
import SubPage from "components/templates/SubPage";

const TermsOfUse = () => {
  return (
    <SubPage title="Terms of Use" wrapperClass="termsOfUse">
      <ol className="decimal">
        <li>
          <h2>INTRODUCTION</h2>
          <p>
            These Axie Infinity Terms of Use, together with any supplemental
            terms and policies incorporated herein, constitute a legally binding
            agreement (collectively, the &quot;<strong>Terms</strong>&quot; or &quot;
            <strong>Terms of Use</strong>&quot;) made between you, whether personally
            or on behalf of an entity (&quot;<strong>you</strong>&quot;) and Axie Infinity
            Limited (&quot;<strong>Axie Infinity</strong>&quot;, &quot;<strong>we</strong>&quot;, &quot;
            <strong>us</strong>&quot;, or &quot;<strong>our</strong>&quot;) concerning your
            access to and use of the www.Axieinfinity.com website (the &quot;
            <strong>Site</strong>&quot;) and one or more Axie Infinity applications
            (the &quot;<strong>App</strong>&quot;), as well as any other media form, media
            channel, mobile website or mobile application made available by or
            on behalf of Axie Infinity and related, linked, or otherwise
            connected thereto (collectively, with the Site and the App and any
            Smart Contracts or other software or services enabled thereby, the &quot;
            <strong>Services</strong>&quot;). The Services include any distributed
            application running on one or more blockchain networks, which may
            include the Ethereum Network and Ronin Network (the &quot;
            <strong>Blockchains</strong>&quot;), using smart contracts (each, a &quot;
            <strong>Smart Contract</strong>&quot;) that interact with or enable the
            use of a user&apos;s digital creatures (&quot;<strong>Axies</strong>&quot;). The
            Services also enable users to use or interact with Smart Contracts
            involving other digital assets, such as tokens, plots of land, and
            resources (collectively with the Axies, &quot;
            <strong>Digital Assets</strong>&quot;). These Digital Assets can be
            visualized via the Services and other third-party services.
          </p>
          <p>
            The Services are not intended for distribution to any person or
            entity in any jurisdiction or country where such distribution or use
            would be contrary to law or regulation, or which would subject us to
            any registration requirement or other regulation within such
            jurisdiction or country. Accordingly, you are solely responsible for
            compliance with all applicable laws when you access and/or use the
            Services.
          </p>
          <p>
            The Services are intended for users who are at least 18 years old.
            People under the age of 18 are not permitted to use or register for
            the Services.
          </p>
          <p>
            THE SERVICES INCLUDE A MARKETPLACE WHICH ALLOWS USERS TO OFFER TO
            SELL AND SELL DIGITAL ASSETS TO, AND OFFER TO BUY AND BUY DIGITAL
            ASSETS FROM, OTHER USERS. ALL DIGITAL ASSETS AVAILABLE THROUGH THE
            MARKETPLACE ARE FURNISHED BY OR ON BEHALF OF SELLERS IN CONNECTION
            WITH SUCH SALES. YOU AGREE THAT, EXCEPT WHERE AXIE INFINITY IS THE
            SELLER IN SUCH SALE, WE SHALL NOT BE A PARTY TO OR HAVE ANY
            RESPONSIBILITY OR LIABILITY FOR, ARISING OUT OF, RELATING TO,
            ASSOCIATED WITH OR RESULTING FROM ANY DISPUTES BETWEEN YOU AND ANY
            BUYER OR SELLER OF DIGITAL ASSETS IN RESPECT OF THE USE, MISUSE,
            PROVISION OR FAILURE TO PROVIDE ANY DIGITAL ASSETS. YOU BEAR FULL
            RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND
            AUTHENTICITY OF DIGITAL ASSETS, INCLUDING WITHOUT LIMITATION ANY
            AXIES, THAT YOU PURCHASE. WE ARE NOT A DIGITAL WALLET PROVIDER,
            BROKER, DEALER, FINANCIAL INSTITUTION, EXCHANGE, PAYMENT PROCESSOR,
            MONEY SERVICES BUSINESS, OR CREDITOR.
          </p>
          <p>
            <strong>
              PLEASE BE AWARE THAT SECTION 13 CONTAINS PROVISIONS GOVERNING HOW
              TO RESOLVE DISPUTES BETWEEN YOU AND US. AMONG OTHER THINGS,
              SECTION 13 INCLUDES AN AGREEMENT TO ARBITRATE, WHICH REQUIRES,
              WITH LIMITED EXCEPTIONS, THAT ALL DISPUTES BETWEEN YOU AND US
              SHALL BE RESOLVED BY BINDING AND FINAL INDIVIDUAL ARBITRATION.
              PLEASE CAREFULLY READ SECTION 13.
            </strong>
          </p>
          <p>
            <strong>
              PLEASE BE AWARE THAT SECTION 4 OF THESE TERMS OF USE CONTAINS YOUR
              OPT-IN CONSENT TO RECEIVE COMMUNICATIONS FROM US, INCLUDING VIA
              E-MAIL, TEXT MESSAGE, CALLS AND PUSH NOTIFICATION.
            </strong>
          </p>
          <p>
            WE ONLY MAKE THE SERVICES AVAILABLE TO YOU ON THE TERMS SET FORTH IN
            THESE TERMS OF USE. BY USING THE SERVICES OR ANY OTHER PRODUCT OR
            SERVICE MADE AVAILABLE BY AXIE INFINITY, YOU AGREE THAT YOU HAVE
            READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF USE.{" "}
            <strong>
              IF YOU DO NOT AGREE AND/OR ACCEPT THESE TERMS OF USE, THEN YOU ARE
              EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
              DISCONTINUE USE IMMEDIATELY
            </strong>
            .
          </p>
          <p>
            Please refer to our{" "}
            <a href="https://axieinfinity.com/privacy-policy">Privacy Policy</a>{" "}
            for information about how we collect, use and share personal
            information about you. By submitting data through the Services, you
            expressly consent to the collection, use and disclosure of your
            personal data in accordance with the Privacy Policy.
          </p>
          <p>
            We reserve the right to change or modify these Terms of Use at any
            time and in our sole discretion. If we make changes to these Terms
            of Use, we will provide notice of such changes, for example by
            sending an email notification (if we have a valid email for you),
            providing notice through the Services or updating the &quot;Last Updated&quot;
            date at the beginning of these Terms of Use. By continuing to access
            or use any Services at any point after such update, you confirm your
            acceptance of the revised Terms of Use and all of the terms
            incorporated therein by reference. We encourage you to review these
            Terms of Use frequently to ensure that you understand the terms and
            conditions that apply when you access or use the Services. If you do
            not agree to the revised Terms of Use, you may not access or use the
            Services.
          </p>
        </li>
        <li>
          <h2>OUR SERVICES</h2>
          <ol className="lowerAlpha">
            <li>
              <strong>General.</strong> The Services include an online platform
              through which users can view, offer to purchase and purchase
              Digital Assets or other Items (as defined below) that can be used
              and deployed in one or more games made available through the
              Services. Users of the Services may be able to mint certain
              Digital Assets, including Axies, as non-fungible tokens (&quot;
              <strong>NFTs</strong>&quot;) on the Blockchains. In order to access
              certain Services, including to purchase any Digital Assets, a user
              must connect a compatible digital wallet to the Services.
            </li>
            <li>
              <strong>Minting Digital Assets.</strong> By minting a Digital
              Asset as an NFT, you agree to comply with any terms, including
              licenses or payment rights that are embedded within or otherwise
              included with any such Digital Asset. Axie Infinity does not
              guarantee that Digital Assets will be transferable to or have any
              utility in connection with any other platform.
            </li>
            <li>
              <strong>Digital Asset Terms.</strong> There may be limits on or
              other terms applicable to the purchase, sale or use of Digital
              Assets or Items comprising Axie Infinity&apos;s Content (defined
              below), including, without limitation, any fee payable in
              connection with any subsequent sale of a Digital Asset or Item,
              regardless of whether such sale takes place on or through the
              Service (each such subsequent sale, a &quot;
              <strong>Secondary Sale</strong>,&quot; and such fee, a &quot;
              <strong>Secondary Sale Fee</strong>&quot;), and Axie Infinity will
              display such terms at point of sale or otherwise within the
              Services (the limits and other terms, collectively, the &quot;
              <strong>Digital Asset Terms</strong>&quot;).
            </li>

            <li>
              <strong>Transacting in Digital Assets.</strong> All transactions
              regarding Digital Assets are managed and confirmed on the
              Blockchain. You understand that your Blockchain public address may
              be made publicly visible whenever you engage in a transaction.
              Axie Infinity is not and shall not be a party to any transaction
              or dispute between any initial minter of a Digital Asset (other
              than any Digital Asset that we mint) and any subsequent owner of
              such Digital Asset (unless we are the owner of such Digital
              Asset), whether arising from any rights granted in that Digital
              Asset or otherwise, unless otherwise set forth in connection with
              such Digital Asset.
            </li>
            <li>
              <strong>Game Rules.</strong> You may be able to use the Services
              to take certain actions in connection with one or more Digital
              Assets or Items that results in a swap, trade, or other exchange
              of that Digital Asset or Item for a modified, new, or otherwise
              different Digital Asset or Item (each, a &quot;<strong>Play</strong>&quot;).
              A Digital Asset or Item that you receive in connection with or as
              a result of a Play may have different traits than the Digital
              Asset or Item that you used in such Play, such as traits that
              represent different elements of rarity or &quot;level&quot; status. Axie
              Infinity does not represent that any Digital Asset or Item that
              you receive from a Play will have similar or equivalent attributes
              or value to the Digital Asset or Item that you originally used in
              such Play. Axie Infinity shall have no liability to you in
              connection with (i) any Digital Asset or Item that you use in a
              Play, or (ii) any Digital Asset or Item that you receive in
              connection with a Play. In some instances, a Play may result in a
              Digital Asset or Item being sent to a null address, erased, or
              otherwise rendered unusable (&quot;<strong>Burned</strong>&quot;), in each
              case in accordance with the then-current functionality of the
              Services and any in-game supplemental terms. Any action that
              results in such Digital Asset or Item being irrevocably modified
              or Burned is permanent and irreversible. You acknowledge and agree
              that Axie Infinity shall not be liable to you in connection with
              any Digital Asset or Item that is irrevocably modified and/or
              Burned as a result of or in connection with your use of the
              Services.
            </li>
            <li>
              <strong>Marketplace.</strong> Certain Digital Assets can be
              bought, sold, and traded with other users on the Axie Infinity
              marketplace (&quot;
              <strong>Marketplace</strong>&quot;). Pricing and payment terms on the
              Marketplace, including specific details relating to the offering
              of any Digital Assets, will be set forth at the point of sale.
              Axie Infinity may, in its sole discretion, limit the use of the
              Marketplace to the listing and sale of certain Digital Assets or
              collections of Digital Assets. Digital Assets listed on the
              Marketplace are being offered by the individuals seeking to sell
              such Digital Assets (&quot;<strong>Sellers</strong>&quot;), and the actual
              contracts for sale are between the Sellers and the individuals
              seeking to buy such Digital Assets (&quot;<strong>Buyers</strong>&quot;). A
              user who has purchased a Digital Asset on the Marketplace, whether
              in its initial sale or via Secondary Sale, may be able to resell
              that Digital Asset on the Marketplace and/or on a third-party
              marketplace, subject to these Terms of Use and any supplemental
              terms and conditions. Except where explicitly set forth at point
              of sale, Axie Infinity is not the Seller of any Digital Asset
              listed on the Marketplace and will not provide or deliver any
              Digital Assets. While we may, in our discretion, help facilitate
              resolution of Disputes through various programs, we have no
              control over the ability of Sellers to sell or receive
              compensation for Digital Assets, the ability of Buyers to purchase
              or pay for Digital Assets, or that a Buyer and Seller will
              actually complete a transaction on the Marketplace. While Axie
              Infinity may provide pricing and guidance for Digital Assets sold
              by Sellers on our Marketplace, such information is solely
              informational. We do not have control over the quality, safety,
              timing, legality, failure to provide, or any aspect whatsoever of
              any Digital Assets advertised or sold by Sellers, or of the
              integrity, responsibility, or any actions of any users. Axie
              Infinity makes no representations about any transactions or
              interactions, whether public, private, online or offline,
              including with regard to the suitability, reliability, timeliness
              or accuracy thereof.
            </li>
            <li>
              <strong>Selling on the Marketplace.</strong> PLEASE CAREFULLY READ
              THIS IMPORTANT INFORMATION ABOUT PROCEDURES FOR SELLING ON THE
              MARKETPLACE BEFORE USING THE MARKETPLACE. To help prevent the
              funding of terrorism and money laundering activities, Axie
              Infinity may ask you to provide additional information or copies
              of identifying documents that will allow us and/or our dApp
              Providers (defined below) to identify you. We reserve the right to
              require you to provide such additional information and documents.
              Failure to provide such information upon request, as well as any
              other violation of these Terms of Use, may result in your removal
              from the Services and/or a termination of your ability to sell,
              purchase or use Digital Assets via the Marketplace. By using the
              Marketplace to offer for sale or sell a Digital Asset, you hereby
              appoint Axie Infinity as your limited agent for the sole purpose
              of receiving, holding and settling payments due and owed to you
              arising out of the sale of Digital Assets through the Services.
              Subject to these Terms of Use, Axie Infinity will process such
              payments and settle payments that are actually received by Axie
              Infinity, less any amounts owed to Axie Infinity, including taxes,
              fees and other obligations as applicable. You agree that a payment
              received by Axie Infinity on your behalf satisfies the payor&apos;s
              obligation to make payment to you, regardless of whether Axie
              Infinity actually settles such payment to you. If Axie Infinity
              does not settle any such payments to you as described in these
              Terms of Use, you will have recourse only against Axie Infinity
              and not the payor, as payment is deemed made by the payor to you
              upon constructive or actual receipt by Axie Infinity.
            </li>
            <li>
              <strong>Axie Mavis Store.</strong> Certain non-Digital Asset
              in-game items (collectively &quot;<strong>Items</strong>&quot;) can be
              purchased via the Axie Mavis Store (&quot;
              <strong>Axie Mavis Store</strong>&quot;).
            </li>
            <ol className="lowerRoman">
              <li>
                <strong>Pricing and Payment Terms.</strong> Pricing and payment
                terms on the Axie Mavis Store, including specific details
                relating to the offering of any Items, will be set forth at the
                point of sale. Acceptable forms of payment for these Items may
                be changed at any time in our sole discretion, and we reserve
                the right to decline orders and refuse payment at any time and
                for any reason. We may use a third-party payment processor in
                order to provide you with the Axie Mavis Store and its related
                services, and you understand and agree that by using or
                accessing our Axie Mavis Store, you also acknowledge and agree
                that you have read, understood and agree to any and all of the
                third-party payment processor&apos;s terms and conditions. ALL
                CHARGES INCURRED IN CONNECTION WITH THE AXIE MAVIS STORE AND ALL
                FUNDS PLACED IN YOUR ACCOUNT BALANCE, AS APPLICABLE, ARE PAYABLE
                IN ADVANCE AND ARE NOT REFUNDABLE IN WHOLE OR IN PART,
                REGARDLESS OF THE PAYMENT METHOD, EXCEPT AS EXPRESSLY SET FORTH
                IN THESE TERMS OR AS REQUIRED BY APPLICABLE LAW.
              </li>
              <li>
                <strong>Item Availability.</strong> Axie Infinity may, in its
                sole discretion, limit the use of the Axie Mavis Store to the
                listing and sale of certain Items only, and the availability of
                such Items is not guaranteed. The mere appearance of an Item on
                the Axie Mavis Store does not guarantee or indicate that the
                Item is available.
              </li>

              <li>
                <strong>Offer and Acceptance of Item Sales.</strong> Your order
                through the Axie Mavis Store is an offer to Axie Infinity to
                agree on the delivery of the ordered Items in exchange for the
                listed price. When you place an order on the Axie Mavis Store,
                we will send you a notification (e.g. in the form of a popup
                message) confirming receipt of your order and containing the
                details of your order (the &quot;<strong>Order Confirmation</strong>
                &quot;). The Order Confirmation is acknowledgement that we have
                received your order but is not an acceptance of your offer to
                enter into an agreement. If we accept your offer, we will
                conclude the transaction for an Item ordered by you, when we
                transfer or otherwise make available the Item to you and send
                you another notification indicating that we have transferred the
                Item or otherwise make available the Item to you. You hereby
                consent to receiving any and all sales invoices electronically.
              </li>
            </ol>
            <li>
              <strong>
                DISCLAIMERS WITH RESPECT TO THE SERVICES. ANY INFORMATION
                PROVIDED BY OR ON BEHALF OF AXIE INFINITY ON ANY PLATFORM OR
                THROUGH THE SERVICES IS FOR INFORMATIONAL AND ENTERTAINMENT
                PURPOSES ONLY AND SHOULD NOT BE CONSTRUED AS, NOR IS IT INTENDED
                AS, AN OFFER TO SELL, OR THE SOLICITATION OF AN OFFER TO BUY,
                ANY INTEREST IN ANY SECURITY, ENTITY, INVESTMENT VEHICLE OR
                CONTRACT, INCLUDING, BUT NOT LIMITED TO, WITH RESPECT TO DIGITAL
                ASSETS.
              </strong>
            </li>
            <li>
              <strong>Updates.</strong> You understand that the Services are
              evolving. As a result, we may require you to accept updates to
              continue to use any portion of the Services. You acknowledge and
              agree that we may update the Services with or without notifying
              you. You may need to update third-party software from time to time
              in order to use the Services. Any future release, update or other
              addition to the Services shall be subject to these Terms of Use.
              Axie Infinity, its suppliers and service providers reserve all
              rights not granted in these Terms of Use. Any unauthorized use of
              any Services terminates the licenses granted by us pursuant to
              these Terms of Use.
            </li>
          </ol>
        </li>
        <li>
          <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
          <ol className="lowerAlpha">
            <li>
              <strong>Ownership.</strong> Unless otherwise indicated in writing
              by us, the Services and all content and other materials contained
              therein, including, without limitation, all characters (including
              but not limited to character names, catch phrases, and character
              likeness), dialog, story lines and lore, designs (including but
              not limited to structural or landscape designs), text, graphics
              (whether still or moving), animations, pictures, information,
              data, software, sound files (including but not limited to musical
              compositions and recordings), audiovisual effects, other files,
              and the selection and arrangement thereof (collectively, the &quot;
              <strong>Content</strong>&quot;), are the proprietary property of Axie
              Infinity or our affiliates, licensors or users, as applicable. The
              Axie Infinity logo and any Axie Infinity product, service name,
              logo, slogan, trademark and service mark contained therein (the &quot;
              <strong>Marks</strong>&quot;) are owned, controlled by us or licensed
              to us, and are protected by copyright and trademark laws and
              various other intellectual property rights and unfair competition
              laws of the Cayman Islands, foreign jurisdictions and
              international conventions. Except as expressly provided in these
              Terms of Use, no part of the Services and no Content or Marks may
              be copied, reproduced, aggregated, republished, uploaded, posted,
              publicly displayed, encoded, translated, transmitted, distributed,
              sold, licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
              Reference to any products, services, processes or other
              information by name, trademark, manufacturer, supplier or
              otherwise does not constitute or imply endorsement, sponsorship,
              or recommendation by Axie Infinity.
            </li>
            <li>
              <strong>Access to Services.</strong> Subject to your ongoing
              compliance with these Terms of Use and eligibility to use the
              Services, you are hereby granted a limited, revocable,
              nonexclusive, non-transferable, non-assignable, non-sublicensable,
              &quot;as is&quot; right to access and use the Services and Content for your
              own personal, non-commercial use; provided, however, that (except
              as and solely to the extent expressly set forth herein) such right
              does not include any right to (i) sell, resell, or use
              commercially the Services or Content, (ii) distribute, publicly
              perform, or publicly display any Content, (iii) modify or
              otherwise make any derivative uses of the Services or Content, or
              any portion thereof, (iv) use any data mining, robots, or similar
              data gathering or extraction methods, (v) download (other than
              page caching) any portion of the Services or Content, except as
              expressly permitted by us, and (vi) use the Services or Content
              other than for their intended purposes. We reserve all rights not
              expressly granted hereunder in and to the Services and all
              components thereof, including without limitation the Site, the
              App, the Content, and the Marks.
            </li>
            <li>
              <strong>Digital Asset and Item License.</strong> With respect to
              any Digital Asset sold by Axie Infinity or allowed for sale
              through the Axie Marketplace or any Item sold by Axie Infinity or
              allowed for sale through the Axie Mavis Store, the terms of this
              Section 3(c) and only the terms of this Section 3(c) shall apply.
              Axie Infinity hereby grants you a non-exclusive, limited,
              revocable, royalty-free, worldwide right and license (with no
              right to sub-license) to use the artwork, if any, contained within
              such Digital Asset or Item in order to create certain derivative
              works thereof in digital or tangible form (the &quot;
              <strong>Fan Art</strong>&quot;), solely for personal, non-commercial
              use. Fan Art (i) shall not include any Marks (including without
              limitation any Axie Infinity logo, Axie Infinity Shard logo, SLP
              logo, etc.); (ii) must be clearly and prominently designated in
              all manners and mediums as &quot;Axie Infinity Fan Art&quot;; and (iii) if
              displayed in any online format, must link (1) to{" "}
              <a href="https://www.axieinfinity.com/">
                https://www.axieinfinity.com/
              </a>{" "}
              AND (2) directly to the precedent Digital Asset or Item. If you
              are the then-current owner of any Digital Asset or Item, you are
              granted a limited license, subject to these Terms of Use, to
              create Fan Art from such Digital Asset or Item and to use such Fan
              Art for commercial purposes; provided that your revenue from Fan
              Art derived from such Digital Asset or Item must not exceed ten
              thousand U.S. Dollars ($10,000), except subject to the execution
              of a separate commercial license agreement between you and Axie
              Infinity or otherwise indicated by us. Each Digital Asset or Item
              may embody or include certain artwork owned or created by Axie
              Infinity (the &quot;<strong>Artwork</strong>&quot;). You acknowledge and
              agree that Axie Infinity or our respective licensors owns all
              legal right, title and interest in and to the Artwork, and all
              intellectual property rights therein. Digital Assets or Items may
              also embody or include one or more Marks (collectively with the
              Artwork embodied by such Digital Asset or Item, the &quot;
              <strong>Visual IP</strong>&quot;). The Visual IP is neither stored nor
              embedded in the Digital Assets or Item but is accessible through
              the Digital Assets or Items. ALTHOUGH EACH DIGITAL ASSET OR ITEM
              ITSELF IS OWNED BY ITS THEN-CURRENT OWNER, THE VISUAL IP EMBODIED
              THEREIN IS LICENSED, PURSUANT TO THESE DIGITAL ASSET AND ITEM
              TERMS, AND NOT TRANSFERRED OR SOLD, TO SUCH OWNER. The rights that
              you have in and to the Visual IP are limited to those expressly
              set forth in these Digital Asset and Item Terms. Axie Infinity and
              its licensors reserve all rights in and to the Visual IP not
              expressly granted to you herein. AXIE INFINITY PROVIDES EACH
              DIGITAL ASSET AND ITEM, LICENSES THE VISUAL IP TO THE THEN-CURRENT
              OWNER OF THE DIGITAL ASSET OR ITEM, AND PROVIDES THE SERVICES ON
              AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. AXIE INFINITY EXPRESSLY
              DISCLAIMS ANY WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES
              OR CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR
              FITNESS FOR A PARTICULAR PURPOSE. THE LICENSE TO CREATE AND
              DISPLAY THE VISUAL IP EMBODIED BY A DIGITAL ASSET OR ITEM IS
              AUTOMATICALLY AND ALWAYS TRANSFERRED WITH THE RESPECTIVE DIGITAL
              ASSET OR ITEM AS PROVIDED IN THESE DIGITAL ASSET AND ITEM TERMS.
              OTHERWISE, THE LICENSE TO DISPLAY THE VISUAL IP EMBODIED BY EACH
              DIGITAL ASSET OR ITEM IS NON-TRANSFERABLE. You acknowledge that
              the license granted to you in these Digital Asset and Item Terms
              applies only to the extent that you lawfully purchased or acquired
              a Digital Asset or Item and, with respect to any such Digital
              Asset or Item, for so long as you own such Digital Asset or Item.
              If at any time you sell, trade, donate, give away, transfer, or
              otherwise dispose of your Digital Asset or Item for any reason,
              the license granted in these Digital Asset and Item Terms will
              immediately terminate with respect to such Digital Asset or Item
              without the requirement of notice, and you will have no further
              rights in or to the Visual IP embodied by such Digital Asset or
              Item. The license granted in these Digital Asset and Item Terms
              will also automatically terminate, with all rights returning to
              Axie Infinity, if: (a) you breach these Digital Asset and Item
              Terms or the Terms of Use, which shall include any unauthorized
              transfer or acquisition of a Digital Asset or Item; or (b) you
              engage in any unlawful business practice related to Digital Assets
              or Items. In the event that you did not lawfully purchase or
              acquire a Digital Asset or Item, or upon any termination of the
              license granted in these Digital Asset and Item Terms, Axie
              Infinity may disable your access to the Visual IP and/or deny you
              access to the Services and/or any further benefits, services, or
              goods associated with the Digital Asset or Item.
            </li>
            <li>
              <ol className="lowerRoman">
                <li>
                  <strong>
                    LIMITED LICENSES FOR OWNERS OF MYSTIC AND ORIGIN AXIES.
                  </strong>{" "}
                  Subject to your lawful purchase or acquisition of a Axie
                  non-fungible token that has the &quot;Mystic&quot; or &quot;Origin&quot; trait as
                  embedded in the token&apos;s code (&quot;<strong>Rare Axies</strong>&quot;)
                  and your continued compliance with these Digital Asset and
                  Item Terms and Terms of Use, Axie Infinity grants you a
                  worldwide, non- exclusive, revocable, royalty-free license,
                  with no right to sub-license, to use the Rare Axies for
                  commercial purposes, provided that your commercial use of the
                  Visual IP of the Rare Axies must, (a) not relate to or involve
                  in any way the use or application of Rare Axies in game
                  applications other than those related to Axie Infinity; (b)
                  not create or result in potential harm or risk to the Axie
                  Infinity ecosystem and community as determined by us in our
                  sole discretion; (c) not use or involve the use of any Marks
                  other than the Visual IP that is solely related to your Rare
                  Axie.
                </li>
                <li>
                  <strong>RESTRICTIONS.</strong> You agree that you will not,
                  nor will you cause or permit any third party to, do or attempt
                  to do, any of the following without Axie Infinity&apos;s express
                  prior written consent in each case: (i) modify, distort, or
                  perform any other change to the Visual IP in any way,
                  including, without limitation, the shapes, designs, drawings,
                  attributes, or color schemes; (ii) use the Visual IP as a
                  brand or trademark or to advertise, market, or sell any
                  product or service (other than an authorized offering of a
                  Digital Asset or Item itself); (iii) use the Visual IP in
                  connection with images, videos, or other forms of media that
                  depict hatred, intolerance, violence, cruelty, or anything
                  else that could reasonably be found to constitute hate speech
                  or otherwise infringe upon the rights of others or promote
                  illegal activities; (iv) sell, distribute for commercial gain
                  (including, without limitation, giving away in the hopes of
                  eventual commercial gain), or otherwise commercialize
                  merchandise that includes, contains, or consists of the Visual
                  IP; (v) attempt to trademark, copyright, or otherwise acquire
                  additional intellectual property rights in or to the Visual
                  IP; (vi) use the Visual IP in connection with disparaging
                  statements about Axie Infinity and/or its affiliated
                  companies, or otherwise damage the goodwill, value, or
                  reputation of Axie Infinity, or represent or imply that your
                  exercise of the licenses granted hereunder is endorsed by Axie
                  Infinity and/or its affiliated companies; or (vii) otherwise
                  utilize the Visual IP for your or any third party&apos;s commercial
                  benefit (except as otherwise permitted herein and/or other
                  than an authorized offering of the Digital Asset or Item
                  itself). These restrictions will survive the expiration or
                  termination of licenses granted hereunder. Without limiting
                  the foregoing, the license granted by these Terms of Use does
                  not include: (a) the right to use the Visual IP in connection
                  with additional digital assets or items; or (b) the right to
                  create derivative works of the Visual IP. You may not use or
                  attempt to register any asset (including, without limitation,
                  any domain names, social media accounts, or related addresses)
                  that contains or incorporates any Visual IP, or any other
                  representation, name, or mark that may be confusingly similar
                  to any of Axie Infinity&apos;s intellectual property.
                </li>
                <li>
                  <strong>
                    TRANSFERRING YOUR DIGITAL ASSET OR ITEM. As applicable,
                  </strong>{" "}
                  you may transfer to a third party any Digital Asset or Item
                  that you lawfully own to a third party, subject to these Terms
                  of Use. If you transfer any Digital Asset or Item, you must
                  make the transferee aware of, and you must cause any such
                  transferee to agree in writing to comply with and be bound by,
                  these Digital Asset and Item Terms. You acknowledge and agree
                  that any transfer of a Digital Asset or Item, by you or by the
                  then-current owner, is subject to Axie Infinity&apos;s then-current
                  fee(s) as set forth in the Services.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>COMMUNICATIONS</h2>
          <p>
            By accessing or using the Services, you consent to receive
            communications from us by electronic means (e.g., via email,
            Discord, or by posting notices to the Services). These
            communications may include notices about your use of the Service
            (e.g., transactional information) and are part of your relationship
            with us. You agree that any notices, agreements, disclosures or
            other communications that we publish electronically will satisfy any
            legal communication requirements, including, but not limited to,
            that such communications be in writing. You should maintain copies
            of electronic communications from us by printing a paper copy or
            saving an electronic copy. We have no obligation to store for your
            later use or access any such electronic communications.
          </p>
        </li>

        <li>
          <h2>USER REGISTRATION</h2>
          <ol className="lowerAlpha">
            <li>
              <strong>Creating an Account.</strong> In order to access certain
              features of the Services, you may be required to create an account
              on the Services (&quot;<strong>Account</strong>&quot;). In creating an
              Account, you agree to (i) provide true, accurate, current, and
              complete information about yourself as prompted by the
              registration form, including contact information such as your
              email (the &quot;<strong>Registration Data</strong>&quot;);(ii) maintain and
              promptly update the Registration Data to keep it true, accurate,
              current, and complete; (iii) consent to us using your Registration
              Data in any manner permitted under applicable law, including
              sharing it with third parties; and (iv) consent to receive
              electronic communications from us (e.g., via email or other means)
              regarding notices about your account and other promotional and
              marketing communications from us or third parties with whom we
              have shared your contact information. You represent that you are
              (A) at least eighteen (18) years old; and (B) not a person barred
              from using the Services under the laws of your place of residence
              or any other applicable jurisdiction. You are responsible for all
              activities that occur under your Account. You are responsible for
              any use of any payment instrument (e.g., your digital wallet)
              initiated or enabled by or through your Account. You may not share
              your Account or password with anyone, and you agree to notify Axie
              Infinity immediately of any unauthorized use of your password or
              any other breach of security. If you provide any information
              (including without limitation Registration Data) that is untrue,
              inaccurate, not current, or incomplete, or Axie Infinity has
              reasonable grounds to suspect that any information you provide is
              untrue, inaccurate, not current, or incomplete, Axie Infinity has
              the right to suspend or terminate your Account and refuse any and
              all current or future use of the Services (or any portion
              thereof). You agree not to create an Account using a false
              identity or information, or on behalf of someone other than
              yourself, except to the extent expressly permitted by us in
              writing. Axie Infinity reserves the right to remove or reclaim any
              usernames at any time and for any reason, including but not
              limited to, claims by a third party that a username violates the
              third party&apos;s rights. Notwithstanding anything to the contrary
              herein, you acknowledge and agree that you shall have no ownership
              or other property interest in your Account, and you further
              acknowledge and agree that all rights in and to your Account are
              and shall forever be owned by and inure to the benefit of Axie
              Infinity.
            </li>

            <li>
              <strong>User Representations and Warranties.</strong> When you use
              the Services, you hereby represent and warrant, to and for the
              benefit of Axie Infinity, its affiliates, and their respective
              representatives, as follows:
              <ul className="disc">
                <li>
                  <strong>Authority</strong>. You have all requisite capacity,
                  power and authority to enter into, and perform your
                  obligations under these Terms.
                </li>
                <li>
                  <strong>Accuracy of Information.</strong> All information
                  provided by you to Axie Infinity and/or its third-party
                  designees, including Registration Data, is accurate and
                  complete. None of: (i) you; (ii) any of your affiliates; (iii)
                  any other person having a beneficial interest in you; or (iv)
                  any person for whom you are acting as agent or nominee in
                  connection with these Terms is: (A) a country, territory,
                  entity or individual included in any trade embargoes or
                  economic sanctions list (such as the United Nations Security
                  Council sanctions list or the Office of Foreign Assets Control
                  of the U.S. Department of the Treasury list as provided at
                  http://www.treas.gov/ofac), or a person or entity prohibited
                  under the OFAC programs, regardless of whether or not they
                  appear on the OFAC list, or a person or entity included in the
                  denied persons or entity list of the U.S. Department of
                  Commerce; or (B) a senior foreign political figure, or any
                  immediate family member or close associate of a senior foreign
                  political figure.
                </li>
                <li>
                  <strong>Independent Investigation and Non-Reliance.</strong>{" "}
                  You are sophisticated, experienced and knowledgeable regarding
                  blockchain technologies and digital assets. Additionally, you
                  have conducted an independent investigation of the Services
                  and the matters contemplated by these Terms, have formed your
                  own independent judgment regarding the benefits and risks of
                  and necessary and desirable practices regarding the foregoing,
                  and, in making the determination to use the Services, you have
                  relied solely on the results of such investigation and such
                  independent judgement. Without limiting the generality of the
                  foregoing, you understand, acknowledge and agree that the
                  legal requirements pertaining to blockchain technologies and
                  digital assets generally, including the Digital Assets, are
                  uncertain, and you have conducted an independent investigation
                  of such potentially applicable legal requirements and the
                  resulting risks and uncertainties, including the risk that one
                  or more governmental entities or other persons may assert that
                  any digital assets or cryptographic tokens (including the
                  Digital Assets) may constitute securities under applicable
                  legal requirements. You hereby irrevocably disclaim and
                  disavow reliance upon any statements or representations made
                  by or on behalf of, or information made available by, Axie
                  Infinity, in determining to enter into these Terms of Use or
                  use the Services.
                </li>
                <li>
                  <strong>Litigation.</strong> There is no legal proceeding
                  pending that relates to your activities relating to the
                  Services or other token- or digital asset- trading or
                  blockchain technology-related activities.
                </li>
                <li>
                  <strong>Compliance.</strong> You have not failed to comply
                  with, and have not violated, any applicable legal requirement
                  relating to any blockchain technologies or token trading
                  activities. No investigation or review by any governmental
                  entity is pending or, to your knowledge, has been threatened
                  against or with respect to you, nor does any government order
                  or action prohibit you or any of your representatives from
                  engaging in or continuing any conduct, activity or practice.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            You must provide all equipment and software necessary to connect to
            the Services, including but not limited to, a mobile device that is
            suitable to connect with and use the Services, in cases where the
            Services offer a mobile component. You are solely responsible for
            any fees, including Internet connection or mobile fees, that you
            incur when accessing the Services.
          </p>
        </li>

        <li>
          <h2>FEES AND PAYMENT</h2>
          <ol className="lowerAlpha">
            <li>
              All pricing and payment terms for Digital Assets or Items are as
              indicated at point of sale or otherwise on the Services, and any
              payment obligations you incur are binding at the time of purchase.
              You may not substitute any other currency, whether cryptocurrency
              or fiat currency, for the currency in which you have contracted to
              pay at the time of purchase. For clarity, no fluctuation in the
              value of any currency, whether cryptocurrency or otherwise, shall
              impact or excuse your obligations with respect to any purchase.
              Whether a particular cryptocurrency is accepted as a payment
              method by Axie Infinity is subject to change at any time in Axie
              Infinity&apos;s sole discretion.
            </li>
            <li>
              When you purchase a Digital Asset or Item, you agree that you have
              read, understand, and agree to be bound by any Digital Asset and
              Item Terms applicable to the sale of that Digital Asset or Item,
              including any Secondary Sale Fee (regardless of whether such
              Secondary Sale Fee is enforced or supported by the platform,
              blockchain or marketplace that facilitates a Secondary Sale). You
              further agree that you will bind any subsequent purchaser of the
              Digital Asset or Item to such Digital Asset and Item Terms.
            </li>
            <li>
              Payment processing and related services (e.g., digital wallet
              management, card acceptance, merchant settlement) for the Services
              will be performed by Axie Infinity&apos;s third-party service
              providers, including third-party dApp providers such as Metamask
              and Ronin Wallet (each, a &quot;
              <strong>Third-Party Service Provider</strong>&quot;). Your use of the
              Services and the payment processing and related services provided
              by a Third-Party Service Provider is subject to your agreement(s)
              with such Third-Party Service Provider for such Services and
              payment processing and related services, as may be modified by the
              Third-Party Service Provider from time to time (collectively, &quot;
              <strong>Third-Party Service Provider Agreement</strong>&quot;). As a
              condition of using the Third-Party Service Provider&apos;s payment
              processing and related services, you must provide accurate and
              complete information, and you authorize us to share this
              information with the Third-Party Service Provider and to charge
              your payment method for all amounts that may become due under this
              Agreement. Your use of the Third-Party Service Provider&apos;s payment
              processing and related services is conditioned upon your
              compliance with the Third- Party Service Provider Agreement, and
              if the Third-Party Service Provider Agreement is terminated by the
              Third-Party Service Provider, you may not be able to use the
              Services, or you may have your use of the Services suspended or
              terminated. We may change or add other payment processing services
              at any time upon notice to you, which may be subject to additional
              terms or conditions. We will have no control over the Third-Party
              Service Provider&apos;s payment processing and related services and
              cannot reverse or refund any transactions.
            </li>
            <li>
              Each Blockchain may require the payment of a transaction fee (a &quot;
              <strong>Gas Fee</strong>&quot;) for every transaction that occurs on
              such Blockchain, such Gas Fee to be based on the structure and
              composition of such Blockchain. This means that you may need to
              pay a Gas Fee for each transaction that occurs via the Services.
              Gas Fees may vary based on market conditions on the applicable
              Blockchain, and Axie Infinity shall have no liability to you in
              connection with the same.
            </li>
            <li>
              In addition to the Gas Fee, each time you use a Smart Contract to
              conduct a transaction via the Marketplace, the Mavis Store or
              other parts of the Services, you authorize us to collect a
              commission based on the total value of that transaction (each, a &quot;
              <strong>Commission</strong>&quot;). You acknowledge and agree that the
              Commission will be transferred directly to us through the
              Blockchain as a part of the applicable transaction.
            </li>
            <li>
              If Axie Infinity determines it has a legal obligation to collect
              any Sales Tax (including, without limitation, any taxes that may
              become payable as the result of your purchase or sale of any of
              your Digital Assets or Items) from you in connection with these
              Terms, Axie Infinity shall collect such Sales Tax. If any services
              or products, or payments for any services or products, under this
              Agreement are subject to any Sales Tax in any jurisdiction and you
              have not remitted the applicable Sales Tax to Axie Infinity, you
              will be responsible for the payment of such Sales Tax and any
              related penalties or interest to the relevant tax authority, and
              you will indemnify Axie Infinity for any liability or expense Axie
              Infinity may incur in connection with such Sales Taxes. Upon Axie
              Infinity&apos;s request, you will provide it with official receipts
              issued by the appropriate taxing authority, or other such evidence
              that you have paid all applicable taxes. For purposes of this
              section, &quot;<strong>Sales Tax</strong>&quot; means any sales or use tax
              and any other tax measured by sales proceeds that is the
              functional equivalent of a sales tax where the applicable taxing
              jurisdiction does not otherwise impose a sales or use tax.
            </li>
          </ol>
        </li>

        <li>
          <h2>USER CONTENT</h2>
          <ol className="lowerAlpha">
            <li>
              &quot;<strong>User Content</strong>&quot; means any information and content
              that a user submits to, or uses with, the Services (e.g., content
              in the user&apos;s profile or postings) or any of Axie Infinity&apos;s
              profiles on other properties (e.g., Discord). You are solely
              responsible for your User Content. You assume all risks associated
              with use of your User Content, including any reliance on its
              accuracy, completeness or usefulness by others, or any disclosure
              of your User Content that personally identifies you or any third
              party. You hereby represent and warrant that your User Content
              does not violate our Acceptable Use Policy set forth in Section
              10. You may not represent or imply to others that your User
              Content is in any way provided, sponsored or endorsed by Axie
              Infinity. Since you alone are responsible for your User Content,
              you may expose yourself to liability if, for example, your User
              Content violates the Acceptable Use Policy. Axie Infinity is not
              obligated to backup any User Content, and your User Content may be
              deleted at any time without prior notice. You are solely
              responsible for creating and maintaining your own backup copies of
              your User Content if you desire.
            </li>
            <li>
              You hereby grant (and you represent and warrant that you have the
              right to grant) to Axie Infinity an irrevocable, nonexclusive,
              royalty-free and fully paid, worldwide license to reproduce,
              distribute, publicly display and perform, prepare derivative works
              of, incorporate into other works, and otherwise use and exploit
              your User Content, and to grant sublicenses of the foregoing
              rights, to provide and improve the Services and our other products
              and services. You hereby irrevocably waive (and agree to cause to
              be waived) any claims and assertions of moral rights or
              attribution with respect to your User Content.
            </li>
            <li>
              We reserve the right (but have no obligation) to review, refuse
              and/or remove any User Content in our sole discretion, and to
              investigate and/or take appropriate action against you in our sole
              discretion if you violate the Acceptable Use Policy or any other
              provision of these Terms or otherwise create liability for us or
              any other person. Such action may include removing or modifying
              your User Content, terminating your access to the Service in
              accordance with Section 11, and/or reporting you to law
              enforcement authorities.
            </li>
            <li>
              You acknowledge and agree that submission of any questions,
              comments, suggestions, ideas, documents, proposals, feedback, or
              other information regarding the Services (&quot;
              <strong>Submissions</strong>&quot;) provided by you to us is at your
              own risk and that Axie Infinity has no obligations (including
              without limitation obligations of confidentiality) with respect to
              such Submissions. You hereby grant to Axie Infinity a fully paid,
              royalty- free, perpetual, irrevocable, worldwide, non-exclusive,
              and fully sublicensable right and license to use, reproduce,
              perform, display, distribute, adapt, modify, re-format, create
              derivative works of, and otherwise commercially or
              non-commercially exploit in any manner, any and all Submissions,
              and to sublicense the foregoing rights, in connection with the
              operation and maintenance of the Services and/or Axie Infinity&apos;s
              business. To the fullest extent permitted by applicable law, you
              hereby waive any moral rights to any such Submissions that would
              limit the foregoing license grant, and you hereby warrant that any
              such Submissions are original with you or that you have the right
              to submit such Submissions and grant the foregoing licenses.
            </li>
          </ol>
        </li>

        <li>
          <h2>INTERACTIONS WITH OTHER USERS</h2>
          <p>
            You are solely responsible for your interactions with other users
            and any other parties with whom you interact; provided, however,
            that we reserve the right, but have no obligation, to intercede in
            any disputes between users. The Services may contain User Content
            provided by other users. We are not responsible for and do not
            control User Content. We have no obligation to review or monitor,
            and do not approve, endorse or make any representations or
            warranties with respect to, User Content, including without
            limitation any User Content embodied by or otherwise made available
            through the Services. You use all User Content and interact with
            other users at your own risk. You agree that Axie Infinity will not
            be responsible for any liability incurred as the result of your
            interactions with other users. When interacting with other users you
            should exercise caution and common sense to protect your personal
            safety and property, just as you would when interacting offline
            with other persons whom you don&apos;t know. WE, OUR SUBSIDIARIES,
            AFFILIATES, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS AND LICENSORS
            (EACH, AN &quot;<strong>AXIE INFINITY PARTY</strong>&quot; AND COLLECTIVELY &quot;
            <strong>AXIE INFINITY PARTIES</strong>&quot;) ARE NOT RESPONSIBLE FOR THE
            CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICE. NO
            AXIE INFINITY PARTY WILL BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE
            ARISING IN CONNECTION WITH YOUR INTERACTIONS WITH OTHER USERS OF THE
            SERVICE OR YOUR USE OF OR YOUR USE OF OR INABILITY TO USE ANY
            DIGITAL ASSET PURCHASED FROM A SELLER.
          </p>
        </li>

        <li>
          <h2>THIRD-PARTY WEBSITES AND CONTENT</h2>
          <p>
            The Services may contain links to third-party websites (&quot;
            <strong>Third-Party Websites</strong>&quot;) as well as articles,
            photograph, text, graphics, pictures, designs, music, sound, video,
            information, applications, advertisements, software, and other
            content or items belonging to or originating from third parties (&quot;
            <strong>Third-Party Content</strong>&quot;). When you click on a link to
            a Third-Party Website or Third-Party Content, we will not warn you
            that you have left the Services and you become subject to the terms
            and conditions (including privacy policies) of another website or
            destination. Such Third-Party Websites and Third-Party Content are
            not under the control of Axie Infinity and are not investigated,
            monitored, or checked for accuracy, appropriateness, or completeness
            by us, and we are not responsible for any Third-Party Websites
            accessed through the Site and/or the App; any Third-Party Content
            posted on, available through, or installed from the Site and/or the
            App; or any advertisements placed on the Site and/or the App, any
            services provided on the Site and/or the App, or products sold
            through those advertisements; including the content, accuracy,
            offensiveness, opinions, reliability, privacy practices, or other
            policies of or contained in the Third-Party Websites or the
            Third-Party Content. Inclusion of, linking to, or permitting the use
            or installation of any Third-Party Websites or any Third-Party
            Content does not imply approval or endorsement thereof by us. If you
            decide to leave the Site and/or the App and access the Third-Party
            Websites or to use or install any Third-Party Content, you do so at
            your own risk and you should be aware of these Terms of Use no
            longer govern. You should review the applicable terms and policies,
            including privacy and data gathering practices, of any website to
            which you navigate from the Site and/or the App or relating to any
            applications you use or install from the Site and/or the App. Any
            purchase you make through Third-Party Websites will be through other
            websites and from other companies, and we take no responsibility
            whatsoever in relation to such purchases which are exclusively
            between you and the applicable third party. You agree and
            acknowledge that we do not endorse the products and services offered
            on Third-Party Websites; and you hereby waive any claims against
            Axie Infinity Parties arising from or related to any Third-Party
            Content or Third-Party Websites. If you are an advertiser, you
            warrant and represent that you possess all rights and authority to
            place advertisements on the Site and/or the App, including, but not
            limited to, intellectual property rights, publicity rights, and
            contractual rights. We do not make any representations or warranties
            about any Third- Party Content you may encounter during your use of
            the Service, including any content associated with any Digital
            Assets (including any Axies) or Items displayed on the Service, and
            you bear sole responsibility for verifying the accuracy, legitimacy,
            authenticity, and legality of these Digital Assets or Items that you
            may purchase from third-party sellers. We cannot guarantee that any
            Digital Assets or Items that are visible on the Marketplace and/or
            Axie Mavis Store will always remain visible or available for
            purchase, sale, or transfer, nor can we guarantee that these Digital
            Assets or Items may hold or retain value—some may even become
            worthless. You are solely responsible for any content related to
            your Digital Assets or Items.
          </p>
        </li>

        <li>
          <h2>ACCEPTABLE USE POLICY</h2>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available.
          </p>
          <p>You shall not (and shall not permit any third party) to:</p>
          <ul className="disc">
            <li>
              use the Services for any purpose that is prohibited by this
              Agreement or is inconsistent with applicable law, rule, or
              regulation;
            </li>
            <li>
              violate, or encourage the violation of, the legal rights of others
              (for example, this may include allowing End Users to infringe or
              misappropriate the intellectual property rights of others in
              violation of the Digital Millennium Copyright Act);
            </li>
            <li>
              access the Services through automated or other non-human means,
              whether through a bot, script or otherwise, except subject to a
              separate written agreement with us for the following purposes:
              <ul className="circle">
                <li>Scholarship management.</li>
                <li>
                  Building public tools and bots that facilitate transparency
                  and analysis;
                </li>
                <li>
                  Building private, non-commercial tools which store data for
                  analytical purposes. Note that anyone abusing public APIs by
                  spamming requests may be banned from using such APIs in the
                  future;
                </li>
                <li>
                  Breeding, EXCEPT when there is a breeding event happening.
                  Axie Infinity will announce these events in advance on
                  Discord, Twitter, or similar communication channels.
                  Interacting with the breeding Smart Contracts directly during
                  breeding events is expressly prohibited;
                </li>
              </ul>
            </li>
            <li>
              Frame or use framing techniques to enclose any trademark, logo, or
              other Services (including images, text, page layout or form) of
              Axie Infinity;
            </li>
            <li>
              Use any metatags or other &quot;hidden text&quot; using Axie Infinity&apos;s name
              or trademarks;
            </li>
            <li>
              Modify, translate, adapt, merge, make derivative works of,
              disassemble, decompile, reverse compile, or reverse engineer any
              part of the Services, except to the extent the foregoing
              restrictions are expressly prohibited by applicable law;
            </li>
            <li>
              Remove or destroy any copyright notices or other proprietary
              markings contained on or in the Services;
            </li>
            <li>
              Take any action or make available any content on or through the
              Services that (i) is unlawful, threatening, abusive, harassing,
              defamatory, libelous, deceptive, fraudulent, invasive of another&apos;s
              privacy, tortious, obscene, offensive, or profane, including
              without limitation in an attempt to learn sensitive Account
              information such as user passwords; (ii) constitute unauthorized
              or unsolicited advertising, junk or bulk e-mail; (iii) involves
              commercial activities and/or sales, such as contests, sweepstakes,
              barter, advertising, or pyramid schemes without Axie Infinity&apos;s
              prior written consent; (iv) impersonates any person or entity,
              including any employee or representative of Axie Infinity, or uses
              the username of another user; or (v) interferes with or attempts
              to interfere with the proper functioning of the Services or uses
              the Services in any way not expressly permitted by this Agreement.
              Without limiting the foregoing, except as expressly stated herein,
              no part of the Services may be copied, reproduced, distributed,
              republished, downloaded, displayed, posted or transmitted in any
              form or by any means;
            </li>
            <li>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us;
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Services;
            </li>
            <li>
              Use the Services to create, sell, or buy Digital Assets or other
              items that give owners the right to participate in an initial coin
              offering (&quot;ICO&quot;) or any securities offering, or that are
              redeemable for securities, commodities, or other financial
              instruments;
            </li>
            <li>
              Circumvent, disable, bypass, or otherwise interfere with or
              attempt to interfere with any security-related features of the
              Services, including features that prevent or restrict the use or
              copying of any Content or enforce limitations on the use of the
              Services and/or the Content contained therein;
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct;
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools, except for the
              allowable purposes enumerated at the beginning of this Section 10;
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Site;
            </li>
            <li>
              Sell or otherwise transfer or attempt to transfer your Account;
            </li>
            <li>
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise not expressly
              permitted hereunder;
            </li>
            <li>
              Sell or resell our Service, or otherwise attempt to circumvent any
              of Axie Infinity&apos;s fee systems;
            </li>
            <li>
              Disparage, tarnish, or otherwise harm or attempt to harm Axie
              Infinity and/or any of the Services, as determined by us in our
              sole discretion;
            </li>
            <li>
              Use the Blockchains to carry out any illegal activities in
              connection with or in any way related to your access to and use of
              the Services, including but not limited to money laundering,
              terrorist financing, or deliberating engaging in activities
              designed to adversely affect the performance of the Blockchains or
              the Services;
            </li>
            <li>
              Engage in or knowingly facilitate any &quot;front-running,&quot; &quot;wash
              trading,&quot; &quot;pump and dump trading,&quot; &quot;ramping,&quot; &quot;cornering&quot; or
              fraudulent, deceptive or manipulative trading practices;
            </li>
            <li>
              Engage in any behavior or practice that have the intention or the
              effect of artificially causing any Digital Asset or Item to appear
              in a certain area of our Site or at the top of search results, or
              artificially increasing view counts, likes, or other metrics that
              we may use to sort items, collections, or search results;
            </li>
            <li>
              Use or attempt to use another user&apos;s Account without authorization
              from such user; or pose as another person or entity, or use a
              wallet to engage in a transaction on the Site or through the
              Service that is owned or controlled, in whole or in part, by any
              other person;
            </li>
            <li>
              Access the Service from a different Blockchain address if we have
              blocked any of your other Blockchain addresses or Account(s) from
              accessing the Service, unless you have our prior written consent;
            </li>
            <li>
              Engage in any practice that aims to manipulate the outcome of any
              Axie match, whether in the arena or tournaments or engage in any
              sort of match-fixing, win-trading, or colluding between
              competitors that goes against the spirit that all players should
              play to the best of their abilities, as determined by Axie
              Infinity in our sole discretion; or
            </li>
            <li>
              Use an Axie non-fungible token(s) or Item in any manner not
              then-currently permitted (a) by the rules of the applicable game;
              or (b) as communicated by Axie Infinity through official channels
              such as Discord, Twitter, Substack or other means of
              communication. Use of an Axie non-fungible token(s) or Item in any
              prohibited manner may result in the disqualification and
              ineligibility of Accounts for rewards, prizes, or other benefits
              that would otherwise be available to eligible Accounts.
            </li>
          </ul>
          <p>
            The rights granted to you in these Terms of Use are subject to your
            compliance with the restrictions set forth in this Section. Any
            future release, update or other addition to the Services shall be
            subject to the Terms of Use.
          </p>
          <p>
            By using the Services, you further represent and warrant that (1)
            you (a human being) will only use one Account to earn tokens in any
            24-hour period; (2) you, as an Axie owner, are responsible for the
            actions of any &quot;scholars&quot; (players using any Axies you own) that
            play on your behalf, and that their actions can have consequences
            for any connected Accounts that you own; (3) you will not manipulate
            the energy system, such as gifting Axies or Items to make use of
            more energy (this goes under multi-accounting).
          </p>
          <p>
            For the avoidance of doubt, within any 24-hour period, (i) you may
            use the Services on one Account across multiple devices; (ii)
            multiple people including you may log in to a single Account; and
            (iii) you may <em>own</em> multiple Accounts (however, you cannot{" "}
            <em>play</em> using multiple Accounts in any 24-hour period).
          </p>
          <p>
            Axie Infinity may restrict or refuse, at its sole discretion, the
            provision of the Services in certain countries or regions. The
            Services may not be intended for use in your jurisdiction. You are
            solely responsible for ensuring that your use of the Services
            complies with applicable law.
          </p>
        </li>

        <li>
          <h2>TERMINATION</h2>
          <p>
            If you want to terminate the Services, you may do so by (a)
            notifying us at any time; and (b) closing your Account; provided,
            however, that notwithstanding any such termination and for the
            avoidance of doubt, these Terms will continue to apply with respect
            to any Digital Asset or Item owned by you and all of your User
            Content. These Terms of Use remain in full force and effect while
            you use the Services.
          </p>
          <p>
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
            RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, TERMINATE THIS AGREEMENT AND/OR DENY ACCESS TO AND USE OF
            THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON
            FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
            BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
            THESE TERMS OF USE OR FOR VIOLATION OF ANY APPLICABLE LAW, RULE, OR
            REGULATION. WE MAY TERMINATE YOUR USE OF OR PARTICIPATION IN THE
            SERVICES OR DELETE YOUR ACCOUNT WITHOUT WARNING, IN OUR SOLE
            DISCRETION.
          </p>
          <p>
            If we terminate or suspend your Account for any reason, you are
            prohibited from registering and creating a new Account under your
            name, a fake or a borrowed name, or the name of any third party, or
            from otherwise using the Services, even if you are acting on behalf
            of the third party. In addition to terminating and suspending your
            Account, we reserve the right to take appropriate legal action,
            including without limitation pursuing civil, criminal, and
            injunctive redress, as set forth in more detail in Section 17,
            below. You understand that any termination of your right to access
            and use the Services may involve deletion of your User Content
            associated with your use of the Services from our live databases as
            well as limitation or inability to access Items that you may have
            purchased. Axie Infinity will not have any liability whatsoever to
            you for any termination of your rights under this Agreement,
            including for termination of your right to access and use the
            Services, deletion of your User Content, or limitation or inability
            to access Items in your Account. All sections intended by their
            nature to survive will survive the termination of this Agreement.
          </p>
        </li>

        <li>
          <h2>GOVERNING LAW</h2>
          <p>
            These Terms of Use and your use of the Services are governed by and
            constructed in accordance with the laws of the Cayman Islands, as
            applicable to agreements made and to be entirely performed in the
            Cayman Islands, without regard to its conflicts of law principles.
          </p>
        </li>

        <li>
          <h2>DISPUTE RESOLUTION</h2>
          <ol className="lowerAlpha">
            <li>
              <strong>Informal Negotiations.</strong> To expedite resolution and
              control the cost of any dispute, controversy, or claim related to
              these Terms of Use (each a &quot;<strong>Dispute</strong>&quot; and
              collectively, the &quot;<strong>Disputes</strong>&quot;) brought by either
              you or us (individually, a &quot;<strong>Party</strong>&quot; and
              collectively, the &quot;<strong>Parties</strong>&quot;), the Parties agree
              to first attempt to negotiate any Dispute (except those Disputes
              expressly provided below) informally and in good faith for at
              least the lesser of (i) the time necessary to resolve such Dispute
              or (ii) thirty (30) days before initiating the arbitration. Such
              informal negotiations commence upon written notice from one Party
              to the other Party.
            </li>
            <li>
              <strong>Binding Arbitration.</strong> If a Party is unable to
              resolve a Dispute through informal negotiations, the Disputes
              (except those Disputes expressly excluded below) will be finally
              and exclusively resolved by binding arbitration. YOU UNDERSTAND
              THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN
              COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced
              and conducted under the Commercial Arbitration Rules of the
              American Arbitration Association (&quot;AAA&quot;) and, where appropriate,
              the AAA&apos;s Supplementary Procedures for Consumer-Related Disputes
              (&quot;<strong>AAA Consumer Rules</strong>&quot;), both of which are
              available at the AAA website www.adr.org. Your arbitration fees
              and your share of arbitration compensation shall be governed by
              the AAA Consumer Rules and, where appropriate, limited by the AAA
              Consumer Rules. If such costs are determined by the arbitrator to
              be excessive, we will pay all the arbitration fees and expenses.
              Except where otherwise required by the applicable AAA rules or
              applicable law, the arbitration can take place in the Cayman
              Islands. Except as otherwise provided herein, the Parties may
              litigate in court to compel arbitration, stay proceedings pending
              arbitration, or to confirm, modify, vacate, or enter judgement on
              the award entered by the arbitrator. If for any reason, a Dispute
              proceeds in court rather than arbitration, the Dispute shall be
              commenced or prosecuted in the state and federal courts located in
              the Cayman Islands, sitting in Grand Cayman, and the Parties
              hereby consent to and waive all defenses of lack of personal
              jurisdiction, and forum non conveniens with respect to venue and
              jurisdiction in such state and federal courts. In no event shall
              any Dispute brought by either Party related in any way to Services
              be commenced more than one (1) year after the cause of the action
              arose. Each Party agrees that, if this provision is found to be
              unenforceable, then neither Party will elect to arbitrate any
              Dispute falling within that portion of this provision found to be
              unenforceable and such Dispute shall be decided by a court of
              competent jurisdiction within the courts listed or jurisdiction
              above, and the Parties agree to submit to the personal
              jurisdiction of that court.
            </li>
            <li>
              <strong>
                Exceptions to the Informal Negotiations and Arbitration.
              </strong>{" "}
              The Parties agree that the following Disputes are not subject to
              the above provision concerning informal negotiations and binding
              arbitration: (a) any Dispute seeking to enforce or protect, or
              concerning the validity of, the intellectual property rights of a
              Party, (b) any Dispute related to, or arising from, allegations of
              theft, piracy, invasion of privacy, or unauthorized use; and (c)
              any claim for injunctive relief. Each Party agrees that, if any
              portion of this provision is found to be unenforceable, then
              neither Party will elect to arbitrate any Dispute falling within
              that portion of this provision found to be unenforceable, and such
              Dispute shall be decided by a court of competent jurisdiction
              located in the Cayman Islands, sitting in Grand Cayman.
            </li>
          </ol>
        </li>

        <li>
          <h2>DISCLAIMERS</h2>
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
            THE SERVICES ARE AT YOUR SOLE RISK, AND THAT THE SERVICES ARE
            PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
            PURSUANT TO APPLICABLE LAW, AXIE INFINITY PARTIES MAKE NO EXPRESS
            WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE
            SERVICES AND ANY PART OF THEM (INCLUDING, WITHOUT LIMITATION, THE
            SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, AND RELIABILITY.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, AXIE INFINITY
            PARTIES DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO
            OR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS
            TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
            FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SERVICES WILL
            BE ACCURATE, (IV) THE SERVICES OR ANY CONTENT, SERVICES, OR FEATURES
            MADE AVAILABLE ON OR THROUGH THE SERVICES ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS, OR (V) THAT ANY DATA THAT YOU DISCLOSE
            WHEN YOU USE THE SERVICES WILL BE SECURE. SOME JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH
            CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO
            YOU.
          </p>
          <p>
            WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE SAFE, BUT
            WE DO NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT, OR ANY
            LINKED OR ASSOCIATED CONTENT WITH DIGITAL ASSETS OR OTHER ITEMS, OR
            ANY DIGITAL ASSETS OR ITEMS YOU INTERACT WITH WHEN USING OUR SERVICE
            OR OUR SERVICE PROVIDERS&apos; SERVERS ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA
            THAT YOU DISCLOSE ONLINE. NO ADVICE OR INFORMATION, WHETHER ORAL
            WRITTEN, OR OBTAINED FROM AXIE INFINITY PARTIES OR THROUGH THE
            SERVICES, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY
            MADE HEREIN. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
            INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE THAT WE
            HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY
            UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
          </p>
          <p>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR
            AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK, RONIN NETWORK,
            THE METAMASK ELECTRONIC WALLET, RONIN ELECTRONIC WALLET, OR ANY
            OTHER THIRD-PARTY SERVICES OR THIRD-PARTY BLOCKCHAINS, INCLUDING BUT
            NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER
            ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART
            CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS;
            (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR ACTIVITIES BY
            THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES,
            PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK.
          </p>
          <p>
            THE DIGITAL ASSETS, INCLUDING THE AXIES AND OTHER IN-GAME ITEMS, ARE
            INTANGIBLE ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
            MAINTAINED ON THE RONIN NETWORK. ALL DIGITAL ASSETS EXIST ONLY BY
            VIRTUE OF THE RECORD THEREOF ON THE DECENTRALIZED LEDGER WITHIN THE
            RONIN NETWORK. AXIE INFINITY HAS NO CONTROL OVER AND MAKES NO
            GUARANTEES OR PROMISES WITH RESPECT TO ANY DIGITAL ASSETS. AXIE
            INFINITY IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY
            OTHER FEATURES OF OR INHERENT TO BLOCKCHAIN TECHNOLOGY, INCLUDING
            BUT NOT LIMITED TO LATE REPORTS BY DEVELOPERS OR REPRESENTATIVES (OR
            NO REPORTS AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING ANY
            BLOCKCHAIN NETWORK OR INCLUDING FORKS, TECHNICAL NODE ISSUES, AND
            ANY OTHER ISSUES RESULTING IN LOSSES OF FUNDS.
          </p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT THE AXIE INFINITY PARTIES ARE NOT
            LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD ANY AXIE INFINITY PARTY
            LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF
            EXTERNAL SITES AND THIRD-PARTY SERVICE PROVIDERS, AND THAT THE RISK
            OF ECONOMIC DAMAGES FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
          </p>
          <p>
            YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
            INTERACTIONS WITH OTHER USERS OF THE SERVICES. YOU UNDERSTAND THAT
            AXIE INFINITY DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF
            USERS. AXIE INFINITY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
            THE CONDUCT OF USERS OF THE SERVICES OR THEIR COMPATIBILITY WITH ANY
            CURRENT OR FUTURE USERS OF THE SERVICES. YOU AGREE TO TAKE
            REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH
            OTHER USERS OF THE SERVICES, PARTICULARLY IF YOU DECIDE TO MEET
            OFFLINE OR IN PERSON. YOU ACKNOWLEDGE AND AGREE THAT AXIE INFINITY
            DOES NOT CONDUCT BACKGROUND CHECKS ON ANY USER. AXIE INFINITY MAKES
            NO WARRANTY THAT THE GOODS OR SERVICES PROVIDED BY THIRD PARTIES
            WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
            SECURE, OR ERROR-FREE BASIS.
          </p>
          <p>
            We are not responsible for any losses or harms sustained by you due
            to vulnerability or any kind of failure, abnormal behavior of
            software (e.g., smart contract), blockchains, or any other features
            of or inherent to the Digital Assets or Items. We are not
            responsible for casualties due to developers&apos; or representatives&apos;
            delay or failure to report any issues with any blockchain supporting
            Digital Assets or Items, including without limitation forks,
            technical node issues, or any other issues that result in losses of
            any sort.
          </p>
          <p>
            We do not transfer legal ownership of Digital Assets or Items from
            the Seller to the Buyer. Further, we cannot guarantee continuous or
            secure access to the Services and operation of the Services may be
            interfered with by numerous factors outside of our control.
            Accordingly, to the extent legally permitted, we exclude all implied
            warranties, terms and conditions.
          </p>
        </li>

        <li>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
            LAW, THE AXIE INFINITY PARTIES WILL NOT BE LIABLE TO YOU OR TO ANY
            THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
            EXEMPLARY DAMAGES WHICH YOU MAY INCUR, INCLUDING, WITHOUT
            LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
            INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA,
            COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER
            INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE TERMS OF USE
            OR ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF
            THE SERVICES, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
            RESULTING FROM: (a) THE USE OR INABILITY TO USE THE SERVICES; (b)
            THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES RESULTING
            FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED;
            OR MESSAGES RECEIVED FOR TRANSACTIONS ENTERED INTO THROUGH THE
            SERVICES; (c) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; (d) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
            ON THE SERVICES; OR (e) ANY OTHER MATTER RELATED TO THE SERVICES,
            WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), OR ANY OTHER LEGAL THEORY. THE FOREGOING LIMITATION OF
            LIABILITY SHALL NOT APPLY TO LIABILITY OF AN AXIE INFINITY PARTY FOR
            (i) DEATH OR PERSONAL INJURY CAUSED BY AN AXIE INFINITY PARTY&apos;S
            NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY AN AXIE INFINITY
            PARTY&apos;S FRAUD OR FRAUDULENT MISREPRESENTATION.
          </p>
          <p>
            YOU AGREE AND ACKNOWLEDGE THAT, TO THE FULLEST EXTENT PERMITTED BY
            LAW, THE AXIE INFINITY PARTIES WILL NOT BE LIABLE TO YOU FOR MORE
            THAN THE GREATER OF (A) THE TOTAL AMOUNT PAID TO AXIE INFINITY BY
            YOU IN THE TRANSACTION OR INCIDENT THAT IS THE SUBJECT OF THE CLAIM
            OR (B) ONE HUNDRED US DOLLARS (US $100.00).
          </p>
          <p>
            YOU AGREE AND ACKNOWLEDGE THAT WE HAVE MADE THE SERVICES AVAILABLE
            TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY
            DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH
            REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES
            AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT
            BE ABLE TO PROVIDE THE SERVICES TO YOU WITHOUT THESE LIMITATIONS.
          </p>
          <p>
            THE FOREGOING LIMITATIONS OF LIABILITY APPLY TO THE FULLEST EXTENT
            ALLOWED BY APPLICABLE LAW.
          </p>
        </li>

        <li>
          <h2>ASSUMPTION OF RISK</h2>
          <p>
            AXIE INFINITY&apos;S SERVICE RELIES ON EMERGING TECHNOLOGIES, SUCH AS THE
            RONIN NETWORK. SOME SERVICES ARE SUBJECT TO INCREASED RISK THROUGH
            YOUR POTENTIAL MISUSE OF THINGS SUCH AS PUBLIC/PRIVATE KEY
            CRYPTOGRAPHY. BY USING THE SERVICES, YOU EXPLICITLY ACKNOWLEDGE AND
            ACCEPT THESE HEIGHTENED RISKS.
          </p>
          <p>
            In this section, we set out a non-exhaustive list of some of the
            risks below. These risks, as well as additional risks arising from
            now or in the future, can be substantial and potentially
            devastating. You should therefore carefully consider whether using
            any of our Service, including the Site, is suitable for you
            considering your financial condition prior to your commencement of
            use. You must also seek professional advice regarding your financial
            condition prior to your commencement of the use of our Service.
            Finally, please ensure that you review the latest version of these
            Terms as they may change from time to time. You accept and
            acknowledge each of the following:
          </p>
          <ol className="lowerAlpha">
            <li>
              The prices of blockchain assets are extremely volatile and may
              significantly fluctuate at any given moment for any reason, even
              becoming worthless. Due to these price fluctuations, you may gain
              or lose value in your digital assets at any given moment, and the
              fluctuations in the price of other digital assets could materially
              and adversely affect the value of your Digital Assets, which may
              also be subject to significant price volatility. We cannot
              guarantee that any purchasers of Digital Assets will not lose
              money.
            </li>
            <li>
              Digital Assets and Items are not considered legal tender. They may
              not be backed by any physical assets and may not be backed,
              guaranteed, or supported by any government or centralized
              authority. Digital Assets and Items may not have intrinsic value,
              and their circulation may be limited and restricted.
            </li>
            <li>
              Digital Assets are generally considered a high-risk asset class
              and may or may not be considered securities under certain
              jurisdictions. You must therefore exercise prudent judgment when
              trading Digital Assets.
            </li>
            <li>
              The nature of Digital Assets may be very complex, and their terms,
              features, and/or risks may not be readily or fully understood due
              to the complex structure, novelty, and reliance on technological
              features.
            </li>

            <li>
              There is no assurance that the Site, Service, or any other related
              products or services will be orderly and stable. Any listed
              Digital Asset or Item&apos;s value may be subject to large swings and
              may even become worthless.
            </li>
            <li>
              In comparison to other types of assets, including fiat currencies
              and securities, any Digital Asset transactions may not be subject
              to a right of claim under any investor compensation fund
              established by any government or regulatory authority;
              furthermore, Digital Assets held by any dApp service provider or
              aggregator may not be protected deposits, and may not be protected
              by any deposit protection scheme in any relevant jurisdiction.
              Thus, Digital Assets may have a reduced level and type of
              protection compared to fiat currencies, securities, and other
              asset classes and types.
            </li>
            <li>
              During your use of the Service, you may be subject to various fees
              that may or may not arise directly from us, including fees that
              arise from any action that you take on the blockchain, whether a
              successful transaction occurs. These fees are final and
              irreversible. Prior to making any transactions, you must fully
              understand all commissions, fees, and costs for which you may be
              liable. If any of these fees are not clear to you, you must
              request clarification of what fees will be applicable in specific
              monetary terms prior to using the Service or entering any
              transactions.
            </li>
            <li>
              You are solely responsible for determining what, if any, taxes
              apply to your Digital Assets or related or similar transactions,
              and to withhold, collect, report and remit the correct amount of
              taxes to the appropriate tax authorities. Axie Infinity is not
              responsible for determining, withholding, collecting, reporting,
              or remitting any taxes that apply to your Digital Assets or any
              related or similar transactions on the Services.
            </li>
            <li>
              You understand that there are risks associated with purchasing
              items associated with content created by third parties through
              peer-to-peer transactions, including but not limited to, the risk
              of purchasing counterfeit items, mislabeled items, items that are
              vulnerable to metadata decay, bugs in items on smart contracts,
              and items that become non-transferable. You represent and warrant
              that you have done sufficient research prior to making any
              transactions or otherwise interacting with any Digital Assets.
            </li>
            <li>
              The Services do not store, send, or receive Digital Assets. This
              is because Digital Assets exist only by virtue of the ownership
              record maintained on the applicable Digital Asset&apos;s supporting
              blockchain. Any transfer of Digital Assets occurs only on the
              supporting blockchain, and not on the Services.
            </li>
            <li>
              The current regulatory regime(s) governing blockchain
              technologies, non-fungible tokens, digital assets, and other
              crypto-based items and collectibles is uncertain and may be
              constantly changing. New regulations or policies may materially
              and adversely affect the Service and the value and utility of
              Digital Assets.
            </li>
            <li>
              There are risks associated with using Internet and
              blockchain-based products, such as Digital Assets, Items, and
              cryptocurrencies, including, but not limited to, the risk of
              hardware, software and Internet connections, the risk of malicious
              software introduction, and the risk that third parties may obtain
              unauthorized access to information stored within your wallet. You
              accept and acknowledge that we will not be responsible for any
              communication failures, disruptions, errors, distortions or delays
              you may experience when using the Internet, Blockchains or any
              other blockchain, however caused.
            </li>
            <li>
              Malicious individuals or organizations may target you and attempt
              to steal any Digital Assets and/or Items you may hold or claim any
              Digital Asset and/or Item that you may have purchased. You are
              solely responsible for protecting yourself against such actions.
            </li>
            <li>
              There is no technology that is completely secure or safe. You
              should therefore exercise caution when using any technology.
            </li>
            <li>
              We do not have ownership or control of the smart contracts
              deployed by third parties, and we are not responsible and make no
              guarantees regarding their capabilities, operation, or
              functionality. We do not control the public Blockchains that you
              are interacting with, and we do not control certain smart
              contracts that are integral to your ability to complete
              transactions on these public blockchains. Additionally, blockchain
              transactions are irreversible, and we have no ability to reverse
              any transactions on the blockchain.
            </li>
            <li>
              We are not responsible for losses or casualties due to developers&apos;
              or representatives&apos; delay or failure to report any issues with any
              Blockchain supporting Digital Assets, including without
              limitation, forks, technical node issues, or any other issues that
              result in losses of any sort.
            </li>
            <li>
              The Service relies on third-party platforms and vendors. If we are
              unable to maintain a good relationship with these parties; if
              their respective terms and conditions or pricing changes; if we
              violate or cannot comply with these parties&apos; terms and conditions;
              or if any of these parties loses market share or falls out of
              favor or is unavailable for a prolonged period of time, access to
              and use of the Service may be degraded.
            </li>
            <li>
              A lack of use or public interest in the creation and development
              of distributed ecosystems (including without limitation the
              Blockchains) could negatively impact the development of the Axie
              Infinity ecosystem, and therefore the potential utility or value
              of Digital Assets or Items.
            </li>
            <li>
              The legal and regulatory regime governing blockchain technologies,
              cryptocurrencies, and tokens is uncertain, and new regulations or
              policies may materially adversely affect the development of the
              Axie Infinity ecosystem, and therefore the potential utility or
              value of Digital Assets.
            </li>
            <li>
              Upgrades to the Blockchains may have unintended, adverse effects
              on the Services, including any Digital Assets made available by
              Axie Infinity.
            </li>
            <li>
              At any point in time, one or more persons may directly or
              indirectly control significant portions of the total supply of any
              particular Digital Asset or Item. These persons are often referred
              to colloquially as &quot;whales&quot;. Whether acting individually or
              collectively, these whales may have significant impact, and may be
              able to influence or cause significant market events that may have
              a detrimental effect on price, value or functionality of Digital
              Assets or Items. Furthermore, these whales, or other network
              participants and users, may make decisions that are not in your
              best interest as an owner of Digital Assets or Items.
            </li>
            <li>
              We reserve the right to hide collections, contracts, and items
              that are affected by any issues for any reason at our sole
              discretion. These items you purchase may be inaccessible on the
              Site or through the Service. You understand and accept that the
              inability to view items on the Site or the inability to use the
              Service in conjunction with the purchase, sale, or transfer of
              items available on any blockchains does not give you any grounds
              for a claim against us.
            </li>
          </ol>
        </li>

        <li>
          <h2>INVESTIGATIONS</h2>
          <p>
            If we become aware of any possible violations by you of these Terms
            of Use, we reserve the right to investigate such violations. If, as
            a result of the investigation, we believe that criminal activity may
            have occurred, we reserve the right to refer the matter to, and to
            cooperate with, any and all applicable legal authorities. We are
            entitled, except to the extent prohibited by applicable law, to
            disclose any information or materials on or in the Services,
            including your Content, in Axie Infinity&apos;s possession in connection
            with your use of the Services, to (i) comply with applicable laws,
            legal process or governmental request; (ii) enforce these Terms of
            Use, (iii) respond to any claims that your Content violates the
            rights of third parties, (iv) respond to your requests for customer
            service, or (v) protect the rights, property or personal safety of
            Axie Infinity, its users, or the public, and all law enforcement or
            other government officials, as Axie Infinity in its sole discretion
            believes to be necessary or appropriate. By agreeing to these Terms
            of Use, you hereby provide your irrevocable consent to such
            monitoring. You acknowledge and agree that you have no expectation
            of privacy concerning your use of the Services, including without
            limitation text, voice, or video communications.
          </p>
        </li>

        <li>
          <h2>INDEMNIFICATION</h2>
          <p>
            To the fullest extent permitted by applicable law, you agree to
            indemnify, defend, and hold harmless Axie Infinity and the Axie
            Infinity Parties from and against all actual or alleged third party
            claims, damages, awards, judgments, losses, liabilities,
            obligations, penalties, interest, fees, expenses (including, without
            limitation, attorneys&apos; fees and expenses) and costs (including,
            without limitation, court costs, costs of settlement, and costs of
            or associated with pursuing indemnification and insurance), of every
            kind and nature whatsoever arising out of or related to these Terms
            of Use or your use of the Service or the Service, whether known or
            unknown, foreseen or unforeseen, matured or unmatured, or suspected
            or unsuspected, in law or equity, whether in tort, contract or
            otherwise (collectively, &quot;<strong>Claims</strong>&quot;), including, but
            not limited to, damages to property or personal injury, that are
            caused by, arise out of or are related to (a) your use or misuse of
            the Service, User Content, or any Digital Assets or Items, (b) any
            Submissions you provide, (c) your violation of these Terms of Use,
            and (d) your violation of the rights of any third party, including
            another user. You agree to promptly notify Axie Infinity of any
            third-party Claims and cooperate with the Axie Infinity Parties in
            defending such Claims. You further agree that the Axie Infinity
            Parties shall have control of the defense or settlement of any
            third-party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN
            LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A SEPARATE WRITTEN
            AGREEMENT BETWEEN YOU AND AXIE INFINITY.
          </p>
        </li>

        <li>
          <h2>RELEASE</h2>
          <p>
            You hereby release and forever discharge Axie Infinity and the Axie
            Infinity Parties from, and hereby waive and relinquish, each and
            every past, present and future dispute, claim, controversy, demand,
            right, obligation, liability, action and cause of action of every
            kind and nature (including personal injuries, death, and property
            damage), that has arisen or arises directly or indirectly out of, or
            that relates directly or indirectly to, the Services (including any
            interactions with, or act or omission of, other users). IN
            CONNECTION WITH THE FOREGOING YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
            SECTION 1542, OR ANY SIMILAR LAW OR RULE IN YOUR JURISDICTION, WHICH
            STATES IN SUBSTANCE: &quot;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
            WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
            EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
            WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
            HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.&quot;
          </p>
        </li>

        <li>
          <h2>MISCELLANEOUS</h2>
          <p>
            These Terms of Use and any policies or operating rules posted by us
            on the Services, or with respect to the Services, constitute the
            entire agreement and understanding between you and us. Our failure
            to exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. You
            may not assign these Terms of Use, or any of your rights or
            obligations hereunder, by operation of law or otherwise, without our
            prior written consent. We may assign any or all of our rights and
            obligations to others at any time. We shall not be responsible or
            liable for any loss, damage, delay, or failure to act caused by any
            cause beyond our reasonable control. If any provision or part of a
            provision of these Terms of Use is determined to be unlawful, void,
            and unenforceable, that provision or part of the provision will be
            construed in a manner to reflect, as nearly as possible, the
            original intention of the Parties, and the remaining portions will
            remain in full force and effect. There is no joint venture,
            partnership, employment or agency relationship created between you
            and us as a result of these Terms of Use or use of the Services. You
            agree that these Terms of Use will not be construed against us by
            virtue of having drafted them. The communications between you and
            Axie Infinity may take place via electronic means, whether you visit
            the Services or send Axie Infinity e-mails, or whether Axie Infinity
            posts notices on the Services or communicates with you via e-mail.
            For contractual purposes, you (a) consent to receive communications
            from Axie Infinity in an electronic form; and (b) agree that all
            terms and conditions, agreements, notices, disclosures, and other
            communications that Axie Infinity provides to you electronically
            satisfy any legal requirement that such communications would satisfy
            if it were to be in writing. The foregoing does not affect your
            statutory rights, which may include without limitation the
            Electronic Signatures in Global and National Commerce Act at 15
            U.S.C. §7001 et seq. (&quot;E-Sign&quot;) or any comparable law or rule of
            your jurisdiction. Except as otherwise set forth herein, these Terms
            do not create any third-party beneficiary rights in any individual
            or entity that is not a party to these Terms. All communications and
            notices made or given pursuant to these Terms must be made in the
            English language. If we provide a translation of the English
            language version of these Terms, the English language version of the
            Agreement will control if there is any conflict.
          </p>
        </li>
      </ol>
      <p>
        <strong>LAST UPDATED: January 5, 2024</strong>
      </p>
    </SubPage>
  );
};

export default TermsOfUse;
