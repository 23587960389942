import * as PIXI from "pixi.js";

import { keyboard } from "./helpers";
import { Figure } from "./Figure";
import { AXIE_DIRECTION } from "..";

export class CurrentFigure extends PIXI.Container {
  constructor(options) {
    super(options);
    this.direction = AXIE_DIRECTION.Left;
    this.currentAnimation = "action/idle/normal";
    this.vx = 0;
    this.axieScale = options.axieScale;
  }

  async changeSpine(loader, id) {
    this.removeChild(this.currentSpine);
    const prevSpine = this.currentSpine;

    const newFigure = await Figure.fromAxieId(loader, id, this.axieScale);
    if (!newFigure) throw new Error("Invalid Axie ID");
    this.currentSpine = newFigure;

    this.addChild(this.currentSpine);
    this.currentSpine.scale.x = this.axieScale * this.direction;
    this.changeCurrentAnimation(this.currentAnimation, true);
    this.removeChild(prevSpine);
  }

  registerKeyBoardController() {
    this.keys = {
      left: keyboard("ArrowLeft"),
      right: keyboard("ArrowRight"),
      space: keyboard(" "),
      e: keyboard("e"),
    };

    const { left, right, space, e } = this.keys;

    for (let key in this.keys) {
      window.addEventListener("keydown", this.keys[key].downHandler, false);
      window.addEventListener("keyup", this.keys[key].upHandler, false);
    }

    left.press = () => {
      this.currentSpine.scale.x = this.axieScale * AXIE_DIRECTION.Left;
      this.changeCurrentAnimation("draft/run-origin", true);
      this.vx = -3;

      this.direction = AXIE_DIRECTION.Left;
    };

    left.release = () => {
      if (!right.isDown) {
        this.vx = 0;
        this.changeCurrentAnimation("action/idle/normal", true, 0.8);
      }
    };

    right.press = () => {
      this.currentSpine.scale.x = this.axieScale * AXIE_DIRECTION.Right;
      this.changeCurrentAnimation("draft/run-origin", true);
      this.vx = 3;

      this.direction = AXIE_DIRECTION.Right;
    };

    right.release = () => {
      if (!left.isDown) {
        this.vx = 0;
        this.changeCurrentAnimation("action/idle/normal", true, 0.8);
      }
    };

    space.press = () => {
      this.changeCurrentAnimation("attack/ranged/cast-fly", false);
    };

    space.release = () => {
      this.changeCurrentAnimation("action/idle/normal", true, 1);
    };

    e.press = () => {
      this.changeCurrentAnimation("attack/melee/tail-smash", false);
    };

    e.release = () => {
      this.changeCurrentAnimation("action/idle/normal", true, 1);
    };
  }

  changeCurrentAnimation(keyId, loop, delay) {
    this.currentAnimation = keyId;
    if (delay) {
      this.currentSpine.state.addAnimation(0, keyId, loop, delay);
    } else {
      this.currentSpine.state.setAnimation(0, keyId, loop);
    }
  }
}
