import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH_MOBILE } from "config";

const LightDivider = styled(Stack)(({ theme }) => ({
  position: "relative",
  backgroundImage: "url('/images/templates/home/accents/wood-divider-light.png')",
  backgroundRepeat: "repeat-x",
  backgroundSize: "cover",
  backgroundAttachment: "cover",
  backgroundPosition: "center center",
  height: 36,
  width: "100%",
  zIndex: 2,
  marginTop: -18,

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    height: 20,
    marginTop: -10,
  },
}));

const DarkDivider = styled(Stack)(({ theme }) => ({
  backgroundImage: "url('/images/templates/home/accents/wood-divider-dark.png')",
  backgroundRepeat: "repeat-x",
  backgroundSize: "cover",
  backgroundAttachment: "cover",
  backgroundPosition: "center center",
  height: 56,
  width: "100%",
  zIndex: 2,

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    height: 28,
  },
}));

const WoodDivider = ({ variant = "light" }) => {
  return variant === "dark" ? <DarkDivider /> : <LightDivider />;
};

export default WoodDivider;
