import * as types from "./axieActionTypes";
import produce from "immer";

export const initialState = {
  floorPrices: {
    data: {},
    status: {},
  },
  marketplaceStats: {
    data: {},
    status: {},
  },
};

export const axieReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      /* Price Floors */
      case types.AXIE_FETCH_PRICE_FLOORS_STARTED:
        draft.floorPrices.status.loading = true;
        draft.floorPrices.status.failed = false;
        break;

      case types.AXIE_FETCH_PRICE_FLOORS_SUCCEEDED:
        draft.floorPrices.status.loading = false;
        draft.floorPrices.data = action.data;
        break;

      case types.AXIE_FETCH_PRICE_FLOORS_FAILED:
        draft.floorPrices.status.loading = false;
        draft.floorPrices.status.failed = true;
        break;

      /* Price Floors */
      case types.AXIE_FETCH_MARKETPLACE_STATS_STARTED:
        draft.marketplaceStats.status.loading = true;
        draft.marketplaceStats.status.failed = false;
        break;

      case types.AXIE_FETCH_MARKETPLACE_STATS_SUCCEEDED:
        draft.marketplaceStats.status.loading = false;
        draft.marketplaceStats.data = action.data;
        break;

      case types.AXIE_FETCH_MARKETPLACE_STATS_FAILED:
        draft.marketplaceStats.status.loading = false;
        draft.marketplaceStats.status.failed = true;
        break;

      default:
        break;
    }
  });

export default axieReducer;
