import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import CustomLink from "components/atoms/CustomLink";

const Wrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.colors.mocha[1],
  textTransform: "uppercase",
  transition: "0.2s color ease",
  fontWeight: 800,

  "&:hover": {
    color: theme.palette.colors.white,
  },
}));

const NavLink = ({ link }) => {
  return (
    <CustomLink to={link.to} href={link.href}>
      <Wrapper>{link.text}</Wrapper>
    </CustomLink>
  );
};

export default NavLink;
