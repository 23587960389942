import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Content from "./Content";
import Jumbotron from "./Jumbotron";

const Wrapper = styled(Stack)(({ theme }) => ({
  position: "relative",
}));

const Esports = () => {
  return (
    <Wrapper>
      <Jumbotron />
      <Content />
    </Wrapper>
  );
};

export default Esports;
