import React from "react";
import { Box } from "@mui/material";
import Image from "components/atoms/Image";
import { useParallax } from "react-scroll-parallax";

const Constellation = ({ target }) => {
  const constellation = useParallax({
    speed: 20,
    targetElement: target.current,
  });

  return (
    <Box
      ref={constellation.ref}
      sx={{
        right: 60,
        bottom: 60,
        position: "absolute",
        zIndex: 2,
      }}
    >
      <Image src="/images/templates/home/infinite-experiences/constellation.png" width={247} />
    </Box>
  );
};

export default Constellation;
