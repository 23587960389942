import React, { Fragment } from "react";
import { Routes as RRoutes, Route } from "react-router";
import Page from "../components/templates/Page";
import Pages from "pages";
import { links } from "data/data";

const Routes = () => {
  const routes = [
    {
      path: "/",
      element: <Page page={<Pages.Home />} seo="home" />,
    },
    {
      path: links.axs.to,
      element: <Page page={<Pages.AXS />} seo="axs" />,
    },
    // {
    //   path: links.buildersTerms.to,
    //   element: <Page page={<Pages.BuildersTerms />} seo="buildersTerms" />,
    // },
    {
      path: links.termsOfUse.to,
      element: <Page page={<Pages.TermsOfUse />} seo="termsOfUse" />,
    },
    {
      path: links.privacyPolicy.to,
      element: <Page page={<Pages.PrivacyPolicy />} seo="privacyPolicy" />,
    },
    {
      path: links.codeOfConduct.to,
      element: <Page page={<Pages.CodeOfConduct />} seo="codeOfConduct" />,
    },
    {
      path: links.lore.to,
      element: <Page page={<Pages.Lore />} seo="lore" />,
      children: [
        {
          path: ":chapterId",
          element: <Pages.Chapter />,
          children: [
            {
              path: ":pageId",
              element: <Pages.Chapter />,
            }
          ]
        }
      ]
    },
  ];

  const createRoutes = ({ key, path, element, children }) => {
    if (children?.length > 0) {
      return (
        <Fragment key={key}>
          <Route path={path} element={element} />
          <Route path={path}>
            {children.map((item, index) => createRoutes({ key: index, ...item }))}
          </Route>
        </Fragment>
      );
    }
    return <Route exact path={path} element={element} key={key} />;
  }

  return (
    <RRoutes>
      {routes.map((route, i) => createRoutes({ key: i, ...route }))}
      <Route
        path="*"
        element={<Page page={<Pages.Page404 />} seo="page404" />}
      />
    </RRoutes>
  );
};

export default Routes;
