import React from "react";
import { Stack } from "@mui/material";
import Text from "components/atoms/Typography/Text";

const ViewOnMarketplace = () => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Text textAlign="center">View on Marketplace</Text>
    </Stack>
  );
};

export default ViewOnMarketplace;
