import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "components/atoms/Image";
import Text from "components/atoms/Typography/Text";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: 12,
  width: 240,

  ".icon": {
    position: "relative",
    bottom: 0,
    transition: "0.2s bottom ease",
  },

  "&:hover": {
    ".icon": {
      bottom: 10,
    },
  },
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
}));

const Stat = ({ stat }) => {
  return (
    <Wrapper>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <Image
          src={`/images/icons/${stat.icon}.png`}
          alt={stat.title}
          className="icon"
        />
      </AnimationOnScroll>
      <TextWrapper>
        <Text fontSize={24} fontWeight={800} color="text.light">
          {stat.amount ?? "??"}
        </Text>
        <Text color="text.light">{stat.title}</Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default Stat;
