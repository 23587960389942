import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import CustomLink from "components/atoms/CustomLink";
import PostText from "./PostText";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ style }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url('/images/templates/home/news/${style.background}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  filter: "drop-shadow(0 8px 10px rgba(0,0,0,0.5))",
}));

const Content = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ theme, style }) => ({
  alignItems: "center",
  gap: 13,
  padding: "20% 9%",
  transform: `rotate(${style.rotate})`,
}));

const FeaturedImage = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "background",
})(({ background }) => ({
  backgroundImage: `url('${background}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  borderRadius: "20px",
  width: "100%",
  aspectRatio: "1456 / 844",
}));

const Post = ({ index, post }) => {
  const styles = {
    0: {
      background: "news-1.png",
      rotate: "3deg",
    },
    1: {
      background: "news-2.png",
      rotate: "0deg",
    },
    2: {
      background: "news-3.png",
      rotate: "-3deg",
    },
  };
  return (
    <AnimationOnScroll
      className="post"
      animateIn="animate__bounceInUp"
      duration="0.6"
    >
      <CustomLink href={post.url}>
        <Wrapper style={styles[index]}>
          <Content style={styles[index]}>
            <FeaturedImage background={post.img} />
            <PostText post={post} />
          </Content>
        </Wrapper>
      </CustomLink>
    </AnimationOnScroll>
  );
};

export default Post;
