import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Text from 'components/atoms/Typography/Text';
import Button from 'components/atoms/Button';
import fontSizes from 'theme/fontSizes';
import colors from 'theme/colors';

import { chapters } from 'pages/Lore/data';
import { onChangeFullScreen } from 'pages/Lore/utils';
import { links } from 'data/data';

import { ReactComponent as CaretLeft } from 'icons/caret-left.svg';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import { ReactComponent as ZoomIn } from 'icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'icons/zoom-out.svg';
import { ReactComponent as FullScreen } from 'icons/full-screen.svg';

import PageSelection from './PageSelection';
import {
  ChevronDownWrapper,
  Container,
  Content,
  Divider,
  Dropdown,
  Left,
  PageNumber,
  Pagination,
  Right,
  ZoomWrapper,
} from './styles';

const DesktopFooter = (props) => {
  const { onZoom, isZoomIn, pageId } = props;

  const [isShowPageSelection, setIsShowPageSelection] = useState(false);

  const { chapterId } = useParams();
  const navigation = useNavigate();

  const chapterData = chapters[chapterId] || {};
  const { pages, name, title } = chapterData;

  const onClickToPageNumberDropdown = () => {
    setIsShowPageSelection(!isShowPageSelection);
  };

  const onGoBack = () => {
    navigation(links.lore.to);
  };

  return (
    <Container>
      <PageSelection open={isShowPageSelection} />
      <Content>
        <Left>
          <Button onClick={onGoBack}>
            <CaretLeft color={colors.mocha[1]} />
            <Text fontSize={fontSizes[16]} color="text.lighter">
              BACK TO LORE
            </Text>
          </Button>
          <Text
            fontSize={fontSizes[16]}
            color="text.lighter"
            style={{ textTransform: 'uppercase' }}
          >
            {`${name} - ${title}`}
          </Text>
        </Left>
        <Right>
          <Pagination>
            <Text fontSize={fontSizes[16]} color="text.lighter">
              Page
            </Text>
            <Dropdown onClick={onClickToPageNumberDropdown}>
              <PageNumber>
                <Text fontSize={fontSizes[16]} color="text.white">
                  {pageId || 1}
                </Text>
              </PageNumber>
              <ChevronDownWrapper open={isShowPageSelection}>
                <ChevronDown width={24} height={24} />
              </ChevronDownWrapper>
            </Dropdown>
            <Text fontSize={fontSizes[16]} color="text.lighter">
              {`of ${pages.length}`}
            </Text>
          </Pagination>
          <ZoomWrapper>
            <Button onClick={onZoom}>
              {isZoomIn ? (
                <ZoomOut height={24} color={colors.mocha[1]} />
              ) : (
                <ZoomIn height={24} color={colors.mocha[1]} />
              )}
            </Button>
            <Divider />
            <Button onClick={onChangeFullScreen}>
              <FullScreen height={24} color={colors.mocha[1]} />
            </Button>
          </ZoomWrapper>
        </Right>
      </Content>
    </Container>
  );
};

export default DesktopFooter;
