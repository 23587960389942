import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { default as HeaderText } from "components/atoms/Typography/Header";
import { useBreakpoints } from "hooks/useBreakpoints";
import Text from "components/atoms/Typography/Text";
import { isNullOrEmptyArray } from "helpers/utils";
import CustomLink from "components/atoms/CustomLink";

const BREAKPOINT = 800;

const Wrapper = styled(Stack)(() => ({
  minHeight: 400,
  alignItems: "center",
  position: "relative",
  backgroundImage: `url('/images/templates/sub-page/header-background.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  padding: "182px 30px 60px 30px",

  [`@media (max-width: ${BREAKPOINT}px)`]: {
    minHeight: 300,
  },
}));

const Description = styled(Stack)(({ theme }) => ({
  maxWidth: 1080,
  marginTop: 16,
}));

const Links = styled(Stack)(({ theme }) => ({
  marginTop: 20,
  flexDirection: "row",
  gap: 22,
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
}));

const LinkWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 22,
  alignItems: "center",
  justifyContent: "center",
}));

const LinkText = ({ children }) => (
  <Text
    fontSize={24}
    lineHeight="32px"
    color="#fff"
    fontWeight={700}
    textAlign="center"
    style={{ textShadow: "0px 2px 20px #2363DD" }}
  >
    {children}
  </Text>
);

const Header = ({ title, description, headerLinks }) => {
  const { customBreakpoint } = useBreakpoints(BREAKPOINT);
  return (
    <Wrapper>
      <HeaderText
        variant="h1"
        className="title"
        color="text.white"
        textAlign="center"
        fontWeight={700}
        fontSize={customBreakpoint ? 36 : 48}
        fontFamily="body"
        style={{ lineHeight: 56 / 48 }}
      >
        {title}
      </HeaderText>
      {description && (
        <Description>
          <Text
            className="description"
            color="#fff"
            fontSize={20}
            lineHeight="28px"
            textAlign="center"
            style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            {description}
          </Text>
        </Description>
      )}
      {!isNullOrEmptyArray(headerLinks) && (
        <Links>
          {headerLinks.map((link, i) => (
            <LinkWrapper key={i}>
              {i > 0 && <LinkText>|</LinkText>}
              <CustomLink href={link.href}>
                <LinkText>{link.text}</LinkText>
              </CustomLink>
            </LinkWrapper>
          ))}
        </Links>
      )}
    </Wrapper>
  );
};

export default Header;
