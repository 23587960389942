import { useState, useEffect } from "react";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default function useWindowWidth() {
  function getWidth() {
    return {
      width: window.innerWidth,
    };
  }

  const [width, setWidth] = useState(getWidth);

  useEffect(() => {
    var handleResizeDebounced = debounce(function handleResize() {
      setWidth(getWidth());
    }, 250);

    window.addEventListener("resize", handleResizeDebounced);
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, []);

  return width;
}
