import React from "react";
import { Typography } from "@mui/material";
import fontSizes from "theme/fontSizes";
import fonts from "theme/fonts";

const Header = ({
  variant = "h2",
  children,
  color,
  fontSize,
  className,
  textAlign = "left",
  style,
  fontFamily = "header",
  lineHeight,
  fontWeight,
}) => {
  return (
    <Typography
      variant={variant}
      textAlign={textAlign}
      fontSize={fontSizes[fontSize]}
      color={color}
      className={className}
      fontWeight={fontWeight}
      fontFamily={fonts[fontFamily]}
      sx={{ lineHeight, ...style }}
    >
      {children}
    </Typography>
  );
};

export default Header;
