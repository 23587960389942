export const AXIE_FETCH_PRICE_FLOORS = "AXIE_FETCH_PRICE_FLOORS";
export const AXIE_FETCH_PRICE_FLOORS_STARTED =
  "AXIE_FETCH_PRICE_FLOORS_STARTED";
export const AXIE_FETCH_PRICE_FLOORS_FAILED = "AXIE_FETCH_PRICE_FLOORS_FAILED";
export const AXIE_FETCH_PRICE_FLOORS_SUCCEEDED =
  "AXIE_FETCH_PRICE_FLOORS_SUCCEEDED";

export const AXIE_FETCH_MARKETPLACE_STATS = "AXIE_FETCH_MARKETPLACE_STATS";
export const AXIE_FETCH_MARKETPLACE_STATS_STARTED =
  "AXIE_FETCH_MARKETPLACE_STATS_STARTED";
export const AXIE_FETCH_MARKETPLACE_STATS_FAILED =
  "AXIE_FETCH_MARKETPLACE_STATS_FAILED";
export const AXIE_FETCH_MARKETPLACE_STATS_SUCCEEDED =
  "AXIE_FETCH_MARKETPLACE_STATS_SUCCEEDED";
