import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import { chapters } from '../../../../data';
import PageItem from '../../../PageItem';

import {
  ArrowLeftButton,
  ArrowRightButton,
  Container,
  LeftOverlay,
  RightOverlay,
} from './styles';

const NUMBER_SLIDE_PER_VIEW = 12;

const PageSelection = (props) => {
  const { open } = props;

  const carouselRef = useRef();
  const { chapterId, pageId } = useParams();

  const pages = chapters[chapterId].pages;

  const CustomRightArrow = ({ onClick }) => {
    return (
      <ArrowRightButton>
        <img
          alt="arrow-right"
          src={'/images/icons/arrow-right.png'}
          onClick={onClick}
          width={48}
          height={48}
        />
      </ArrowRightButton>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <ArrowLeftButton>
        <img
          alt="arrow-left"
          src={'/images/icons/arrow-left.png'}
          onClick={onClick}
          width={48}
          height={48}
        />
      </ArrowLeftButton>
    );
  };

  useEffect(() => {
    const nextSlide = Math.max(0, Number(pageId) - NUMBER_SLIDE_PER_VIEW / 2);
    carouselRef?.current?.goToSlide(nextSlide);
  }, []);

  return (
    <Container open={open}>
      <Carousel
        ref={carouselRef}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: NUMBER_SLIDE_PER_VIEW,
            slidesToSlide: NUMBER_SLIDE_PER_VIEW,
            partialVisibilityGutter: 10,
          },
        }}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {pages.map((image, index) => {
          const currentPage = index + 1;
          return (
            <PageItem
              key={`page-selection-${chapterId}-page-${currentPage}`}
              currentPage={currentPage}
              image={image}
            />
          );
        })}
      </Carousel>
      <LeftOverlay />
      <RightOverlay />
    </Container>
  );
};

export default PageSelection;
