import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';

import Text from 'components/atoms/Typography/Text';
import { chapters } from 'pages/Lore/data';
import fontSizes from 'theme/fontSizes';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

import PageItem from '../../../PageItem';
import { Container, Content, Header, Pages } from './styles';

const PageSelection = (props) => {
  const { open, onClose } = props;

  const { chapterId } = useParams();

  const pages = chapters[chapterId].pages;

  return (
    <Container open={open}>
      <Content>
        <Header>
          <Text fontSize={fontSizes[16]} color="text.white">
            Jump to page
          </Text>
          <IconButton onClick={onClose}>
            <CloseIcon color="white" />
          </IconButton>
        </Header>
        <Pages>
          {pages.map((image, index) => {
            const currentPage = index + 1;
            return (
              <PageItem
                key={`mobile-page-selection-${chapterId}-page-${index + 1}`}
                currentPage={currentPage}
                image={image}
              />
            );
          })}
        </Pages>
      </Content>
    </Container>
  );
};

export default PageSelection;
