import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Stack, useMediaQuery } from "@mui/material";
import AxieStand from "./AxieStand";
import { fetchPriceFloors } from "store/axies/axieActions";
import { connect } from "react-redux";
import Container from "components/atoms/Container";
import { CONTAINER_WIDTH } from "config";
import { getFloorPrices, getFloorPricesStatus } from "store/axies/selectors";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "center",
}));

const Break = styled(Stack)(() => ({
  flexBasis: "100%",
}));

const AxieShowcase = ({ floorPrices, fetchPriceFloors, status }) => {
  useEffect(() => {
    fetchPriceFloors();
  }, [fetchPriceFloors]);

  const lgDown = useMediaQuery(`(max-width:${CONTAINER_WIDTH}px)`);

  if (status.loading || status.failed || !floorPrices) return null;

  const categories = [
    "aquatic",
    "beast",
    "plant",
    "bird",
    "reptile",
    "bug",
    "dawn",
    "dusk",
    "mech",
    "frosty",
    "japanese",
    "mystic",
  ];

  const breaks = lgDown
    ? { 2: true, 4: true, 6: true, 8: true, 10: true }
    : {
        3: true,
        9: true,
        12: true,
      };

  return (
    <Container>
      <Wrapper>
        {categories.map((category, i) => {
          return (
            <AxieWithBreak
              data={floorPrices[category]}
              shouldBreak={breaks[i + 1]}
              key={i}
            />
          );
        })}
      </Wrapper>
    </Container>
  );
};

const AxieWithBreak = ({ data, shouldBreak }) => {
  return (
    <>
      <AxieStand data={data} />
      {shouldBreak && <Break />}
    </>
  );
};

const mapDispatchToProps = { fetchPriceFloors };

const mapStateToProps = (state) => ({
  floorPrices: getFloorPrices(state),
  status: getFloorPricesStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AxieShowcase);
