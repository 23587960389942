import { MAX_AXIE_ID_FALLBACK } from "config";
import { getRandomInteger } from "helpers/display";
import { requestGetAxieDetail } from "services/api";

const MAX_RETRY_COUNT = 2;

export const getAxieGenes = async (axieId) => {
  let retryCount = 0;

  const fetchAxie = async (axieId) => {
    try {
      const data = await requestGetAxieDetail(axieId);

      const genes = data?.newGenes;

      if (genes != null) {
        return genes;
      } else {
        if (retryCount < MAX_RETRY_COUNT) {
          retryCount++;
          return fetchAxie(getRandomInteger(1, MAX_AXIE_ID_FALLBACK));
        } else {
          throw new Error("Max retries reached");
        }
      }
    } catch (e) {
      if (retryCount < MAX_RETRY_COUNT) {
        retryCount++;
        return fetchAxie(getRandomInteger(1, MAX_AXIE_ID_FALLBACK));
      } else {
        throw new Error("Max retries reached");
      }
    }
  };

  return await fetchAxie(axieId);
};
