import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import data from "data/data";
import Image from "components/atoms/Image";
import { CONTAINER_WIDTH } from "config";
import Header from "components/atoms/Typography/Header";
import Text from "components/atoms/Typography/Text";
import { useBreakpoints } from "hooks/useBreakpoints";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Centered = styled(Stack)(({ theme }) => ({
  position: "relative",
  width: "100%",
  margin: "0 auto",
  maxWidth: 625,
  alignItems: "center",

  ".card": {
    position: "absolute",
    top: "74%",
    right: "2%",
    maxWidth: 228,

    [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
      width: "50%",
    },
  },
}));

const Description = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "customBreakpoint",
})(({ customBreakpoint }) => ({
  position: "absolute",
  top: `${(110 / 633) * 100}%`,
  left: `${(105 / 625) * 100}%`,
  width: `${(400 / 625) * 100}%`,
  textAlign: "left",
  gap: "20px",

  ...(customBreakpoint && {
    top: `${(80 / 633) * 100}%`,
    width: `${(420 / 625) * 100}%`,
  }),
}));

const Background = styled(Stack)(({ theme }) => ({
  position: "relative",
  width: `${(441 / 360) * 100}%`,
  backgroundImage: `url('/images/templates/home/what-is-axie-infinity/note.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  minWidth: 440,
  maxWidth: 625,
  aspectRatio: "625 / 633",
}));

const Content = () => {
  const { customBreakpoint } = useBreakpoints(511);

  return (
    <AnimationOnScroll
      animateIn="animate__fadeInLeft"
      duration="0.4"
      className="animatedWrapper"
    >
      <Centered>
        <Background>
          <Description customBreakpoint={customBreakpoint}>
            <Header
              color="text.regular"
              fontSize={customBreakpoint ? 32 : 48}
              lineHeight={customBreakpoint ? 40 / 32 : 56 / 48}
            >
              {data.whatIsAxieInfinity.title}
            </Header>
            <Text color="text.dark" fontSize={customBreakpoint ? 14 : 16}>
              {data.whatIsAxieInfinity.description}
            </Text>
          </Description>
        </Background>
        <Image
          src="/images/templates/home/what-is-axie-infinity/postcard.png"
          alt="Axie postcard"
          className="card"
        />
      </Centered>
    </AnimationOnScroll>
  );
};

export default Content;
