import React from "react";
import { useLocation } from "react-router-dom";
import LinkWrapper from "./LinkWrapper";
import DropdownLink from "./DropdownLink";

const Link = ({
  type,
  text,
  to,
  href,
  dropdownLinks,
  isOpen,
  setIsOpen,
  handleDrawerClose,
}) => {
  const { pathname } = useLocation();

  if (dropdownLinks) {
    return (
      <DropdownLink
        type={type}
        dropdownLinks={dropdownLinks}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleDrawerClose={handleDrawerClose}
      >
        {text}
      </DropdownLink>
    );
  }

  return (
    <LinkWrapper
      to={to}
      href={href}
      type={type}
      status={to === pathname ? "active" : "inactive"}
      onClick={handleDrawerClose}
    >
      {text}
    </LinkWrapper>
  );
};

export default Link;
