export const chapters = {
  1: {
    name: 'Issue #1',
    title: 'The Forgotten Temple',
    coverImage: '/images/templates/lore/chapter-1/cover.jpg',
    pages: [
      '/images/templates/lore/chapter-1/page-1.jpg',
      '/images/templates/lore/chapter-1/page-2.jpg',
      '/images/templates/lore/chapter-1/page-3.jpg',
      '/images/templates/lore/chapter-1/page-4.jpg',
      '/images/templates/lore/chapter-1/page-5.jpg',
      '/images/templates/lore/chapter-1/page-6.jpg',
      '/images/templates/lore/chapter-1/page-7.jpg',
      '/images/templates/lore/chapter-1/page-8.jpg',
      '/images/templates/lore/chapter-1/page-9.jpg',
      '/images/templates/lore/chapter-1/page-10.jpg',
      '/images/templates/lore/chapter-1/page-11.jpg',
      '/images/templates/lore/chapter-1/page-12.jpg',
      '/images/templates/lore/chapter-1/page-13.jpg',
      '/images/templates/lore/chapter-1/page-14.jpg',
      '/images/templates/lore/chapter-1/page-15.jpg',
      '/images/templates/lore/chapter-1/page-16.jpg',
      '/images/templates/lore/chapter-1/page-17-1.jpg',
      '/images/templates/lore/chapter-1/page-18.jpg',
      '/images/templates/lore/chapter-1/page-19.jpg',
      '/images/templates/lore/chapter-1/page-20.jpg',
      '/images/templates/lore/chapter-1/page-21.jpg',
    ],
  },
};
