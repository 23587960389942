import React from "react";
import Header from "components/atoms/Typography/Header";
import { useBreakpoints } from "hooks/useBreakpoints";

const Title = ({ children }) => {
  const { desktopDown } = useBreakpoints();
  return (
    <Header
      variant="h1"
      color="text.blue"
      fontSize={desktopDown ? 40 : 48}
      textAlign="center"
      style={{ lineHeight: desktopDown ? 56 / 48 : 48 / 40 }}
    >
      {children}
    </Header>
  );
};

export default Title;
