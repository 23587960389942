import React from "react";
import data from "data/data";
import Button from "components/atoms/Button";
import { AXIE_DISCORD, AXIE_TWITTER } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";
import Text from "components/atoms/Typography/Text";
import styled from "@emotion/styled";
import StackedButtons from "components/molecules/StackedButtons";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  bottom: 30,
  transform: "translateX(-50%)",

  "& .text": {
    textShadow: "0px 1px 2px #000",
  },

  [`@media (max-width: 1000px)`]: {
    position: "static",
    transform: "none",
    background: "#0B2670",
    width: "100%",
    padding: "20px 20px 60px 20px",
    alignItems: "center",
  },
}));

const Content = styled(Stack)(({ theme }) => ({
  width: 360,
  gap: 36,
  alignItems: "center",
}));

const Description = () => {
  const { customBreakpoint } = useBreakpoints(1000);

  return (
    <Wrapper>
      <Content>
        <Text color="text.blue" textAlign="center" className="text">
          {data.community.description}
        </Text>

        <StackedButtons>
          <Button
            variant="discord"
            size={customBreakpoint ? "small" : "large"}
            href={AXIE_DISCORD}
          >
            Discord
          </Button>
          <Button
            variant="twitter"
            size={customBreakpoint ? "small" : "large"}
            href={AXIE_TWITTER}
          >
            Twitter
          </Button>
        </StackedButtons>
      </Content>
    </Wrapper>
  );
};

export default Description;
