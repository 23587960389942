import React from "react";
import Text from "components/atoms/Typography/Text";
import { formatUsd } from "helpers/display";

const IdPlate = ({ id, price }) => {
  return (
    <>
      <Text color="text.dark">{`#${id}`}</Text>
      <Text color="text.dark" fontSize={20} fontWeight={800} lineHeight="16px">
        {price != null ? `${formatUsd(price)}` : "??"}
      </Text>
    </>
  );
};

export default IdPlate;
