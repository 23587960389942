import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import Link from "./Link";

const gap = 40;

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["type", "dropdownHeight"].includes(prop),
})(({ type, dropdownHeight }) => ({
  background: "#C7643D",
  boxShadow: "0px 8px #724051",
  position: "absolute",
  borderRadius: `0 0 16px 16px`,
  zIndex: 2,
  top: "100%",
  left: 0,
  padding: "16px",
  gap: "24px",

  ...(type === "mobile" && {
    background: "transparent",
    boxShadow: "none",
    border: "none",
    position: "static",
    marginTop: "30px",
    padding: "0 0 0 30px",
    overflow: "hidden",
    height: dropdownHeight,
    gap,
  }),
}));

const Dropdown = ({ isOpen, links, type, handleDrawerClose }) => {
  if (!isOpen) return null;

  return (
    <Wrapper type={type} dropdownHeight={isOpen ? calculateHeight(links) : 0}>
      {links.map((link, i) => (
        <Link
          key={`LINK_${i}`}
          {...link}
          type={type}
          handleDrawerClose={handleDrawerClose}
        />
      ))}
    </Wrapper>
  );
};

const calculateHeight = (links) => {
  const heightPerItem = 24;
  const numLinks = (links || []).length;

  return numLinks * heightPerItem + gap * (numLinks === 1 ? 0 : numLinks - 1);
};

export default Dropdown;
