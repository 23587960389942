import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import fonts from "theme/fonts";
import fontSizes from "theme/fontSizes";

const Wrapper = styled(Stack)(({ theme }) => ({
  p: { fontSize: 16, lineHeight: "24px" },

  "h1 + h2, h1 + p": {
    marginTop: "12px",
  },

  "p + h2, ul + h2, table + h2": {
    marginTop: "28px",
  },

  "p + h3, ul + h3, table + h3": {
    marginTop: "8px",
  },

  "* + table": {
    marginTop: "20px",
  },

  "table + *": {
    marginTop: "20px",
  },

  "a, a:visited": {
    color: theme.palette.colors.blue[600],
    textDecoration: "none",
  },

  strong: {
    fontWeight: 800,
  },

  em: {
    fontStyle: "italic",
  },

  h1: {
    textAlign: "center",
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: 800,
  },

  h2: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 800,
  },

  h3: {
    fontSize: 16,
    lineHeight: "28px",
    fontWeight: 800,
  },

  h4: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
  },

  button: {
    background: theme.palette.colors.brown.medium,
    border: "none",
    fontFamily: fonts.header,
    color: theme.palette.colors.mocha[1],
    fontSize: fontSizes[20],
    lineHeight: 28 / 20,
    width: 200,
    height: 48,
    borderRadius: "12px",
    transition: "0.2s transform ease",

    "&:hover": {
      transform: "scale(1.06)",
      cursor: "pointer",
    },
  },

  ul: {
    paddingInlineStart: 40,

    "&.lowerAlpha": {
      listStyleType: "lower-alpha",
    },

    "&.disc": {
      listStyleType: "disc",

      li: {
        "&:not(:last-child), & > *:not(:last-child)": {
          marginBottom: 0,
        },
      },
    },

    "&.circle": {
      listStyleType: "circle",

      li: {
        "&:not(:last-child), & > *:not(:last-child)": {
          marginBottom: 0,
        },
      },
    },

    li: {
      "&:not(:last-child), & > *:not(:last-child)": {
        marginBottom: "12px",

        table: {
          marginBottom: 18,
        },
      },
    },
  },

  ol: {
    paddingInlineStart: 40,

    "&.lowerAlpha": {
      listStyleType: "lower-alpha",
      li: {
        "&:not(:last-child), & > *:not(:last-child)": {
          marginBottom: 0,
        },
      },
    },
    "&.lowerRoman": {
      listStyleType: "lower-roman",

      li: {
        "&:not(:last-child), & > *:not(:last-child)": {
          marginBottom: 0,
        },
      },
    },

    "&.decimal": {
      listStyleType: "decimal",
      "& > li::marker": {
        fontSize: 20,
        lineHeight: "28px",
        fontWeight: 800,
      }

    },

    li: {
      h2: {
        marginTop: "28px"
      },
      "&:not(:last-child), & > *:not(:last-child)": {
        marginBottom: "12px",

        table: {
          marginBottom: 18,
        },
      },
    },
  },

  table: {
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "separate",
    border: `2px solid ${theme.palette.colors.brown.light}`,
    borderRadius: "8px",
    display: "block",
    overflowX: "auto",

    tbody: {
      tr: {
        td: {
          lineHeight: "24px",
          padding: "12px 20px",
          verticalAlign: "middle",

          "&:nth-of-type(1)": {
            textAlign: "center",
            width: "15%",
            borderRight: `2px solid ${theme.palette.colors.brown.light}`,
            fontWeight: 800,
          },

          "&:nth-of-type(2)": {
            textAlign: "left",
          },
        },

        "&:not(:last-child) td": {
          borderBottom: `2px solid ${theme.palette.colors.brown.light}`,
        },
      },
    },
  },

  hr: {
    backgroundImage: 'url("/images/templates/sub-page/axs-divider.png")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 20,
    width: 174.3,
    border: "none",
    margin: "28px auto",
  },

  /* Custom Styles */

  "& .textCenter": {
    textAlign: "center !important",
  },

  "& .columns": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",

    "&.columns3": {
      "& > div": {
        textAlign: "center",
        width: 360,
      },

      img: {
        marginBottom: 10,
      },

      h2: {
        marginBottom: 20,
      },

      "@media (max-width: 1150px)": {
        flexDirection: "column",
        alignItems: "center",

        "& > div": {
          width: "auto",
          maxWidth: 340,
        },
      },
    },
  },

  /* Page Specific */

  "table.buildersRevenueSharing": {
    tr: {
      td: {
        textAlign: "center",
        "&:not(:last-of-type)": {
          borderRight: `2px solid ${theme.palette.colors.brown.light}`,
        },
      },

      "&:nth-of-type(2)": {
        "& td:nth-of-type(2)": {
          fontWeight: 800,
        },
      },
    },
  },

  // Code of Conduct Page
  "table.codeOfConductRevisions": {
    border: `none`,

    thead: {
      th: {
        backgroundColor: "rgba(179,130,83,0.5)",
        padding: "12px 20px",
        fontWeight: 800,

        "&:first-of-type": {
          borderRadius: "8px 0 0 8px",
        },

        "&:last-of-type": {
          borderRadius: "0 8px 8px 0",
        },
      },
    },

    tr: {
      td: {
        textAlign: "center",
        borderBottom: `2px solid ${theme.palette.colors.brown.light}`,

        "&:first-of-type": {
          borderRight: "none",
          fontWeight: 400,
        },

        "&:nth-of-type(2)": {
          whiteSpace: "nowrap",
        },
      },
    },
  },

  // Privacy Policy Page
  "&.privacyPolicy": {
    p: {
      marginLeft: 28,
    },
  },

  // Terms of Use Page
  "&.termsOfUse": {
    h1: {
      textAlign: "left",
    },
    p: {
      marginLeft: 28,
    },
  },

  // AXS Page
  "&.axs": {
    h1: {
      textAlign: "left",
    },

    "& .columns": {
      marginTop: 64,
      marginBottom: 64,
    },

    "& .staking": {
      display: "flex",
      flexDirection: "row",
      marginBottom: 64,
      justifyContent: "space-between",
      gap: "80px",

      "@media (max-width: 900px)": {
        flexDirection: "column",
        alignItems: "center",
        gap: "42px",
      },

      "& .content": {
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
        maxWidth: 560,
        marginTop: 100,

        "& .text": {
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        },

        "@media (max-width: 900px)": {
          marginTop: 0,
          alignItems: "center",

          "& .text": {
            "& > p, & > h1": {
              textAlign: "center",
            },
          },
        },
      },
    },

    "& .diagram": {
      width: "100%",
      maxWidth: 600,
      img: {
        width: "100%",
      },
    },

    "& .tokenInfo": {
      display: "flex",
      flexDirection: "row",
      gap: "80px",
      justifyContent: "space-between",

      "@media (max-width: 900px)": {
        flexDirection: "column",
        alignItems: "center",
        gap: "64px",
      },

      "& > div > h1": {
        textAlign: "center",
        marginBottom: 46,
      },

      "& .diagram": {
        width: "100%",
        maxWidth: 560,
        img: {
          width: "100%",
        },
      },
    },
  },
}));

export default Wrapper;
