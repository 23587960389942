import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const LAYOUT_BREAKPOINT = 880;

export const Background = styled(Stack)(() => ({
  backgroundImage: `url('/images/templates/home/accents/wood-background-dark.jpg')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  paddingTop: 184,
  paddingBottom: 100,

  [`@media (max-width: ${LAYOUT_BREAKPOINT}px)`]: {
    paddingTop: 150,
  },
}));

export const Board = styled(Stack)(() => ({
  maxWidth: LAYOUT_BREAKPOINT,
  width: "100%",
  margin: "0 auto",
  aspectRatio: "880 / 457",
  backgroundImage: `url('/images/templates/page-404/board.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",

  [`@media (max-width: ${LAYOUT_BREAKPOINT}px)`]: {
    aspectRatio: "324 / 404",
    backgroundImage: `url('/images/templates/page-404/board-mobile.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 20px",
  marginTop: -8,
}));

export const Content = styled(Stack)(({ theme }) => ({
  gap: "32px",
  alignItems: "center",
}));

export const TextContent = styled(Stack)(({ theme }) => ({
  gap: "20px",
  alignItems: "center",
  maxWidth: 460,

  "& .textLink": {
    color: theme.palette.colors.mocha[2],
    textDecoration: "underline",

    "&:hover": {
      textDecoration: "none",
    },
  },
}));
