import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Header from "components/atoms/Typography/Header";
import Text from "components/atoms/Typography/Text";
import { CONTAINER_WIDTH_MOBILE } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";
import CustomLink from "components/atoms/CustomLink";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: 360,

  "& .header": {
    marginBottom: 12,
  },

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    width: 320,
  },
}));

const Thumbnail = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "background",
})(({ background }) => ({
  width: "100%",
  borderRadius: "50%",
  marginBottom: 20,
  paddingBottom: "100%",
  position: "relative",

  "&:hover": {
    "& .image": {
      backgroundSize: "108%",
    },
  },

  "& .image": {
    backgroundImage: `url('${background}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center center",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    border: "4px solid #4A577A",
    transition: "0.2s all ease",
  },
}));

const Experience = ({ experience }) => {
  const { mobile } = useBreakpoints();

  return (
    <Wrapper className="experience">
      <CustomLink href={experience.href}>
        <Thumbnail
          background={`/images/templates/home/infinite-experiences/${experience.image}.jpg`}
        >
          <Stack className="image" />
        </Thumbnail>

        <Header
          variant="h3"
          color="text.blue"
          textAlign="center"
          className="header"
        >
          {experience.title}
        </Header>
        <Text
          textAlign="center"
          color="text.blue"
          fontSize={mobile ? 14 : 16}
          lineHeight={mobile ? 20 / 14 : 24 / 16}
        >
          {experience.description}
        </Text>
      </CustomLink>
    </Wrapper>
  );
};

export default Experience;
