import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import data from "data/data";
import Photo from "./Photo";
import Description from "./Description";
import Title from "../Title";

const Wrapper = styled(Stack)(({ theme }) => ({
  marginTop: "-4%",
  position: "relative",

  "& .header": {
    marginBottom: "-2%",
  },
}));

const Centered = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  maxWidth: 1440,
  position: "relative",
  margin: "0 auto",
}));

const Background = styled(Stack)(({ theme }) => ({
  position: "relative",
  paddingBottom: `${(926 / 1440) * 100}%`,
  width: `${(1440 / 1280) * 100}%`,
  backgroundImage: `url('/images/templates/home/infinite-experiences/globe.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top center",
  minWidth: 554,
}));

const Globe = () => {
  return (
    <Wrapper>
      <Title>{data.community.title}</Title>

      <Centered>
        <Background />
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <Photo index={index} key={index} />
        ))}
      </Centered>

      <Description />
    </Wrapper>
  );
};

export default Globe;
