import React, { useState } from "react";
import Tabs from "components/atoms/Tabs";
import TabPanel from "components/atoms/Tabs/TabPanel";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import data from "data/data";
import Image from "components/atoms/Image";
import Text from "components/atoms/Typography/Text";
import { CONTAINER_WIDTH_MOBILE } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";
import { BREAKPOINT } from ".";
import Carousel from "react-material-ui-carousel";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  position: "relative",

  ".thumbnail": {
    border: `1px solid ${theme.palette.colors.mocha[2]}`,
    borderRadius: 32,
    overflow: "hidden",
    width: "100%",
    maxWidth: 1024,
    aspectRatio: "1024 / 511",

    img: {
      width: "100%",
    },

    [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
      borderRadius: 12,
    },
  },
}));

const InfoWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: 720,
  margin: "0 auto",
  alignItems: "center",
  marginBottom: `${(50 / 1200) * 100}%`,
}));

const InfoTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabId = "info-tabs";
  const { customBreakpoint } = useBreakpoints(BREAKPOINT);

  return (
    <Wrapper>
      <Carousel
        sx={{
          width: "100%",
          aspectRatio: "1024 / 513",
          marginBottom: `${(48 / 1200) * 100}%`,
        }}
        interval={6000}
        index={activeTab}
        animation="slide"
        navButtonsWrapperProps={{
          style: {
            opacity: "1",
            "&:hover": {
              "& $button": {
                backgroundColor: "black",
                filter: "brightness(120%)",
                opacity: "1.0 !important",
              },
            },
          },
        }}
        navButtonsProps={{
          style: {
            color: "white",
            backgroundColor: "transparent",
            filter: "brightness(120%)",
            opacity: "0.8",
            borderRadius: "6px",
            height: "30px",
            "&:hover unset": {
              backgroundColor: "transparent",
              opacity: "1.0 !important",
              transform: "scale(1.1)",
            },
          },
        }}
        indicators={false}
        navButtonsAlwaysVisible={true}
        onChange={(now, prev) => {
          setActiveTab(now);
        }}
        NextIcon={
          <Image
            src="/images/templates/home/accents/wood-arrow-right.png"
            alt="Next arrow"
            width={customBreakpoint ? 20 : 40}
            className="arrow"
          />
        }
        PrevIcon={
          <Image
            src="/images/templates/home/accents/wood-arrow-left.png"
            alt="Previous arrow"
            width={customBreakpoint ? 20 : 40}
          />
        }
      >
        {data.playCollectOwn.map((item, i) => (
          <Image
            key={i}
            src={`/images/templates/home/play-collect-own/${item.image}`}
            className="thumbnail"
          />
        ))}
      </Carousel>

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsId={tabId}
        tabs={data.playCollectOwn}
        ariaLabel="Info tabs"
        labelKey="title"
        gap={customBreakpoint ? "16px" : "40px"}
        tabsStyle={{
          marginBottom: `${(30 / 1200) * 100}%`,
        }}
        tabStyle={{ fontSize: customBreakpoint ? 20 : 40 }}
      />
      <InfoWrapper>
        {data.playCollectOwn.map((tab, i) => (
          <TabPanel value={activeTab} index={i} id={tabId} key={tab.title}>
            <Text
              color="text.light"
              textAlign="center"
              fontSize={customBreakpoint ? 14 : 16}
            >
              {tab.description}
            </Text>
          </TabPanel>
        ))}
      </InfoWrapper>
    </Wrapper>
  );
};

export default InfoTabs;
