import { Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const CustomLink = ({
  children,
  to,
  hashTo,
  href,
  startSpace = true,
  endSpace = true,
  style,
  underline,
  target = "_self",
  color,
  onClick,
  className = "link",
}) => {
  if (to == null && hashTo == null && href == null && onClick == null) {
    return children;
  }
  return (
    <>
      {startSpace && " "}
      <LinkWrapped
        hashTo={hashTo}
        to={to}
        href={href}
        style={style}
        underline={underline}
        target={target}
        color={color}
        onClick={onClick}
        className={className}
      >
        {children}
      </LinkWrapped>
      {endSpace && " "}
    </>
  );
};

const LinkWrapped = ({
  to,
  hashTo,
  href,
  children,
  style,
  underline,
  target,
  color,
  onClick,
  className,
}) =>
  to != null ? (
    <Link
      to={to}
      component={RouterLink}
      sx={style}
      underline={underline}
      target={target}
      color={color}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : hashTo != null ? (
    <Link
      to={hashTo}
      component={HashLink}
      sx={style}
      underline={underline}
      target={target}
      color={color}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : href != null ? (
    <Link
      href={href}
      target="_blank"
      sx={style}
      underline={underline}
      color={color}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <span
      onClick={onClick}
      sx={style}
      underline={underline}
      color={color}
      className={className}
    >
      {children}
    </span>
  );

export default CustomLink;
