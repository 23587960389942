import Text from 'components/atoms/Typography/Text';
import fontSizes from 'theme/fontSizes';

import { Container, Content, ImageWrapper, Overlay } from './styles';

const ChapterCover = (props) => {
  const { coverImage, title, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Content>
        <ImageWrapper>
          <img src={coverImage} alt="cover-img" />
          <Overlay />
        </ImageWrapper>
        <Text
          color="text.lighter"
          fontSize={fontSizes[20]}
          style={{ position: 'absolute', bottom: 20, left: 20 }}
        >
          {title}
        </Text>
      </Content>
    </Container>
  );
};

export default ChapterCover;
