import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { keyframes } from "@emotion/react";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["size", "style"].includes(prop),
})(({ size, style }) => ({
  width: `${size}px`,
  img: {
    animation: `${spin} 0.7s linear infinite`,
    width: "100%",
    margin: "0 auto",
  },
  ...style,
}));

const PuffLoader = ({ size = 80, style = {} }) => {
  return (
    <Wrapper size={size} className="puffLoader" style={style}>
      <img src="/images/templates/home/accents/puff-loader.png" alt="Loader" />
    </Wrapper>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
    transform: rotate(-360deg);
  }
}`;

export default PuffLoader;
