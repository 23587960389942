import { addAlphaToHex } from "helpers/colors";

const makeStyles = (variant, size, theme) => {
  /* Jumbotron Play Now Button */
  if (variant === "play-now") {
    const styles = {
      large: {
        width: 240,
        height: 80,
        fontSize: 28,
        textTransform: "uppercase",
        color: theme.palette.colors.mocha[1],
      },
      small: {
        width: 180,
        height: 60,
        fontSize: 20,
        textTransform: "uppercase",
        color: theme.palette.colors.mocha[1],
      },
    };

    return {
      backgroundImage: `${variant}-${size}.png`,
      ...styles[size],
    };
  }

  /* Plain Color Buttons */
  if (variant === "plain") {
    const sharedStyles = {
      background: addAlphaToHex(theme.palette.colors.darkGrey, 0.5),
      color: theme.palette.colors.mocha[1],
      textTransform: "uppercase",

      hover: {
        backgroundColor: addAlphaToHex(theme.palette.colors.darkGrey, 0.5),
      },
    };

    const styles = {
      large: {
        ...sharedStyles,
        height: 48,
        borderRadius: 12,
        width: 200,
        fontSize: 20,
      },
      small: {
        ...sharedStyles,
        height: 40,
        borderRadius: 10,
        width: 160,
        fontSize: 16,
      },
    };

    return styles[size];
  }

  /* Social Buttons */
  if (variant === "discord" || variant === "twitter") {
    const styles = {
      large: {
        borderRadius: 12,
        color: theme.palette.colors.white,
        width: 172,
        textTransform: "uppercase",
        height: 48,
        fontSize: 20,
      },
      small: {
        borderRadius: 12,
        color: theme.palette.colors.white,
        width: 154,
        textTransform: "uppercase",
        height: 48,
        fontSize: 20,
      },
    };
    const colors = {
      twitter: {
        background: theme.palette.colors.socials.twitter,

        hover: {
          background: theme.palette.colors.socials.twitter,
        },
      },
      discord: {
        background: theme.palette.colors.socials.discord,

        hover: {
          background: theme.palette.colors.socials.discord,
        },
      },
    };

    return {
      ...styles[size],
      ...colors[variant],
    };
  }

  /* Wood Buttons */
  if (variant === "dark" || variant === "light") {
    const styles = {
      148: {
        height: 40,
        fontSize: 16,
        lineHeight: 24 / 16,
      },
      154: {
        height: 48,
        fontSize: 20,
        lineHeight: 28 / 20,
      },
      178: {
        height: 48,
        fontSize: 20,
        lineHeight: 28 / 20,
      },
      188: { height: 60, fontSize: 20, lineHeight: 28 / 20 },
      224: { height: 60, fontSize: 20, lineHeight: 28 / 20 },
    };

    return {
      backgroundImage: `${variant}-${size}.png`,
      color: theme.palette.colors.mocha[2],
      width: size,
      textTransform: "none",
      ...styles[size],
    };
  }

  return null;
};

export default makeStyles;
