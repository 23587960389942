import fontSizes from "theme/fontSizes";
import fonts from "theme/fonts";

export const typography = {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: fontSizes[80],
        lineHeight: 1.12,
        fontFamily: fonts.header,
        textTransform: "uppercase",
      },
      h2: {
        fontSize: fontSizes[40],
        lineHeight: 1.2,
        fontFamily: fonts.header,
        textTransform: "uppercase",
      },
      h3: {
        fontSize: fontSizes[32],
        lineHeight: 1.25,
        fontFamily: fonts.header,
        textTransform: "uppercase",
      },
      body1: {
        lineHeight: 1.6,
        fontSize: fontSizes[20],
        fontWeight: 600,
      },
      body2: {
        lineHeight: 1.5,
        fontSize: fontSizes[16],
        fontWeight: 400,
      },
      span: {
        lineHeight: 1.5,
      },
    },
  },
};
