import {
  MARKETPLACE_URL,
  GAMES_URL_ORIGINS,
  GAMES_URL,
  MARKETPLACE_URL_AXIES,
  WHITEPAPER_URL,
  GAMES_URL_LAND,
  BUILDERS_URL,
  BREEDING_URL,
  BLOG_URL,
  CREATORS_URL,
  GAMEPLAY_TRAILER_URL,
  AXIECON_ESPORTS_REPLAY_URL,
} from "config";

export const links = {
  origins: { text: "Origins", href: GAMES_URL_ORIGINS, id: "origins" },
  games: { text: "Games", href: GAMES_URL },
  whitepaper: { text: "Whitepaper", href: WHITEPAPER_URL },
  codeOfConduct: { text: "Code of Conduct", to: "/code-of-conduct" },
  // buildersTerms: {
  //   text: "Builders Program Terms",
  //   to: "/builders-terms",
  // },
  termsOfUse: { text: "Terms of Use", to: "/terms-of-use" },
  privacyPolicy: { text: "Privacy Policy", to: "/privacy-policy" },
  faq: { text: "FAQ", to: "/faq" },
  marketplace: {
    id: "marketplace",
    text: "Marketplace",
    href: MARKETPLACE_URL,
  },
  news: {
    id: "news",
    text: "News",
    href: BLOG_URL,
  },
  axs: {
    id: "axs",
    text: "AXS",
    to: "/axs",
  },
  land: { text: "Land", href: GAMES_URL_LAND },
  lore: {
    id: "lore",
    text: "Lore",
    to: "/lore"
  },
  p2r: {
    id: "p2r",
    text: "Play2Rice",
    href: "https://app.axieinfinity.com/daily-bounty-board/"
  }
};

const data = {
  features: [
    {
      title: "Free Axies",
      description:
        "Start your adventure with free starter Axies each with unique abilities and playstyles.",
      image: "buba.png",
    },
    {
      title: "Epic Power Ups",
      description:
        "Equip your axies with runes and charms that power up their abilities and unlock new strategies!",
      image: "puffy.png",
    },
    {
      title: "Infinite Adventure",
      description:
        "Each axie has unique strengths based on its genes. With billions of genetic combinations, the possibilities are infinite.",
      image: "olek.png",
    },
  ],
  playForFree: {
    title: "More Than A Game",
    description:
      "Battle monsters called Chimera or test your skills against other players in The Arena. Climb the leaderboard and become a living legend!",
    videoUrl: GAMEPLAY_TRAILER_URL,
  },
  latestNews: [
    {
      title: "Updates To SLP's Monetary Policy!",
      date: "11 Jan, 2024",
      href: "https://blog.axieinfinity.com/p/slpcap",
      image: "/images/templates/home/news/slp-update.jpg",
    },
    {
      title: "$Pixel Is Coming!",
      date: "05 Jan, 2024",
      href: "https://blog.axieinfinity.com/p/pixel-is-coming",
      image: "/images/templates/home/news/pixel-is-coming.jpg",
    },
    {
      title: "Origins Pre-Season Balancing Patch Goes Live",
      date: "03 Jan, 2024",
      href: "https://blog.axieinfinity.com/p/origins-s7preseason",
      image: "/images/templates/home/news/pre-season-update.jpg",
    }
  ],
  whatIsAxieInfinity: {
    title: (
      <>
        What's <br />
        Axie Infinity?
      </>
    ),
    description: (
      <>
        Axie Infinity is a virtual world filled with cute, formidable creatures
        known as Axies. Axies can be battled, bred, collected, and even used to
        earn resources & collectibles that can be traded on an open marketplace.
        <br />
        Axie was designed to introduce the world to an exciting new technology
        called Blockchain, through a fun, nostalgic, & charming game.
      </>
    ),
  },
  marketplace: {
    title: "Marketplace",
    description:
      "Trade or sell your axies with anyone, anywhere. You own your axies just like a physical trading card or figurine.",
    button: {
      text: "Visit",
      href: MARKETPLACE_URL_AXIES,
    },
    stats: [
      { data: "totalAxies", title: "Total Axies", icon: "egg", type: "number" },
      {
        data: "totalOwners",
        title: "Total Owners",
        icon: "idol",
        type: "number",
      },
      {
        data: "totalSales",
        title: "Total Sales",
        icon: "coin",
        type: "currency",
      },
    ],
  },
  playCollectOwn: [
    {
      title: "Play",
      description:
        "Axies are tickets to an infinite universe of games. Axie owners get automatic access to new games and experiences when they're released!",
      image: "play.jpg",
    },
    {
      title: "Collect",
      description:
        "Some Axies are rare with limited-edition body parts that will never be released again. Show off to your friends and family!",
      image: "collect.jpg",
    },
    {
      title: "Own",
      description:
        "Your axie digital collectibles are yours. You can use them across multiple games, trade them, sell them, and even use them to obtain more unique tradable resources and collectibles.",
      image: "own.jpg",
    },
  ],
  esports: {
    sectionTitle: "Competitive Axie",
    title: "Enter the Arena",
    description:
      "Climb the ladder in ranked arena battles and join tournaments for eternal glory and legendary rewards!",
    videoUrl: AXIECON_ESPORTS_REPLAY_URL,
  },
  experiences: {
    title: "Embrace Infinity",
    origins: {
      title: "Origins",
      description:
        "Build the ultimate team of axies and conquer your enemies in strategic card battles. Take down evil Chimeras in Adventure mode or challenge other players in the Arena!",
      image: "origins",
      href: GAMES_URL_ORIGINS,
    },
    creator: {
      title: "Creator",
      description:
        "Grow your following and gain exclusive perks through the Axie Creator Program!",
      image: "creator",
      href: CREATORS_URL,
    },
    breeding: {
      title: "Breeding",
      description:
        "Breed for power or aesthetics. The choice is yours. Start your own breeding operation and supply axies to battlers and collectors alike!",
      image: "breeding",
      href: BREEDING_URL,
    },
    land: {
      title: "Land",
      description:
        "Start your own village on the breathtaking planet of Lunacia. Lead your axies as they harvest, craft, and build towards a stronger homeland.",
      image: "land",
      href: GAMES_URL_LAND,
    },
    builder: {
      title: "Builder's Program",
      description:
        "Axies are tickets to a universe of experiences. Some of these are even built by community members just like you!",
      image: "builder",
      href: BUILDERS_URL,
    },
  },
  community: {
    title: "Community",
    description:
      "Axie is place for friendship, education, and fun. Meet explorers from all over the world and build together!",
  },
  navigation: {
    navLinks: [
      links.marketplace,
      links.games,
      links.p2r,
      links.lore,
      links.axs,
      links.news,
      {
        id: "more",
        text: "More",
        dropdownLinks: [links.codeOfConduct],
      },
    ],
    footerLinks: [
      links.marketplace,
      links.news,
      links.whitepaper,
      links.codeOfConduct,
      links.privacyPolicy,
      links.termsOfUse,
    ],
  },
};

export default data;
