import * as PIXI from "pixi.js";
import { contain } from "./helpers";
import { CurrentFigure } from "./CurrentFigure";
import { Figure } from "./Figure";
import { AXIE_DIRECTION } from "..";

export class PlaygroundGame extends PIXI.Application {
  constructor(options) {
    super(options);
    this.offsetWidth = options.width;
    this.offsetHeight = options.height;
    this.keys = null;
    this.axieDirection = AXIE_DIRECTION.Left;
    this.genes = options.genes;
    this.setLoading = options.setLoading;
    this.axieScale = options.axieScale;
  }

  startGame() {
    this.stage.interactive = true;
    this.renderer.view.style.touchAction = "auto";
    this.renderer.plugins.interaction.autoPreventDefault = false;
    this.view.style.width = `${this.offsetWidth}px`;
    this.view.style.height = `${this.offsetHeight}px`;

    let state;

    this.loader.load(async () => {
      const currentFigure = new CurrentFigure(this.axieScale);
      const figure = await Figure.fromAxieId(
        this.loader,
        this.genes,
        this.axieScale
      );

      currentFigure.currentSpine = figure;

      currentFigure.addChild(figure);
      currentFigure.changeCurrentAnimation("action/idle/normal", true);
      currentFigure.vx = 0;

      currentFigure.position.set(
        this.offsetWidth / 2,
        this.offsetHeight * (7 / 8)
      );

      contain(currentFigure, {
        width: this.offsetWidth,
        height: this.offsetHeight,
      });

      this.stage?.addChild(currentFigure);
      this.currentFigure = currentFigure;
      this.currentFigure.registerKeyBoardController();
    });

    this.stage?.on("pointerdown", () => {
      this.currentFigure.changeCurrentAnimation("action/idle/random-02", false);
      this.currentFigure.changeCurrentAnimation("action/idle/normal", true, 1);
    });

    const play = (delta, setLoading) => {
      if (this.currentFigure) {
        setLoading(false);
        if (this.currentFigure.x < -this.currentFigure.width / 2) {
          this.currentFigure.x = this.offsetWidth;
        } else if (
          this.currentFigure.x >
          this.offsetWidth + this.currentFigure.width / 2
        ) {
          this.currentFigure.x = 0;
        } else {
          this.currentFigure.x += this.currentFigure.vx;
        }
      }
    };

    state = play;
    const gameLoop = (delta) => state(delta, this.setLoading);
    this?.ticker?.add((delta) => gameLoop(delta));
    this.start();
  }

  changeSpine(axieId) {
    return this.currentFigure.changeSpine(this.loader, axieId);
  }
}
