import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";

const OuterWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ style }) => ({
  position: "absolute",
  left: style.left,
  top: style.top,
  width: style.width,
}));

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ style }) => ({
  position: "relative",
  paddingBottom: style.paddingBottom,
}));

const Frame = styled(Stack, {
  shouldForwardProp: (prop) => !["style", "background"].includes(prop),
})(({ style, background }) => ({
  backgroundImage: `url('${background}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center center",
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}));

const Photo = ({ index }) => {
  const styles = {
    1: {
      left: "17.2%",
      top: "46.5%",
      paddingBottom: "108.075%",
      width: "9.72%",
    },
    2: {
      left: "32.5%",
      top: "37%",
      paddingBottom: "105.66%",
      width: "14.72%",
    },
    3: {
      left: "29.5%",
      top: "16.5%",
      paddingBottom: "98.55%",
      width: "9.58%",
    },
    4: {
      left: "62%",
      top: "17%",
      paddingBottom: "114.75%",
      width: "4.24%",
    },
    5: {
      left: "66.5%",
      top: "24%",
      paddingBottom: "68.72%",
      width: "14.65%",
    },
    6: {
      left: "68.5%",
      top: "53%",
      paddingBottom: "112.2%",
      width: "14.24%",
    },
  };
  return (
    <OuterWrapper style={styles[index]}>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <Wrapper style={styles[index]}>
          <Frame
            style={styles[index]}
            background={`/images/templates/home/community/photo-${index}.png`}
          />
        </Wrapper>
      </AnimationOnScroll>
    </OuterWrapper>
  );
};

export default Photo;
