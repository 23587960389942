import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Button from "components/atoms/Button";
import { getRandomInteger } from "helpers/display";
import { MAX_AXIE_ID_FALLBACK } from "config";
import AnimatedAxie from "components/organisms/AnimatedAxie";
import { requestTotalAxies } from "services/api";
import { CONTAINER_WIDTH } from "config";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BoardAndButton = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: "center",
  position: "relative",
  maxWidth: 655,

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    margin: "0 auto",
  },
}));

const BoardWrapper = styled(Stack)(({ theme }) => ({
  position: "relative",
  paddingBottom: "61.39%",
  width: "100%",

  ".background": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url('/images/templates/home/what-is-axie-infinity/board.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    filter: "drop-shadow(0 8px 10px rgba(0,0,0,0.5))",
  },
}));

const Axie = () => {
  const [axieId, setAxieId] = useState(null);
  const totalAxies = useRef(null);

  useEffect(() => {
    const getTotalNumAxies = async () => {
      const data = await requestTotalAxies();
      totalAxies.current = data?.axies?.total;
      const id = getRandomInteger(
        1,
        totalAxies.current ?? MAX_AXIE_ID_FALLBACK
      );
      setAxieId(id);
    };

    getTotalNumAxies();
  }, []);

  const handleClick = () => {
    const id = getRandomInteger(1, totalAxies.current ?? MAX_AXIE_ID_FALLBACK);
    setAxieId(id);
  };

  return (
    <AnimationOnScroll
      animateIn="animate__fadeInRight"
      duration="0.4"
      className="animatedWrapper"
    >
      <BoardAndButton>
        <BoardWrapper>
          <Stack className="background">
            {axieId != null && (
              <AnimatedAxie
                axieId={axieId}
                backgroundImage="forest"
                containerStyle={{
                  overflow: "hidden",
                  position: "absolute",
                  left: "8%",
                  top: "3%",
                  height: "90%",
                  width: "87%",
                }}
              />
            )}
          </Stack>
        </BoardWrapper>

        <Button variant="dark" size={224} onClick={handleClick}>
          Infinity how?
        </Button>
      </BoardAndButton>
    </AnimationOnScroll>
  );
};

export default Axie;
