import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { MEDIUM_BREAKPOINT } from 'components/organisms/Nav';

export const Container = styled('div')(() => ({
  backgroundImage: `url('/images/templates/lore/chapter-background.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  width: '100%',
  paddingBottom: 80,
}));

export const ImagesWrapper = styled(Stack)(({ zoom }) => ({
  width: zoom === 'true' ? '100%' : '50%',
  height: 'auto',
  margin: 'auto',
  transition: 'width 0.2s',

  [`@media (max-width: ${MEDIUM_BREAKPOINT}px)`]: {
    width: '100%',
    padding: '0 16px',
  },
}));
