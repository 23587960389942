import React, { useEffect, useState } from "react";
import { AppBar, useMediaQuery } from "@mui/material";
import DesktopNav from "components/organisms/Nav/organisms/DesktopNav";
import MobileNav from "components/organisms/Nav/organisms/MobileNav";
import MobileDrawer from "components/organisms/Nav/organisms/MobileDrawer";

export const MEDIUM_BREAKPOINT = 1000;
export const SMALL_BREAKPOINT = 975;
export const MOBILE_BREAKPOINT = 400;

const Nav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [smallView, setSmallView] = useState(false);
  const mobileView = useMediaQuery(`(max-width:${MOBILE_BREAKPOINT}px)`);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < SMALL_BREAKPOINT
        ? setSmallView(true)
        : setSmallView(false);
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <AppBar>
      {mobileView ? (
        <MobileNav
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          drawerOpen={drawerOpen}
        />
      ) : (
        <DesktopNav handleDrawerOpen={handleDrawerOpen} />
      )}
      <MobileDrawer
        open={drawerOpen && smallView}
        handleDrawerClose={handleDrawerClose}
      />
    </AppBar>
  );
};

export default Nav;
