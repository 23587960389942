import colors from "./colors";

const palette = {
  colors,
  primary: {
    main: colors.primary,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  text: colors.text,
};

export default palette;
