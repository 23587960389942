import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import fonts from "theme/fonts";
import fontSizes from "theme/fontSizes";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "4px",
  fontFamily: fonts.alt,
  alignItems: "center",
}));

const Text = styled(Stack)(({ theme }) => ({
  lineHeight: 1,
  fontSize: fontSizes[16],
}));

const Key = styled(Stack)(({ theme }) => ({
  border: `1px solid #fff`,
  borderRadius: "2px",
  padding: "4px",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: 1,
  fontSize: fontSizes[12],
}));

const Directions = () => {
  return (
    <Wrapper className="directions">
      <Key>←</Key>
      <Key>→</Key>
      <Text>to run and</Text>
      <Key>E</Key>
      <Text>to attack.</Text>
    </Wrapper>
  );
};

export default Directions;
