import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import IdPlate from "./IdPlate";
import ViewOnMarketplace from "./ViewOnMarketplace";
import { makeAxieImageUrlFromId } from "helpers/axie";

const AxieImageWrapper = styled(Stack)(({ background }) => ({
  position: "relative",
  paddingBottom: "76.96%",
  marginBottom: "-17%",
}));

const AxieImage = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "background",
})(({ background }) => ({
  backgroundImage: `url('${background}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  width: "130%",
  height: "130%",
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-48%) translateY(-9%)",
  minHeight: 256,
  transition: "0.2s transform ease",
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: "5%",
  left: "50%",
  transform: "translateX(-50%)",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "74.9%",
  padding: "0 5%",
  alignItems: "center",
  color: theme.palette.text.dark,
}));

const Axie = ({ id, price, hovering }) => {
  return (
    <>
      <AxieImageWrapper>
        <AxieImage
          background={makeAxieImageUrlFromId(id)}
          className="axieImage"
        />
      </AxieImageWrapper>
      <TextWrapper>
        {hovering ? <ViewOnMarketplace /> : <IdPlate id={id} price={price} />}
      </TextWrapper>
    </>
  );
};

export default Axie;
