import colors from "theme/colors";
import fonts from "theme/fonts";
import fontSizes from "theme/fontSizes";

export const tabs = {
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        alignItems: "center",
      },
      indicator: {
        display: "none",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: fontSizes[40],
        fontFamily: fonts.header,
        color: colors.mocha[2],
        padding: 0,
        fontWeight: 800,
        textTransform: "none",
        "&:hover, &.Mui-selected": {
          color: colors.white,
        },
      },
    },
  },
};
