import React, { useState } from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import Link from "../atoms/Link";
import data from "data/data";

const Wrapper = styled(Stack)(({ theme, type }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(3),

  ...(type === "mobile" && {
    flexDirection: "column",
    alignItems: "flex-start",
  }),
}));

const LinksWrapper = styled(Stack)(({ theme, type }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(3),

  ...(type === "mobile" && {
    flexDirection: "column",
    alignItems: "stretch",
    gap: "40px",
  }),
}));

const Links = ({ type, handleDrawerClose }) => {
  const [moreDropdown, setMoreDropdown] = useState(false);
  const dropdownHandlers = {
    more: {
      isOpen: moreDropdown,
      setIsOpen: setMoreDropdown,
    },
  };

  return (
    <Wrapper type={type}>
      <LinksWrapper type={type}>
        {data.navigation.navLinks.map((link, i) => (
          <Link
            {...link}
            key={`LINK_${i}`}
            type={type}
            isOpen={dropdownHandlers[link.id]?.isOpen}
            setIsOpen={dropdownHandlers[link.id]?.setIsOpen}
            handleDrawerClose={handleDrawerClose}
          />
        ))}
      </LinksWrapper>
    </Wrapper>
  );
};

export default Links;
