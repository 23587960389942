import React from "react";
import { ReactComponent as MenuSvg } from "icons/hamburger.svg";
import { IconButton } from "@mui/material";

const Hamburger = ({ handleDrawerOpen }) => {
  return (
    <IconButton
      onClick={handleDrawerOpen}
      sx={{ color: (theme) => theme.palette.colors.mocha[1] }}
    >
      <MenuSvg />
    </IconButton>
  );
};

export default Hamburger;
