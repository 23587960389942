import { addAlphaToHex } from "helpers/colors";
import colors from "theme/colors";
import fonts from "../fonts";

export const baseline = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: colors.darkGrey,
        color: colors.mocha[5],
        fontFamily: fonts.body,
        "& ::selection": {
          background: addAlphaToHex(colors.black, 0.3),
          color: colors.mocha[1],
        },
      },
    },
  },
};
