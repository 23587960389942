import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH } from "config";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["width", "style"].includes(prop),
})(({ theme, width, style }) => ({
  width: "100%",
  maxWidth: width,
  margin: "0 auto",

  [`@media (max-width: ${CONTAINER_WIDTH - 1}px)`]: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  ...style,
}));

const Container = ({
  style = {},
  width = CONTAINER_WIDTH,
  children,
  innerRef,
  ...props
}) => {
  return (
    <Wrapper style={style} width={width} ref={innerRef} {...props}>
      {children}
    </Wrapper>
  );
};

export default Container;
