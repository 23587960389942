export const axieFloorPrices = {
  query : `{
    aquatic:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Aquatic]}){results{id,order{currentPrice,currentPriceUsd}}}
    beast:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Beast]}){results{id,order{currentPrice,currentPriceUsd}}}
    plant:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Plant]}){results{id,order{currentPrice,currentPriceUsd}}}
    bird:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Bird]}){results{id,order{currentPrice,currentPriceUsd}}}
    reptile:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Reptile]}){results{id,order{currentPrice,currentPriceUsd}}}
    bug:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Bug]}){results{id,order{currentPrice,currentPriceUsd}}}
    dawn:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Dawn]}){results{id,order{currentPrice,currentPriceUsd}}}
    dusk:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Dusk]}){results{id,order{currentPrice,currentPriceUsd}}}
    mech:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{classes:[Mech]}){results{id,order{currentPrice,currentPriceUsd}}}
    frosty:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{bodyShapes:[Frosty]}){results{id,order{currentPrice,currentPriceUsd}}}
    japanese:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{numJapan:[1,2,3,4,5,6]}){results{id,order{currentPrice,currentPriceUsd}}}
    mystic:axies(sort:PriceAsc,size:1,auctionType:Sale,criteria:{numMystic:[1,2,3,4,5,6]}){results{id,order{currentPrice,currentPriceUsd}}}
  }
  `
}

export const marketplaceStats = {
  query: `query GetOverallStats {
        overallMarketStats {
          mkpVolume {
            allTime
            __typename
          }
          __typename
        }
        tokensStats {
          axie {
            totalSupply
            holders
            __typename
          }
          __typename
        }
      }`,
  variables: () => {},
};

export const ethExchangeRate = {
  query: `query NewEthExchangeRate {
        exchangeRate {
          eth {
            usd
            __typename
          }
          __typename
        }
      }`,
  variables: () => {},
};

export const getAxieDetail = {
  query: `query GetAxieDetail($axieId: ID!) {
      axie(axieId: $axieId) {
        ...AxieDetail
        __typename
      }
    }
    
    fragment AxieDetail on Axie {
      newGenes
      __typename
    }`,
  variables: (axieId) => ({ axieId }),
};

export const getAxieBriefList = {
  query: `query GetAxieBriefList($auctionType: AuctionType, $criteria: AxieSearchCriteria, $from: Int, $sort: SortBy, $size: Int, $owner: String) {
             axies(auctionType: $auctionType, criteria: $criteria, from: $from, sort: $sort, size: $size, owner: $owner) {
                 total
                 results {
                     ...AxieBrief
                     __typename
                 }
                 __typename
             }
         }
      
         fragment AxieBrief on Axie {
             id
             name
             stage
             class
             breedCount
             image
             title
             newGenes
             order {
              ...OrderInfo
              __typename
             }
             battleInfo {
                 banned
                 __typename
             }
             stats {
                 ...AxieStats
                 __typename
             }
             parts {
                 id
                 name
                 class
                 type  
                 specialGenes  
                 __typename
             }
             __typename
         }
      
         fragment AxieStats on AxieStats {
             hp
             speed
             skill
             morale
             __typename
         }
         
         fragment OrderInfo on Order {
            id
            maker
            kind
            assets {
              ...AssetInfo
              __typename
            }
            expiredAt
            paymentToken
            startedAt
            basePrice
            endedAt
            endedPrice
            expectedState
            nonce
            marketFeePercentage
            signature
            hash
            duration
            timeLeft
            currentPrice
            suggestedPrice
            currentPriceUsd
            __typename
          }
          fragment AssetInfo on Asset {
            erc
            address
            id
            quantity
            orderId
            __typename
          }
      `,
  variables: (filterCriteria) => ({
    auctionType: filterCriteria.auctionType,
    from: filterCriteria.from,
    size: filterCriteria.size,
    sort: filterCriteria.sort,
    owner: filterCriteria.owner,
    criteria: {
      region: filterCriteria.region,
      parts: filterCriteria.parts,
      bodyShapes: filterCriteria.bodyShapes,
      classes: filterCriteria.classes,
      stages: filterCriteria.stages,
      numMystic: filterCriteria.numMystic,
      pureness: filterCriteria.pureness,
      title: filterCriteria.title,
      breedCount: filterCriteria.breedCount,
      hp: filterCriteria.hp,
      skill: filterCriteria.skill,
      speed: filterCriteria.speed,
      morale: filterCriteria.morale,
    },
  }),
};

export const getAxieTotalCounter = {
  query: `{
             axies {
                 total
             }
         }
      `,
};
