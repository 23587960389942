import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import TitledSection from "components/molecules/TitledSection";
import data from "data/data";
import Button from "components/atoms/Button";
import { useBreakpoints } from "hooks/useBreakpoints";

const Wrapper = styled(Stack)(({ theme }) => ({
  maxWidth: 880,
  width: "100%",
  margin: "0 auto",
}));

const Content = styled(Stack)(({ theme }) => ({
  position: "relative",
  paddingBottom: `${(320 / 880) * 100}%`,

  "& .background": {
    alignItems: "center",
    justifyContent: "center",
    padding: "10%",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url('/images/templates/home/marketplace/board.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },

  [`@media (max-width: 900px)`]: {
    paddingBottom: `${(504 / 648) * 100}%`,

    "& .background": {
      backgroundImage: `url('/images/templates/home/marketplace/board-mobile.png')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  },
}));

const Header = () => {
  const { mobile } = useBreakpoints();

  return (
    <Wrapper>
      <Content>
        <Stack className="background">
          <TitledSection
            title={data.marketplace.title}
            description={data.marketplace.description}
            titleFontSizes={{ desktop: 32, mobile: 28 }}
            textColor="text.light"
            titleColor="text.light"
            buttons={
              <Button
                variant="dark"
                size={mobile ? 178 : 224}
                href={data.marketplace.button.href}
              >
                {data.marketplace.button.text}
              </Button>
            }
            titleFontSize={32}
            buttonSpacing="20px"
            textAlign="center"
          />
        </Stack>
      </Content>
    </Wrapper>
  );
};

export default Header;
