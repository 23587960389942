import React from "react";
import { Tabs as MuiTabs, Tab, Stack } from "@mui/material";
import { a11yProps } from "helpers/accessibility";
import Image from "../Image";
import { useBreakpoints } from "hooks/useBreakpoints";

const Tabs = ({
  activeTab,
  setActiveTab,
  tabsId,
  tabs,
  ariaLabel,
  onClick,
  tabStyle,
  tabsStyle,
  labelKey = "label",
  gap = 40,
}) => {
  const getOnClick = (onClick, i) => {
    if (onClick == null) {
      return undefined;
    }
    if (Array.isArray(onClick) && onClick.length > i) {
      return onClick[i];
    }
    return onClick;
  };
  return (
    <MuiTabs
      value={activeTab}
      onChange={(e, newValue) => setActiveTab(newValue)}
      aria-label={ariaLabel}
      sx={{
        ...tabsStyle,
        "& .MuiTabs-flexContainer": {
          gap,
        },
      }}
    >
      {tabs.map((tab, i) => (
        <Tab
          disableRipple
          key={`TAB_${i}`}
          {...a11yProps(tabsId, i)}
          label={
            <Stack direction="row" alignItems="center" gap={gap}>
              {i > 0 && i < tabs.length - 1 && <Gem />}
              {tab[labelKey]}
              {i > 0 && i < tabs.length - 1 && <Gem />}
            </Stack>
          }
          onClick={getOnClick(onClick, i)}
          sx={{ minWidth: 0, ...tabStyle }}
        />
      ))}
    </MuiTabs>
  );
};

const Gem = () => {
  const { mobile } = useBreakpoints();

  return (
    <Image src="/images/icons/gem.png" alt="Gem" width={mobile ? 8 : 16} />
  );
};

export default Tabs;
