import React from "react";
import Esports from "./Esports";
import InfiniteExperiences from "./InfiniteExperiences";

const Section3 = () => {
  return (
    <>
      <Esports />
      <InfiniteExperiences />
    </>
  );
};

export default Section3;
