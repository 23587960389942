import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled(Stack)(({ open }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  overflow: 'auto',
  paddingBottom: 50,
  transform: open ? 'translateX(0%)' : 'translateX(100%)',
  transition: 'transform 0.3s ease-out',
}));

export const Content = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(20, 20, 20, 0.80)',
  backdropFilter: 'blur(6px)',
  gap: 16,
  padding: 20,
}));

export const Pages = styled(Stack)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 8,
  width: '100%',
}));

export const Header = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
