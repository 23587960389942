import React from "react";
import Dropdown from "./Dropdown";
import DropdownArrow from "./DropdownArrow";
import { MOBILE_BREAKPOINT } from "../index";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import LinkWrapper from "./LinkWrapper";

const Wrapper = styled(Stack)(({ theme, type }) => ({
  position: "relative",
  cursor: "pointer",
  padding: theme.spacing(1),

  ...(type === "mobile" && {
    padding: 0,
  }),
}));

const DropdownLink = ({
  type,
  status,
  children,
  dropdownLinks,
  isOpen,
  setIsOpen,
  handleDrawerClose,
}) => {
  const isMobile = type === "mobile";

  const onMouseEnterDropdown = (setIsOpen) => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onMouseLeaveDropdown = (setIsOpen) => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Wrapper
      type={type}
      onMouseEnter={isMobile ? null : () => onMouseEnterDropdown(setIsOpen)}
      onMouseLeave={isMobile ? null : () => onMouseLeaveDropdown(setIsOpen)}
      onClick={isMobile ? () => setIsOpen(!isOpen) : null}
    >
      <LinkWrapper type={type} status={status}>
        {children}
        <DropdownArrow isOpen={isOpen} />
      </LinkWrapper>
      <Dropdown
        links={dropdownLinks}
        type={type}
        isOpen={isOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </Wrapper>
  );
};

export default DropdownLink;
