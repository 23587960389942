import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Features from "./Features";
import Cards from "./Cards";
import Axies from "./Layers/Axies";
import Pillars from "./Layers/Pillars";
import Clouds from "./Layers/Clouds";

export const BREAKPOINT_FIXED_LAYOUT = 1250;

const Wrapper = styled(Stack)(() => ({
  background: "#3A1E10",

  ".features": {
    margin: "-5% auto 30px auto",
  },

  [`@media (max-width: ${BREAKPOINT_FIXED_LAYOUT}px)`]: {
    ".features": {
      marginTop: -120,
      marginBottom: 10,
    },
  },
}));

const Centered = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  position: "relative",
  margin: "0 auto",
}));

const JumbotronWrapper = styled(Stack)(({ theme }) => ({
  backgroundImage: "url('/images/templates/home/jumbotron/background-0.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center bottom",
  backgroundRepeat: "no-repeat",
  aspectRatio: "1444 / 1195",
  position: "relative",
  minWidth: (1440 / 1195) * 839,
  width: "100%",
  minHeight: 839,
}));

const Jumbotron = () => {
  const target = useRef(null);

  return (
    <Wrapper>
      <Centered>
        <JumbotronWrapper ref={target}>
          <Pillars target={target} />
          <Axies target={target} />
          <Clouds target={target} />
        </JumbotronWrapper>
      </Centered>

      <Features />

      <Cards />
    </Wrapper>
  );
};

export default Jumbotron;
