export const onScrollToElement = (id) => {
  const element = document?.querySelector(id);
  element?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
};

const openFullScreen = () => {
  const element = document.documentElement;

  if (element.requestFullscreen) {
    element.requestFullscreen();
    return;
  }

  if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
    return;
  }

  if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
    return;
  }

  element.msRequestFullscreen?.();
};

const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    return;
  }

  if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
    return;
  }

  if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
    return;
  }

  document.msExitFullscreen?.();
};

export const onChangeFullScreen = () => {
  const isFullScreen =
    document?.fullScreen ||
    document?.mozFullScreen ||
    document?.webkitIsFullScreen;

  if (!isFullScreen) {
    openFullScreen();
    return;
  }

  exitFullScreen();
};

export const getPageId = (chapterId, pageId) => {
  return `chapter-${chapterId}-page-${pageId}`;
};

export const parsePageId = (pageId) => {
  const regex = /chapter-\d+-page-(\d+)/;
  return pageId?.match?.(regex)?.[1];
};
