import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Container from "components/atoms/Container";
import { CONTAINER_WIDTH } from "config";
import Axie from "./Axie";
import Content from "./Content";
import Image from "components/atoms/Image";

const Layout = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "625fr 655fr",
  alignItems: "center",
  position: "relative",

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),

    "& .animatedWrapper": {
      width: "100%",
    },
  },

  ".note5": {
    position: "absolute",
    bottom: 0,
    transform: "translateY(95%) translateX(-50%)",
    left: "79%",
    zIndex: 10,
    maxWidth: 142,

    [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
      width: "24%",
    },
  },
}));

const WhatIsAxieInfinity = () => {
  return (
    <Container>
      <Layout>
        <Content />
        <Axie />
        <Image
          src="/images/templates/home/accents/note-5.png"
          alt="Axie note"
          className="note5"
        />
      </Layout>
    </Container>
  );
};

export default WhatIsAxieInfinity;
