import React from "react";
import Text from "components/atoms/Typography/Text";
import H2 from "components/atoms/Typography/Header";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Image from "components/atoms/Image";
import { Wrapper, Note, Content, TitleWrapper } from "./styles";

const Feature = ({ feature }) => {
  return (
    <Wrapper>
      <Note>
        <Content>
          <TitleWrapper>
            <H2 textAlign="center" color="text.regular" fontSize={32}>
              {feature.title}
            </H2>
          </TitleWrapper>
          <Text textAlign="center" color="text.regular">
            {feature.description}
          </Text>
        </Content>
        <AnimationOnScroll animateIn="animate__bounceIn" className="axieImage">
          <Image
            src={`/images/templates/home/jumbotron/${feature.image}`}
            alt={feature.title}
          />
        </AnimationOnScroll>
      </Note>
    </Wrapper>
  );
};

export default Feature;
