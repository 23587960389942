import React from "react";
import SubPage from "components/templates/SubPage";
import { WHITEPAPER_URL, AXS_AUDIT_REPORT } from "config";

const AXS = () => {
  return (
    <SubPage
      title="Axie Infinity Shards (AXS)"
      description="Axie Infinity Shards are an ERC 20 governance token for the Axie universe. Holders will shape the future of Axie Infinity by signaling their support for upgrades to the ecosystem and directing usage of a Community Treasury."
      headerLinks={[
        {
          text: "AXS Vision Document",
          href: WHITEPAPER_URL,
        },
        {
          text: "AXS Audit Report",
          href: AXS_AUDIT_REPORT,
        },
      ]}
      wrapperClass="axs"
      fullWidth={true}
    >
      <h1 className="textCenter">What is AXS?</h1>
      <p className="textCenter">
        Axie Infinity Shards <strong>(AXS)</strong> are the glue that binds all
        Axie community members together.
        <br />
        <br />
        AXS holders will be able to claim rewards if they stake their tokens,
        play the game, and participate in key governance votes. Players will
        also be able to earn $AXS when they play various games within the Axie
        Infinity Universe and through user generated content initiatives.
      </p>

      <div className="columns columns3">
        <div>
          <img src="/images/icons/incense.png" alt="Incense" />
          <h2>Staking</h2>
          <p>
            Through staking, players can lock up their AXS tokens to receive
            newly issued AXS tokens as rewards for their long term alignment.
          </p>
        </div>

        <div>
          <img src="/images/icons/pre-order-tag.png" alt="Order tag" />
          <h2>Payment</h2>
          <p>
            AXS is accepted as currency within the Axie NFT marketplace. AXS may
            also be used to determine eligibility for participation in certain
            sales/auctions conducted by the Axie team.
          </p>
        </div>

        <div>
          <img src="/images/icons/leafy-pot.png" alt="Plant" />
          <h2>Governance</h2>
          <p>
            The Community Treasury receives fees generated from activities such
            as trading on the Axie marketplace and breeding new Axies. This
            treasury will be governed by AXS stakers once the network has become
            sufficiently decentralized.
          </p>
        </div>
      </div>

      <div className="staking">
        <div className="content">
          <div className="text">
            <h1>Staking AXS</h1>
            <p>
              AXS staking acts as an incentive for holding AXS and supporting
              the ecosystem. Stakers receive freshly issued AXS and will be able
              to signal their support for various proposals to improve Axie
              Infinity. AXS stakers will eventually direct usage of The
              Community Treasury.
            </p>
          </div>

          <a href="https://stake.axieinfinity.com/">
            <button>Stake AXS</button>
          </a>
        </div>

        <div className="diagram">
          <img
            src="/images/templates/sub-page/axs/staking-diagram.png"
            alt="AXS Staking Diagram"
          />
        </div>
      </div>

      <div className="tokenInfo">
        <div className="diagram">
          <h1>Allocation</h1>
          <img
            src="/images/templates/sub-page/axs/allocation.png"
            alt="AXS Staking Diagram"
          />
        </div>
        <div className="diagram">
          <h1>Unlock Schedule</h1>
          <img
            src="/images/templates/sub-page/axs/unlock-schedule.png"
            alt="AXS Staking Diagram"
          />
        </div>
      </div>
    </SubPage>
  );
};

export default AXS;
