import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Image from "components/atoms/Image";
import NavLink from "components/organisms/Nav/atoms/NavLink";
import data from "data/data";
import { CONTAINER_WIDTH_MOBILE } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.colors.darkGrey,
  padding: `${theme.spacing(8)} ${theme.spacing(2)}`,
  alignItems: "center",
  gap: theme.spacing(6),
}));

const Links = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "40px",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    flexDirection: "column",
    gap: "20px",
  },
}));

const Footer = () => {
  return (
    <Wrapper>
      <Image
        src="/images/branding/axie-infinity-logo-white.png"
        alt="Axie Infinity Logo"
        width={160}
      />
      <Links>
        {data.navigation.footerLinks.map((link) => (
          <NavLink link={link} key={link.text} />
        ))}
      </Links>
    </Wrapper>
  );
};

export default Footer;
