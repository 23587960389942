import React from "react";
import { Box } from "@mui/material";
import Image from "components/atoms/Image";
import { useParallax } from "react-scroll-parallax";
import { useBreakpoints } from "hooks/useBreakpoints";
import { BREAKPOINT_FIXED_LAYOUT } from "..";

const Clouds = ({ target }) => {
  const { customBreakpoint } = useBreakpoints(BREAKPOINT_FIXED_LAYOUT);

  const clouds = useParallax({
    speed: customBreakpoint ? 0 : 10,
    targetElement: target.current,
  });

  return (
    <Box
      ref={clouds.ref}
      sx={{
        width: "100%",
        position: "absolute",
        left: 0,
        bottom: 0,
        pointerEvents: "none",
      }}
    >
      <Image src="/images/templates/home/jumbotron/background-3.png" width="100%" />
    </Box>
  );
};

export default Clouds;
