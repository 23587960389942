import React from "react";
import Feature from "./Feature";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import data from "data/data";
import Container from "components/atoms/Container";
import { CONTAINER_WIDTH_MOBILE } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
}));

const FeatureItems = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: 60,

  [`@media (max-width: ${CONTAINER_WIDTH_MOBILE}px)`]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const Features = () => {
  return (
    <Wrapper className="features">
      <Container>
        <FeatureItems>
          {data.features.map((feature, i) => (
            <Feature key={i} feature={feature} />
          ))}
        </FeatureItems>
      </Container>
    </Wrapper>
  );
};

export default Features;
