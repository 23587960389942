import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  img: {
    width,
  },
}));

const Image = ({ width = "100%", onClick, src, alt, className }) => {
  return (
    <Wrapper width={width} className={className} onClick={onClick}>
      <img src={src} alt={alt} />
    </Wrapper>
  );
};

export default Image;
