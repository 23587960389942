export const appBar = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: "transparent",
        boxShadow: "none",
        position: "static",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "70px !important",
      },
    },
  },
};
