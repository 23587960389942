import { Stack } from '@mui/material';
import styled from '@emotion/styled';

export const PageWrapper = styled(Stack)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  cursor: 'pointer',
  padding: '0px 6px',
  width: '100%',
}));

export const Image = styled('img')(({ selected }) => ({
  border: selected ? '1px solid #F3E3BA' : 'unset',
  width: '100%',
}));

export const ImageWrapper = styled(Stack)(() => ({
  width: '100%',
  height: 'auto',
  position: 'relative',
}));

export const ImageOverlay = styled(Stack)(({ selected }) => ({
  width: '100%',
  height: '100%',
  background: selected ? 'transparent' : 'rgba(20, 20, 20, 0.60)',
  position: 'absolute',
}));
