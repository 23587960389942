import React from "react";
import TitledSection from "components/molecules/TitledSection";
import Button from "components/atoms/Button";
import data from "data/data";
import Image from "components/atoms/Image";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CONTAINER_WIDTH } from "config";
import { useBreakpoints } from "hooks/useBreakpoints";
import { PLAY_NOW_URL } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  paddingTop: "12%",

  [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
    alignItems: "center",
    paddingTop: 0,
  },
}));

const Text = ({ setVideoOpen }) => {
  const sectionStyles = {
    position: "relative",
    alignSelf: "flex-start",
    maxWidth: 440,
    margin: "0 auto",

    "& .note3": {
      position: "absolute",
      bottom: 0,
      transform: "translateY(100%)",
      right: 0,
      zIndex: 8,
      maxWidth: 129,

      [`@media (max-width: ${CONTAINER_WIDTH}px)`]: {
        width: "17%",
      },
    },
  };

  return (
    <Wrapper>
      <Image
        width={160}
        src="/images/branding/axie-infinity-origins-logo.png"
        alt="Axie Infinity: Origins Logo"
        className="logo"
      />
      <TitledSection
        title={data.playForFree.title}
        description={data.playForFree.description}
        buttons={<Buttons setVideoOpen={setVideoOpen} />}
        style={sectionStyles}
      >
        <Image
          src="/images/templates/home/accents/note-3.png"
          alt="Buba note"
          className="note3"
        />
      </TitledSection>
    </Wrapper>
  );
};

const Buttons = ({ setVideoOpen }) => {
  const { customBreakpoint } = useBreakpoints(436);

  return (
    <>
      <Button
        href={PLAY_NOW_URL}
        variant="dark"
        size={customBreakpoint ? 154 : 188}
      >
        Play now
      </Button>
      <Button
        variant="light"
        size={customBreakpoint ? 154 : 188}
        onClick={() => setVideoOpen(true)}
      >
        Watch trailer
      </Button>
    </>
  );
};

export default Text;
